import { CLEAR_STATE } from "../../action-constants/admissions";
import * as types from "../../action-constants/putme";

const initialState = {
  courses: [],
  reports: [],
  currentPage: 1,
  items: [],
  PutmeStudentsLoading: false,
  bulkUploadPutmeListLoading: false,
  updatePutmeLoading: false,

};

export default function putmeReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.GET_POST_PROGRAMS_UTME:
      return {
        ...state,
        courses: action.payload,
      };
  
    case types.GET_ALL_POST_UTME_STUDENTS:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_PUTME_STUDENTS_LOADING:
      return {
        ...state,
        PutmeStudentsLoading: action.payload,
      };
    case types.BULK_UPLOAD_LOADING:
      return {
        ...state,
        bulkUploadPutmeListLoading: action.payload,
      };

      case types.UPDATE_PUTME_LOADING:
      return {
        ...state,
        updatePutmeLoading: action.payload,
      };
      
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
