import { connect } from "react-redux";
import { useEffect, useState } from "react";

import Select from "react-select";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InAppTemplate } from "../../shared/templates/portal/";

import "react-datepicker/dist/react-datepicker.css";

import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";

import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminLoadNewUserMetaActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminLoadNewUserMetaAction(payload, true);
};

const adminCreateAUserActionequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminCreateAUserAction(payload, true);
};

// const GetFeatureActions = (apiList: any[]) => {
//   let allControllers: any[] = [];
//   let controllerGroup: any[] = [];

//   apiList.forEach((eachApi: any) => {
//     if (!allControllers.includes(eachApi?.controller)) {
//       allControllers.push(eachApi?.controller);
//     }
//   });
//   if (allControllers.length >= 1) {
//     allControllers.forEach((eachItem: any) => {
//       let controllerName: any = eachItem.match(/[A-Z][a-z]+/g).join(" ");
//       let apiUrls = apiList.filter(
//         (eachApi: any) => eachApi?.controller === eachItem
//       );
//       let eachController = {
//         controllerName,
//         apiUrls,
//       };
//       controllerGroup.push(eachController);
//     });
//   }

//   return controllerGroup;
// };

// const RenderEachFeature = ({ feature, collectApiPermissions }: any) => {
//   const [isAllowed, setIsAllowed] = useState<boolean>(false);

//   let apiName: any = feature?.action.match(/[A-Z][a-z]+/g).join(" ");

//   const handleStatus = (status: any) => {
//     setIsAllowed(status);
//     collectApiPermissions(feature, status);
//   };

//   return (
//     <div className="each_feature">
//       <div className="api_name">
//         {" "}
//         <span>Feature:</span> {apiName}
//       </div>
//       <div className="api_desc">
//         <span>Description:</span> {feature?.description || "N/A"}
//       </div>
//       <div className="api_permission">
//         <span>Allow Access:</span>
//         <Switch
//           onChange={handleStatus}
//           checked={isAllowed}
//           width={30}
//           height={15}
//         />
//       </div>
//     </div>
//   );
// };

// const DisplayPermissionSummary = ({ allApiPermissions, name }: any) => {
//   return (
//     <div className="all_allowed_access">
//       {name && <div className="head_txt">Review Acess Granted to {name}</div>}
//       <div className="all_grants">
//         {allApiPermissions.map((eachGrant: any, index: any) => {
//           let grant = eachGrant.split(" ")[1];
//           grant = grant.split("/")[1];
//           grant = grant?.match(/[A-Z][a-z]+/g).join(" ");
//           return (
//             <div className="each_grant" key={index}>
//               {grant}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

const MewUserDataInfo = ({ pageProps }: any) => {
  const [chosenFaculty, setChosenFaculty] = useState<any>();
  const [chosenDept, setChosenDept] = useState<any>();
  const [chosenProgramme, setChosenProgramme] = useState<any>();
  const [roleId, setRoleId] = useState<any>("");
  const [roleIdErr, setRoleIdErr] = useState<any>("");
  const [allDeptsInFaculty, setAllDeptsInFaculty] = useState<any[]>([]);
  const [allProgInDept, setAllProgInDept] = useState<any[]>([]);
  // const [allApiList, setAllApiList] = useState<any[]>([]);
  // const [allApiPermissions, setAllPermissions] = useState<any[]>([]);
  // const [isPermissionPending, setIsPermissionPending] =
  //   useState<boolean>(false);
  // let requestInfo =
  //     pageProps.adminGetAStudentRequest?.request_data?.response?.data;
  let newUserMetaInfo =
    pageProps.adminLoadNewUserMetaRequest?.request_data?.response;
  let createAUserRequest = pageProps.adminCreateAUserReducerRequest;

  useEffect(() => {
    let allDept: any[] = [];
    // newUserMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
    //     if (
    //         allDept.findIndex(
    //             (dept: any) => dept?.value == eachData?.departmentUniqueId
    //         ) == -1 &&
    //         eachData?.faculty?.facultyUniqueId == requestInfo?.facultyUniqueId
    //     ) {
    //         allDept.push({
    //             value: eachData?.departmentUniqueId,
    //             label: eachData?.departmentName,
    //         });
    //     }
    // });
    setAllDeptsInFaculty(allDept);

    let allCourseOfStudy: any = [];
    newUserMetaInfo?.allProgrammes.forEach((eachData: any) => {
      if (
        allCourseOfStudy.findIndex(
          (course: any) => course?.value === eachData?.programmeUniqueId
        ) === -1 &&
        eachData?.departmentUniqueId === ""
      ) {
        allCourseOfStudy.push({
          value: eachData?.programmeUniqueId,
          label: eachData?.programmeName,
        });
      }
    });
    setAllProgInDept(allCourseOfStudy);
    // setAllApiList(GetFeatureActions(newUserMetaInfo?.apis));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chosenFaculty?.value) {
      let allDept: any[] = [];

      setChosenProgramme("");
      setChosenDept("");
      newUserMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
        if (
          allDept.findIndex(
            (dept: any) => dept?.value === eachData?.departmentUniqueId
          ) === -1 &&
          eachData?.faculty?.facultyUniqueId === chosenFaculty?.value
        ) {
          allDept.push({
            value: eachData?.departmentUniqueId,
            label: eachData?.departmentName,
          });
        }
      });
      setAllDeptsInFaculty(allDept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFaculty]);

  useEffect(() => {
    if (chosenDept?.value) {
      setChosenProgramme("");
      let allCourseOfStudy: any = [];
      newUserMetaInfo?.allProgrammes.forEach((eachData: any) => {
        if (
          allCourseOfStudy.findIndex(
            (course: any) => course?.value === eachData?.programmeUniqueId
          ) === -1 &&
          eachData?.departmentUniqueId === chosenDept?.value
        ) {
          allCourseOfStudy.push({
            value: eachData?.programmeUniqueId,
            label: eachData?.programmeName,
          });
        }
      });
      setAllProgInDept(allCourseOfStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDept]);

  useEffect(() => {
    if (chosenDept?.value) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProgramme]);

  let allFaculties: any = [];

  if (newUserMetaInfo?.allDeptAndFaculty) {
    newUserMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
      if (
        allFaculties.findIndex(
          (faculty: any) =>
            faculty?.value === eachData?.faculty?.facultyUniqueId
        ) === -1
      ) {
        allFaculties.push({
          value: eachData?.faculty?.facultyUniqueId,
          label: eachData?.faculty?.facultyName,
        });
      }
    });
  }

  const saveFaculty = (selectedFaculty: any) => {
    setChosenFaculty(selectedFaculty);
    // setChosenDept({})
  };

  const saveDept = (selectedDept: any) => {
    setChosenDept(selectedDept);
    // setChosenProgramme({})
  };
  const saveProgramme = (chosenProgramme: any) => {
    setChosenProgramme(chosenProgramme);
  };

  let checkValidationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required."),
    name: Yup.string().required("Full Name is required."),
    phoneNumber: Yup.string().required("Phone number is required."),
    email: Yup.string().email().required("Valid email is required."),
    // isNotApproved: Yup.boolean().required("Required"),
    departmentName: Yup.string().when("roleName", {
      is: "Course_Advisor",
      then: Yup.string().required("Department is required"),
      otherwise: Yup.string().nullable(),
    }),
    facultyName: Yup.string().when("roleName", {
      is: "Course_Advisor",
      then: Yup.string().required("Faculty is required"),
      otherwise: Yup.string().nullable(),
    }),
    programmeName: Yup.string().when("roleName", {
      is: "Course_Advisor",
      then: Yup.string().required("Course of study is required"),
      otherwise: Yup.string().nullable(),
    }),
    level: Yup.string().when("roleName", {
      is: "Course_Advisor",
      then: Yup.string().required("level of study is required"),
      otherwise: Yup.string().nullable(),
    }),

    // roleId: Yup.string().required("Role is required."),
    roleName: Yup.string().required("Role is required"),
  });

  let initialValues: any = {
    name: "",
    phoneNumber: "",
    email: "",
    departmentName: "",
    facultyName: "",
    gender: "",
    programmeName: "",
    level: "",
    roleId: "",
    roleName: "",
  };

  // const collectApiPermissions = (permission: any, status: boolean) => {
  //   let allPermissions: any[] = [...allApiPermissions];
  //   let permissionApi = `${permission?.controller}/${permission?.action}`;
  //   let pullApiText = `${permission?.method} ${permissionApi}`;

  //   if (status && !allPermissions.includes(pullApiText)) {
  //     allPermissions.push(pullApiText);
  //     setIsPermissionPending(false);
  //   }

  //   if (!status && allPermissions.includes(pullApiText)) {
  //     allPermissions = allPermissions.filter(
  //       (eachItem) => eachItem !== pullApiText
  //     );
  //   }
  //   setAllPermissions(allPermissions);
  // };

  return (
    <div>
      {/* {pageProps.adminGetBioDataMetaRequest?.request_status ==
                appConstantsPortal.FETCH_BIODATA_META_SUCCESS && ( */}
      <>
        {createAUserRequest.request_status !==
          appConstantsPortal.CREATE_A_USER_SUCCESS && (
          <div className=" edit_details">
            <Formik
              initialValues={initialValues}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                if (roleId) {
                  setRoleIdErr("");
                  let {
                    name,
                    email,
                    phoneNumber,
                    gender,
                    programmeName,
                    level,
                    facultyName,
                    departmentName,
                  } = values;

                  // if (allApiPermissions.length >= 1) {
                  //     setIsPermissionPending(false)
                  let payload: any = {
                    name,
                    email,
                    phoneNumber,
                    gender,
                    roleId,
                    programmeName,
                    level,
                    facultyName,
                    departmentName,
                    // allowedApis: allApiPermissions
                    allowedApis: [],
                  };

                  adminCreateAUserActionequest({
                    pageProps,
                    payload,
                  });
                } else {
                  setRoleIdErr("Role is required");
                }

                // } else {
                //     setIsPermissionPending(true)
                // }
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  // handleBlur,
                  handleChange,
                  setFieldTouched,
                  setFieldValue,
                  // isSubmitting,
                } = props;
                return (
                  <Form>
                    <div className="each_edit_section">
                      <div className="edit_data three">
                        <div className="form-group">
                          <label className="label">Full Name * </label>
                          <div className="">
                            <Field
                              value={values?.name}
                              id="name"
                              name="name"
                              type="text"
                              onChange={handleChange}
                              placeholder=""
                              className={`input-wrap ${
                                errors.name && touched.name
                                  ? "is-invalid-input "
                                  : !errors.name && values.name !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="name"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="label">User Phone No *</label>
                          <div className="">
                            <Field
                              value={values?.phoneNumber}
                              id="phoneNumber"
                              name="phoneNumber"
                              type="text"
                              onChange={handleChange}
                              placeholder=""
                              className={`input-wrap ${
                                errors.phoneNumber && touched.phoneNumber
                                  ? "is-invalid-input "
                                  : !errors.phoneNumber &&
                                    values.phoneNumber !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="label">User Email * </label>
                          <div className="">
                            <Field
                              value={values.email}
                              id="email"
                              name="email"
                              type="text"
                              onChange={handleChange}
                              placeholder=""
                              className={`input-wrap ${
                                errors.email && touched.email
                                  ? "is-invalid-input "
                                  : !errors.email && values.email !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            className="form-input-error"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="each_edit_section">
                      <div className="edit_data three">
                        <div className="form-group">
                          <label className="label">Gender * </label>
                          <div className="">
                            <select
                              name="gender"
                              id="gender"
                              value={values?.gender}
                              onChange={handleChange}
                              className={`input-wrap ${
                                errors.gender && touched.gender
                                  ? "is-invalid-input "
                                  : !errors.gender && values.gender !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            >
                              <option value="">Select </option>
                              <option value="Male"> Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                          <ErrorMessage
                            name="gender"
                            className="form-input-error"
                            component="div"
                          />
                        </div>
                        <div className="form-group">
                          <label className="label">User Role * </label>
                          <div className="">
                            <select
                              name="roleId"
                              id="roleId"
                              value={values?.roleId}
                              // onChange={handleChange}
                              onChange={(e: any) => {
                                setFieldValue("roleId", e.target.value);
                                errors.roleId = "";
                                // touched.roleId = false
                                setFieldTouched("roleId", false);

                                if (e.target.value !== "") {
                                  setRoleId(e.target.value);
                                  setRoleIdErr("");
                                }

                                setFieldValue(
                                  "roleName",
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }}
                              className={`input-wrap ${
                                (errors.roleName && touched.roleName) ||
                                roleIdErr
                                  ? "is-invalid-input "
                                  : !errors.roleId && values.roleId !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            >
                              <option value="">Select </option>
                              {newUserMetaInfo?.roles &&
                                newUserMetaInfo?.roles.map(
                                  (eachRole: any, index: any) => {
                                    return (
                                      <option value={eachRole?.id} key={index}>
                                        {" "}
                                        {eachRole?.name}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                          <ErrorMessage
                            name="roleName"
                            className="form-input-error"
                            component="div"
                          />
                          {roleIdErr && (
                            <div className="form-input-error">
                              Role is required.
                            </div>
                          )}
                        </div>
                        {values?.roleName === "Course_Advisor" && (
                          <div className="form-group">
                            <label className="label">Student Level * </label>
                            <div
                              className={
                                errors.level && touched.level
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="level"
                                id="level"
                                value={values?.level}
                                onChange={handleChange}
                                className={`input-wrap ${
                                  errors.level && touched.level
                                    ? "is-invalid-input "
                                    : !errors.level && values.level !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="100">100 </option>
                                <option value="200">200 </option>
                                <option value="300">300 </option>
                                <option value="400">400 </option>
                                <option value="500">500 </option>
                                <option value="600">600 </option>
                                <option value="700">700 </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="level"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        )}
                      </div>
                      {values?.roleName === "Course_Advisor" && (
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Faculty *</label>
                            <div className="">
                              <div className="search_faculty">
                                <Select
                                  // menuIsOpen={menuToOPen === "faculty" ? true : false}
                                  defaultValue={{
                                    value: values?.facultyUniqueId,
                                    label: values?.facultyName,
                                  }}
                                  value={chosenFaculty}
                                  id="chosenFaculty"
                                  openMenuOnClick={true}
                                  onChange={(option: any) => {
                                    setFieldValue("facultyName", option.label);

                                    setFieldTouched("facultyName", false);

                                    setFieldValue("departmentName", "");

                                    saveFaculty(option);
                                  }}
                                  options={allFaculties}
                                  placeholder="Select faculty"
                                  // className="select-wrap"
                                  className={
                                    errors.facultyName && touched.facultyName
                                      ? "invalid-input-wrap select-wrap"
                                      : "select-wrap"
                                  }
                                  classNamePrefix="faculty-select"
                                />
                                <ErrorMessage
                                  name="facultyName"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Department *</label>

                            <div className="search_dept">
                              <Select
                                // menuIsOpen={menuToOPen === "dept" ? true : false}
                                defaultValue={{
                                  label: values.departmentName,
                                  value: values.departmentUniqueId,
                                }}
                                value={chosenDept}
                                // onChange={saveDept}
                                onChange={(option: any) => {
                                  setFieldValue("departmentName", option.label);

                                  setFieldTouched("departmentName", false);

                                  setFieldValue("programmeName", "");

                                  saveDept(option);
                                }}
                                options={allDeptsInFaculty}
                                isDisabled={
                                  !chosenFaculty?.value &&
                                  !values.facultyUniqueId
                                }
                                placeholder="Select department"
                                className={
                                  errors.departmentName &&
                                  touched.departmentName
                                    ? "invalid-input-wrap select-wrap"
                                    : "select-wrap"
                                }
                                classNamePrefix="dept-select"
                              />
                              <ErrorMessage
                                name="departmentName"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Programme *</label>

                            <div className="search_course_of_study">
                              <Select
                                defaultValue={{
                                  value: values?.programmeName,
                                  label: values?.programmeName,
                                }}
                                value={chosenProgramme}
                                onChange={(option: any) => {
                                  setFieldValue("programmeName", option.label);

                                  setFieldTouched("programmeName", false);
                                  saveProgramme(option);
                                }}
                                options={allProgInDept}
                                isDisabled={
                                  !chosenDept?.value &&
                                  !values.departmentUniqueId
                                }
                                placeholder="Select course of study"
                                // className="select-wrap"
                                className={
                                  errors.programmeName && touched.programmeName
                                    ? "invalid-input-wrap select-wrap"
                                    : "select-wrap"
                                }
                                classNamePrefix="course-select"
                              />
                              <ErrorMessage
                                name="programmeName"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* {allApiList.length >= 1 &&
                                                <div className="all_features_section">
                                                    <div className="heading">Select Features this User should be  granted access to</div>
                                                    <div className="all_api_list">
                                                        {
                                                            allApiList.map((eachController: any, idx: any) => {
                                                                return (
                                                                    <div className="each_controller" key={idx}>
                                                                        <div className="controller_name">{eachController?.controllerName}</div>
                                                                        <div className="all_features">
                                                                            {
                                                                                eachController?.apiUrls.map((eachApi: any, index: any) => {
                                                                                    return (
                                                                                        <RenderEachFeature collectApiPermissions={collectApiPermissions} feature={eachApi} key={index} />
                                                                                    )
                                                                                   
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            } */}

                    {/* {allApiPermissions &&
                                        <div className="all_allowed_access">
                                            <div>length is {allApiPermissions.length} </div>
                                            {values?.name && <div className="head_txt">Review Acess Granted to {values?.name}</div>}
                                            <div className="all_grants">
                                                {
                                                    allApiPermissions.map((eachGrant: any, index: any) => {
                                                        let grant = eachGrant.split(" ")[1];
                                                        grant = grant.split("/")[1];
                                                        grant = grant?.match(/[A-Z][a-z]+/g).join(' ')
                                                        return (
                                                            <div className="each_grant" key={index}>
                                                                {grant}
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>

                                    } */}
                    {/* {allApiPermissions.length >= 1 && (
                      <DisplayPermissionSummary
                        allApiPermissions={allApiPermissions}
                        name={values?.name}
                      />
                    )} */}

                    {/* {Object.values(errors).length >= 1 &&
                      Object.values(errors)[0] != "" &&
                      checkIfHasError(errors) && (
                        <div className="justify-center">
                          <AlertMsg
                            type="error"
                            message={Object.values(errors).map(
                              (msg: any, index: any) =>
                                msg && (
                                  <span key={index}>
                                    {msg}
                                    <br />
                                  </span>
                                )
                            )}
                          />
                        </div>
                      )} */}
                    {/* {isPermissionPending &&
                                                <AlertMsg
                                                    type="error"
                                                    message="Select permissions"
                                                />
                                            } */}

                    <div className="form_cta">
                      <button
                        className="btn shaded"
                        type="submit"
                        disabled={createAUserRequest?.is_request_processing}
                        onClick={() => {}}
                      >
                        {createAUserRequest?.is_request_processing
                          ? "Please wait..."
                          : "Create User Account"}{" "}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {createAUserRequest.request_status ===
          appConstantsPortal.CREATE_A_USER_SUCCESS && (
          <div className="update_success">
            <div className="success-message-wrap">
              <div className="success-animation">
                <svg
                  className="success_icon_tick"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div className="success-message-txt">
                {" "}
                User has been successfully created. Please inform the user to
                log in{" "}
              </div>
            </div>
          </div>
        )}
      </>
      {/* )}

            {/* {pageProps.adminGetBioDataMetaRequest?.request_status ==
                appConstantsPortal.FETCH_BIODATA_META_PENDING && <LoadingItem />}

            {pageProps.adminGetBioDataMetaRequest?.request_status ==
                appConstantsPortal.FETCH_BIODATA_META_FAILURE && (
                    <AlertMsg
                        type="error"
                        message={pageProps.adminGetBioDataMetaRequest?.request_data.error}
                    />
                )} */}
    </div>
  );
};

const AdminNewUser = ({ pageProps }: any) => {
  let loadUserMetaRequest = pageProps?.adminLoadNewUserMetaRequest;
  useEffect(() => {
    adminLoadNewUserMetaActionRequest({
      pageProps,
      payload: "",
    });
    adminCreateAUserActionequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="new_user_wrap">
      <div className="back-to-portal">
              <Navigation
                to="/app/portal-settings/manage-users"
                text="Back to Settings"
              />
            </div>
       
        {loadUserMetaRequest?.request_status ===
          appConstantsPortal?.NEW_USER_META_SUCCESS && (
          <MewUserDataInfo pageProps={pageProps} />
        )}
        {loadUserMetaRequest?.request_status ===
          appConstantsPortal?.NEW_USER_META_PENDING && <LoadingItem />}
        {loadUserMetaRequest?.request_status ===
          appConstantsPortal?.NEW_USER_META_FAILURE && (
          <AlertMsg
            type="error"
            message={loadUserMetaRequest?.request_data.error}
          />
        )}
      </div>
    </>
  );
};

const AdminNewUserWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle="Create New User"
      childComponent={<AdminNewUser pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminLoadNewUserMetaAction: adminInAppActions.AdminLoadNewUserMeta,
  adminCreateAUserAction: adminInAppActions.AdminCreateAUser,
};
const mapStateToProps = (state: any) => ({
  adminLoadNewUserMetaRequest:
    state.allUGradPortalReducers.adminLoadNewUserMetaReducer,
  adminCreateAUserReducerRequest:
    state.allUGradPortalReducers.adminCreateAUserReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminNewUserWrap);
