import { appConstantsOnboarding } from "../../action-constants/onboarding/index";


const initialState =  {};




export const adminLoginReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsOnboarding.LOGIN_USER_PENDING:
            return {
                request_status: appConstantsOnboarding.LOGIN_USER_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsOnboarding.LOGIN_USER_SUCCESS:
            return {
                request_status: appConstantsOnboarding.LOGIN_USER_SUCCESS,
                loggedIn: true,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.LOGIN_USER_FAILURE:
            return {
                request_status: appConstantsOnboarding.LOGIN_USER_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.LOGIN_USER_RESET:
            return {
                request_status: appConstantsOnboarding.LOGIN_USER_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const adminForgotPwReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsOnboarding.FORGOT_PASSWORD_PENDING:
            return {
                request_status: appConstantsOnboarding.FORGOT_PASSWORD_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsOnboarding.FORGOT_PASSWORD_SUCCESS:
            return {
                request_status: appConstantsOnboarding.FORGOT_PASSWORD_SUCCESS,
                loggedIn: true,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.FORGOT_PASSWORD_FAILURE:
            return {
                request_status: appConstantsOnboarding.FORGOT_PASSWORD_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.FORGOT_PASSWORD_RESET:
            return {
                request_status: appConstantsOnboarding.FORGOT_PASSWORD_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const adminVerifyForgotPwCodeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_PENDING:
            return {
                request_status: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_SUCCESS:
            return {
                request_status: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_SUCCESS,
                loggedIn: true,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_FAILURE:
            return {
                request_status: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_RESET:
            return {
                request_status: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const adminUpdateNewPasswordeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsOnboarding.UPDATE_NEW_PASSWORD_PENDING:
            return {
                request_status: appConstantsOnboarding.UPDATE_NEW_PASSWORD_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsOnboarding.UPDATE_NEW_PASSWORD_SUCCESS:
            return {
                request_status: appConstantsOnboarding.UPDATE_NEW_PASSWORD_SUCCESS,
                loggedIn: true,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.UPDATE_NEW_PASSWORD_FAILURE:
            return {
                request_status: appConstantsOnboarding.UPDATE_NEW_PASSWORD_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsOnboarding.UPDATE_NEW_PASSWORD_RESET:
            return {
                request_status: appConstantsOnboarding.UPDATE_NEW_PASSWORD_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}