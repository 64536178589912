import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { formatDate } from "../../postutme/reports/Reports";

const DocumentationTable = ({ items }: any) => {
  const { eDocumentationRequestsLoading } = useSelector(
    (state: any) => state.putmeReducer
  );

  return (
    <Table bordered hover className="e-documentation-table">
      <thead>
        <tr>
          <th>STUDENT NAME</th>
          <th>MATRIC NO</th>
          <th>DOCUMENT</th>
          <th>DATE UPLOADED</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        {eDocumentationRequestsLoading ? (
          [...Array(12)].map((_, index) => (
            <tr key={index}>
              {[...Array(5)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : items?.length ? (
          items.map((document: any, index: number) => {
            return (
              <tr key={index}>
                <td>{document?.fullName}</td>
                <td>{document?.matricNumber}</td>
                <td>{document?.documentType}</td>
                <td>{formatDate(document?.dateUploaded)}</td>
                <td>{document?.status}</td>
              </tr>
            );
          })
        ) : (
          <p>No results...</p>
        )}
      </tbody>
    </Table>
  );
};

export default DocumentationTable;
