import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import HostelMenu from "./HostelMenu";

const HostelManagement = () => {
 
  return (
    <InAppTemplate
      childComponent={<HostelMenu />}
      pageTitle="HOSTEL ACCOMMODATION DASHBOARD"
    />
  );
};

export default HostelManagement;
