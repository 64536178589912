import { useEffect, useState } from "react";
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import DropDown from "../../../shared/components/dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

import { PROGRAMME_TYPES } from "../../../shared/_helpers/constants";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getAllProgrammesPutme,
  getPostUtmeReports,
} from "../../../redux/action/putme";
import ReportsTable from "./ReportsTable";
import DownloadModal from "./DownloadPDFModal";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Navigation from "../../../shared/components/navigation/Navigation";

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};

const Reports = () => {
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
  const [reports, setReports] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [selectedProgramme, setSelectedProgramme] = useState<string>("");
  const [selectedProgrammeType, setSelectedProgrammeType] =
    useState<string>("");
  const [fileType, setFileType] = useState("Excel");
  const [downloadUrl, setDownloadUrl] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const { courses } = useSelector((state: any) => state.putmeReducer);
  const dispatch = useDispatch();

  const courseList =
    courses?.map((course: string) => ({
      label: course,
      value: course,
    })) || [];
  const startDateString = "2024-02-06T00:00:00.000Z";
  const endDateString = "2024-03-06T00:00:00.000Z";

  // Format start and end dates
  const formattedStartDate = formatDate(startDateString);
  const formattedEndDate = formatDate(endDateString);

  const validationSchema = Yup.object().shape({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    programmeType: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(getAllProgrammesPutme());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="report-container">
      <div className="back">
        <Navigation to="/app/putme-report-cards" text="Back to Reports" />
      </div>
      <div className="report-page">
        {fileType === "pdf" ? (
          <ReportsTable
            studentsReports={reports}
            program={selectedProgramme}
            programmeType={selectedProgrammeType}
          />
        ) : null}
        <DownloadModal
          show={show}
          setShow={setShow}
          downloadUrl={downloadUrl}
          fileType={fileType}
          dateString={`${formattedStartDate}_${formattedEndDate}`}
        />
        <Formik
          initialValues={{ from: "", to: "", programme: "", programmeType: "" }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const { to, from, programmeType, programme } = values;
            const encodedProgramme = encodeURIComponent(programme);
            const response = await getPostUtmeReports(
              to,
              from,
              programmeType,
              encodedProgramme,
              fileType
            );
            if (response?.data?.length || response?.data?.hasData) {
              if (fileType === "pdf") {
                setReports(response?.data);
              } else {
                setDownloadUrl(response?.data?.reportDownloadUrl);
              }
              setShow(true);
            } else if (
              response?.status === 200 &&
              (response?.length < 1 || !response.hasData)
            ) {
              toast.info("No record found");
            }
            setLoading(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              setFieldTouched,
              setFieldValue,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="row">
                  <div className="form-group">
                    <label className="label">From</label>
                    <div>
                      <DatePicker
                        selected={fromDate}
                        onChange={(date: any) => {
                          setFromDate(date);
                          setFieldTouched("from", true);
                          setFieldValue("from", date.toISOString());
                        }}
                        maxDate={new Date()}
                        placeholderText="From date"
                        showYearDropdown
                        showMonthDropdown
                      />
                    </div>
                    <ErrorMessage
                      name="from"
                      className="form-input-error"
                      component="div"
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">To</label>
                    <div>
                      <DatePicker
                        selected={toDate}
                        onChange={(date: any) => {
                          setToDate(date);
                          setFieldTouched("to", true);
                          setFieldValue("to", date.toISOString());
                        }}
                        placeholderText="To"
                        showYearDropdown
                        showMonthDropdown
                      />
                      <ErrorMessage
                        name="to"
                        className="form-input-error"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <DropDown
                      label="Course of study"
                      name="programme"
                      value={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      touched={touched}
                      errors={errors}
                      options={courseList}
                      field="programme"
                      width="100%"
                      handleChange={(value: any) => {
                        setSelectedProgramme(value);
                        handleChange(value);
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      <DropDown
                        label="Programme type"
                        name="programmeType"
                        touched={touched}
                        errors={errors}
                        options={PROGRAMME_TYPES}
                        field="programmeType"
                        width="100%"
                        value={{
                          value: values.programmeType,
                          label: values.programmeType,
                        }}
                        handleChange={(value: any) => {
                          setSelectedProgrammeType(value);
                          handleChange(value);
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                  </div>
                </div>
                <div className="report-submit-btn">
                  <Dropdown as={ButtonGroup}>
                    <Button
                      disabled={loading}
                      type="submit"
                      variant="success"
                      className="download_cta"
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        `Download as ${fileType}`
                      )}
                    </Button>

                    <Dropdown.Toggle
                      // disabled={downloadReportRequest?.is_request_processing}
                      split
                      variant="success"
                      id="dropdown-split-basic"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setFileType("Excel")}>
                        Excel
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFileType("pdf")}>
                        Pdf
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                  {/* <Button type="submit" disabled={loading}>
                    {" "}
                    {loading ? (
                      <DotLoader
                        color="white"
                        loading={loading}
                        size={30}
                        aria-label="Submitting"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button> */}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Reports;
