import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Delete from "../../../assets/delete.png";
import Skeleton from "react-loading-skeleton";
import { deleteStudentFromRoom } from "../../../redux/action/hostels";
import { useState } from "react";
import { toast } from "react-toastify";

const RoomView = ({ studentsInRoom, loading, fetchStudentsInRoom }: any) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [roomInfo, setRoomInfo] = useState<any>({});
  const [showDialog, setShowDialog] = useState(false);

  const handleDelete = async (payload: any) => {
    setDeleteLoading(true);
    const res = await deleteStudentFromRoom(payload);
    if (res.status === 200) {
      toast.success("Student deleted successfully");
      fetchStudentsInRoom(roomInfo?.roomUniqueId, 1, false);
    }
    setDeleteLoading(false);
    setRoomInfo({});
  };


  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this item?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(roomInfo)}
          disabled={deleteLoading}
        >
          Delete{" "}
          {deleteLoading ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={deleteLoading}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Matric Number</th>
          <th>Student Name</th>
          <th>Level</th>
          <th>Room Name</th>
          <th>Paid</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(5)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          : studentsInRoom?.map((student: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{student?.matricNumber}</td>
                  <td>{student?.fullName}</td>
                  <td>{student?.level}</td>
                  <td>{student?.rooomName}</td>
                  <td>{student?.isPaid ? "YES" : "NO"}</td>

                  <td onClick={async () => {}}>
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={
                        student?.studentId === roomInfo?.studentId && showDialog
                      }
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="view"
                        style={{ cursor: "pointer", width: "30px" }}
                        onClick={() => {
                          setRoomInfo(student);
                          setShowDialog(!showDialog);
                        }}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
      </tbody>
    </Table>
  );
};

export default RoomView;
