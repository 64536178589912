import { InAppTemplate } from '../../../shared/templates/portal';
import SettingsDashboard from './SettingsDashboard';

const HostelSettings = () => {
    return (
      <InAppTemplate
        childComponent={<SettingsDashboard />}
        pageTitle="ACCOMMODATION SETTINGS"
      />
    );
  };

export default HostelSettings;