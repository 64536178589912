import { useState } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import Delete from "../../../assets/delete.png";
import pencil from "../../../assets/pencil.png";
import { deleteExtendCourseUnit } from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
const ExtendUnitTable = ({
  items,
  loading,
  getExtendStudentUnitDetails,
  setEditStudent,
  setShowModal
}: any) => {
  const [deleting, setDeleting] = useState(false);
  const [dialogId, setDialogId] = useState<number | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleEdit = (extendedCourseUniqueId: any) => {
    const itemToEdit = items.find(
      (item: any) => item.extendedCourseUniqueId === extendedCourseUniqueId
    );
    if (itemToEdit) {
      setEditStudent(itemToEdit);
      setShowModal(true);
    }
  };

  const handleDelete = async (studentId: any) => {
    setDeleting(true);
    await deleteExtendCourseUnit(studentId)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Extend student unit deleted successfully");
          getExtendStudentUnitDetails(1);
          setShowDialog(!showDialog);
        }
        setDeleting(false);
        setDialogId(null);
      })
      .catch((err) => {
        toast.error("An error occured, please try again.");
        handleRequestErrors(err);
      });
  };

  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this item?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogId)}
          disabled={loading}
        >
          Delete {deleting ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={deleting}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Matric No.</th>
            <th>Level</th>
            <th>Session</th>
            <th>Semester</th>
            <th>Unit</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(7)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : items?.length ? (
            items?.map((item: any, index: any) => {
              return (
                <tr key={index} id={item?.studentId}>
                  <td>{item?.matricNumber}</td>
                  <td>{item?.level}</td>
                  <td>{item?.session}</td>
                  <td>{item?.semester}</td>
                  <td>{item?.additionalUnit}</td>

                  {/* PENCIL */}
                  <td>
                    <img
                      src={pencil}
                      alt="edit"
                      onClick={() => handleEdit(item?.extendedCourseUniqueId)}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "28px",
                      }}
                    />
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={
                        item?.extendedCourseUniqueId === dialogId && showDialog
                      }
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        onClick={async () => {
                          setShowDialog(!showDialog);
                          setDialogId(item?.extendedCourseUniqueId);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "28px",
                        }}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })
          ) : (
            <p className="no-results">No results...</p>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default ExtendUnitTable;
