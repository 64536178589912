import Table from "react-bootstrap/Table";
import Pencil from "../../assets/pencil.png";
import ViewIco from "../../assets/view.png";
import AddIcon from "../../assets/Add.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";
import RoomModal from "./RoomModal";
import { useCallback, useEffect, useState } from "react";
import {
  getAllRooms,
  getAllRoomsSearch,
  getHostelSiteBlock,
} from "../../redux/action/hostels";
import { Link } from "react-router-dom";
import AddStudentToRoomModal from "./room-view/AddStudent";
import ReactPaginate from "react-paginate";

export interface Allocation {
  level: string;
  allocationPercentage: number;
}

interface Room {
  allocation: Allocation[];
  hostelSite: string;
  blockName: string;
  gender: string;
  roomName: string;
  totalBedSpace: string;
  allocatedBedSpace: string;
  isReserved: boolean;
  roomUniqueId: "";
  amount: string;
  "100L": string;
  "200L": string;
  "300L": string;
  "400L": string;
  "500L": string;
}

const initialValues = {
  hostelSite: "",
  blockName: "",
  gender: "",
  roomName: "",
  totalBedSpace: "",
  allocatedBedSpace: "",
  isReserved: false,
  roomUniqueId: "",
  amount: "",
  "100L": "",
  "200L": "",
  "300L": "",
  "400L": "",
  "500L": "",
};
const RoomsTable = () => {
  const [show, setShow] = useState(false);
  const [roomInfo, setRoomInfo] = useState(initialValues);
  const [allRooms, setAllRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);


  const pageCount = Math.ceil(totalCount / 15);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); 
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]); 
  
  
  const fetchData = useCallback(
    async (page: number) => {
      setLoading(true);

      if (debouncedSearchTerm) {
        const searchResponse = await getAllRoomsSearch(page, debouncedSearchTerm);
        if (searchResponse?.data?.items) {
          setAllRooms(searchResponse.data.items);
          setTotalCount(searchResponse?.data?.totalCount);
        }
      } else {
        const roomsResponse = await getAllRooms();
        if (roomsResponse?.data?.items) {
          setAllRooms(roomsResponse.data.items);
          setTotalCount(roomsResponse?.data?.totalCount);
        }
      }

      setLoading(false);
    },
    [debouncedSearchTerm, refetch] 
  );
  
  const fetchSiteBlock = useCallback(
    async () => {
      setLoading(true);
        const blocksResponse = await getHostelSiteBlock();
        if (blocksResponse?.data?.length) {
          setBlocks(blocksResponse.data);
        }
        setLoading(false);
      },
      [ refetch]
    );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  useEffect(() => {
    fetchSiteBlock();
  }, []);

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchData(newOffset);
  };

  const formatRoomInfo = (room: Room) => {
    const { allocation, ...otherDetails } = room;

    const formattedAllocations = allocation.reduce((acc, alloc) => {
      acc[`${alloc.level}L`] = alloc.allocationPercentage;
      return acc;
    }, {} as { [key: string]: number });

    return { ...otherDetails, ...formattedAllocations };
  };

  return (
    <div className="room-management">
      <RoomModal
        show={show}
        setShow={setShow}
        setRoomInfo={setRoomInfo}
        roomInfo={roomInfo}
        blocks={blocks}
        setRefetch={setRefetch}
        initialValues={initialValues}
        refetch={refetch}
      />
      <AddStudentToRoomModal
        show={addStudentModal}
        setShow={setAddStudentModal}
        room={roomInfo}
      />
      <div className="room-management-top-buttons">
        <Navigation to="/app/hostel/dashboard" text="Back to Hostel Dasbhoard" />
      </div>
      <div className="inputWrap">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchTerm}
          onChange={(e: any) => {
            setSearchTerm(e.target.value);
          }}
        />

        <button
          className="add-btn"
          onClick={() => {
            setRoomInfo(initialValues);
            setShow(true);
          }}
        >
          Add Room
        </button>
      </div>

      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Hostel Site</th>
            <th>Blocks</th>
            <th>Room Name</th>
            <th>Gender</th>
            <th>Bed Space</th>
            <th>Allocated</th>
            <th>Non-Allocated</th>
            <th>Amount</th>
            <th>Reserved</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(12)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : allRooms?.length ? (
            allRooms.map((room: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{room?.hostelSite}</td>
                  <td>{room?.blockName}</td>
                  <td>{room?.roomName}</td>
                  <td>{room?.gender}</td>
                  <td>{room?.totalBedSpace}</td>
                  <td>{room?.allocatedBedSpace}</td>
                  <td>{room?.nonAllocatedBedSpace}</td>
                  <td>{room?.amount}</td>
                  <td>{room?.isReserved ? "Yes" : "No"}</td>

                  <td
                    onClick={async () => {}}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderLeft: "none",
                    }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (room?.allocation?.length) {
                          const roomInfo = formatRoomInfo(room);
                          setRoomInfo(roomInfo);
                        } else {
                          setRoomInfo(room);
                        }
                        setShow(true);
                      }}
                    />
                    <Link
                      to="/app/hostel/room"
                      state={{
                        roomId: room?.roomUniqueId,
                        site: room?.hostelSite,
                        block: room?.blockName,
                        blockUniqueId: room?.blockUniqueId,
                        roomName: room?.roomName,
                      }}
                    >
                      <img
                        src={ViewIco}
                        alt="view"
                        style={{ cursor: "pointer", width: "25px" }}
                      />
                    </Link>
                    <img
                      src={AddIcon}
                      alt="view"
                      style={{ cursor: "pointer", width: "30px" }}
                      onClick={() => {
                        setAddStudentModal(true);
                        setRoomInfo(room);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <h5>There are no room added...</h5>
          )}
        </tbody>
      </Table>
      {allRooms?.length ? (
        <div className="result-count">
          <p>
            Showing {allRooms?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default RoomsTable;
