export const FETCH_STUDENTS_ADMISSION_LIST = "FETCH_STUDENTS_ADMISSION_LIST";
export const LOADING_STUDENTS_ADMISSION_LIST = "LOADING_STUDENTS_ADMISSION_LIST"
export const PAGE_NUMBER = "PAGE_NUMBER";
export const FETCH_STUDENTS_ADMISSION_LIST_FAILURE = "FETCH_STUDENTS_ADMISSION_LIST_FAILURE";
export const LOAD_FACULTY_AND_DEPT = "LOAD_FACULTY_AND_DEPT"
export const LOAD_ALL_ADMISSION_PROGRAMMES = "LOAD_ALL_ADMISSION_PROGRAMMES"
export const ADD_NEW_ADMITTED_STUDENT = "ADD_NEW_ADMITTED_STUDENT"
export const UPDATE_NEW_ADMITTED_STUDENT = "UPDATE_NEW_ADMITTED_STUDENT"
export const ADD_NEW_STUDENT_LOADING = "ADD_NEW_STUDENT_LOADING"
export const BULK_UPLOAD_ADMISSION_LIST_SUCCESS ="BULK_UPLOAD_ADMISSION_LIST_SUCCESS"
export const BULK_UPLOAD_LOADING = "BULK_UPLOAD_LOADING"
export const ADMISSION_LIST_LOADING = "ADMISSION_LIST_LOADING"
export const CLEAR_STATE = "CLEAR_STATE"


export const CREATE_NEW_STUDENT_SUCCESS = "CREATE_NEW_STUDENT_SUCCESS"

