export const getStartAndEndDate = (startDate: any, endDate: any)=>{
    let startMonth =
    startDate.getMonth() + 1 < 10
      ? `0${startDate.getMonth() + 1}`
      : startDate.getMonth() + 1;
  let startDay =
    startDate.getDate() < 10
      ? `0${startDate.getDate()}`
      : startDate.getDate();

  let endMonth =
    endDate.getMonth() + 1 < 10
      ? `0${endDate.getMonth() + 1}`
      : endDate.getMonth() + 1;
  let endDay =
    endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

  let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
  let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;

  return {
    formattedStartDate,
    formattedEndDate
  }

}