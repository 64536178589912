import * as types from "../../action-constants/admissions";

const initialState = {
  items: [],
  currentPage: 1,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  loading: false,
  faculties: [],
  allProgrammes: [],
  courseSubjects: [],
  addNewStudentLoading: false,
  admissionListLoading: false,
  bulkUploadLoading: false,
};

export default function admissionReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.FETCH_STUDENTS_ADMISSION_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.LOAD_FACULTY_AND_DEPT:
      return {
        ...state,
        faculties: action.payload,
      };
    case types.LOAD_ALL_ADMISSION_PROGRAMMES:
      return {
        ...state,
        allProgrammes: action.payload,
      };
    case types.ADD_NEW_STUDENT_LOADING:
      return {
        ...state,
        addNewStudentLoading: action.payload,
      };
    case types.ADMISSION_LIST_LOADING:
      return {
        ...state,
        admissionListLoading: action.payload,
      };
    case types.BULK_UPLOAD_LOADING:
      return {
        ...state,
        bulkUploadLoading: action.payload,
      };
   case types.CLEAR_STATE:
    return initialState
    default:
      return state;
  }
}
