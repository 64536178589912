import { routes } from "../../../services/paths";
import { ApiService } from "../../../services/apiService";
import { handleRequestErrors } from "../../../shared/utils";
import { toast } from "react-toastify";

export const getAllRooms = async (page = 1, pageSize = 15) => {
  return await ApiService.request({
    url: `${routes.HOSTEL_ROOMS}?page=${page}&pageSize=${pageSize}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getStudentsInRoom = async (roomId: string, pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.STUDENTS_IN_ROOM}?roomUniqueId=${roomId}&pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const addOrUpdateRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_UPDATE_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getPaginatedHostelSiteBlock = async (pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.PAGINATED_HOSTEL_SITE_BLOCKS}?pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelSiteBlock = async () => {
  return await ApiService.request({
    url: `${routes.HOSTEL_SITE_BLOCKS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const deleteStudentFromRoom = async (payload: any) => {
  const { studentId, bedSpaceUniqueId, roomUniqueId } = payload;
  return await ApiService.request({
    url: `${routes.DELETE_STUDENT_FROM_ROOM}?studentId=${studentId}&bedSpaceUniqueId=${bedSpaceUniqueId}&roomUniqueId=${roomUniqueId}`,
    http_method: "DELETE",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      if (err?.response?.status < 500) {
        toast.error(err?.response?.data);
        return err;
      }
    });
};

export const addHostelToRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_STUDENT_TO_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  });
};

export const addOrEditRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_EDIT_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
    });
};
export const addOrUpdatePercentage = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_UPDATE_BEDSPACE_PERCENTAGE}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelDashboardStat = async () => {
  return await ApiService.request({
    url: `${routes.HOSTEL_ACCOMODATION_DASHBOARD_STATISTICS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelPercentage = async () => {
  return await ApiService.request({
    url: `${routes.GET_BEDSPACE_PERCENTAGE}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getStudentMatricSearch = async (payload: string) => {
  return await ApiService.request({
    url: `${routes.GET_HOSTEL_MATRIC_SEARCH}?matricNumber=${payload}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getAccomodationReport = async (to: string, from: string) => {
  return await ApiService.request({
    url: `${routes.GET_STUDENT_ACCOMODATION_REPORT}?fromDate=${from}&toDate=${to}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const downloadHostelReport = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.DOWNLOAD_HOSTEL_ACCOMODATION_REPORT}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getAllRoomsSearch = async (
  pageNumber: number,
  searchTerm: string
) => {
  return await ApiService.request({
    url: `${routes.HOSTEL_ROOMS_SEARCH}?pageNumber=${pageNumber}&pageSize=20&searchTerm=${searchTerm}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const allHostelRooms = async () => {
  return await ApiService.request({
    url: `${routes.ALL_HOSTEL_ROOMS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getStudentInRoom = async (roomUniqueId: string) => {
  return await ApiService.request({
    url: `${
      routes.GET_STUDENT_IN_ROOM
    }?roomUniqueId=${roomUniqueId}&pageSize=${20}&pageNumber=${1}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};
export const getStudentInRoomSearch = async (
  roomUniqueId: string,
  searchTerm: string,
  pageNumber: number
) => {
  return await ApiService.request({
    url: `${routes.GET_STUDENT_IN_ROOMS_SEARCH}?roomUniqueId=${roomUniqueId}&searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};


export const getHostelStats = async () => {
  return await ApiService.request({
    url: `${routes.GET_HOSTEL_STATISTICS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};