import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SearchBox from "../../../shared/components/searchbox";
import ReactPaginate from "react-paginate";
import DocumentationTable from "./DocumentationTable";
import "./index.scss";
import DropDown from "../../../shared/components/dropdown";
import {
  getAllRequests,
  getDashboardData,
  getDocumentTypes,
  searchRequestByParameters,
  searchRequests,
} from "../../../redux/action/edocumentation";
import { LoadingItem } from "../../../shared/components/loading";
import { loadAllProgrammes } from "../../../redux/action/admission";
import { allLevels } from "../../../shared/_helpers/constants";
import { MdClear } from "react-icons/md";
import Navigation from "../../../shared/components/navigation/Navigation";

const EDocumentationDashboardData = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [programme, setProgramme] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const dispatch = useDispatch();
  const {
    totalCleared,
    totalPending,
    totalRejected,
    totalSubmitted,
    dashboardStatsLoading,
    items,
    totalCount,
    documentTypes,
  } = useSelector((state: any) => state.edocumentationReducer);
  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);
  const formattedProgrammes = allProgrammes.map((program: any) => ({
    label: program.programmeName,
    value: program.programmeName,
  }));
  const pageCount = Math.ceil(totalCount / 20);

  const filterDocumentTypesByLevel = useMemo(
    () =>
      documentTypes
        .filter((document: any) => document.level === level)
        .map((filteredDoc: any) => ({
          label: filteredDoc.documentType,
          value: filteredDoc.documentType,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [level]
  );
  const handleSearch = useCallback(
    (pageNumber: number, searchParam: string) => {
      dispatch(searchRequests(pageNumber, searchParam));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    if (searchParameter.length) {
      dispatch(handleSearch(newOffset, searchParameter));
    } else {
      dispatch(getAllRequests(newOffset));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(1, searchParameter);
      }, 1000);
    } else {
      dispatch(getAllRequests(1));
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch, dispatch]);

  useEffect(() => {
    if (level && programme && documentType) {
      dispatch(searchRequestByParameters(1, programme, documentType, level));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, programme, documentType]);

  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(loadAllProgrammes());
    dispatch(getDocumentTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocumentType("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  const handleClearFilters = () => {
    setDocumentType("");
    setLevel("");
    setProgramme("");
    dispatch(getAllRequests(1));
  };
  return (
    <div className="e-documentation-dashboard">
      <div className="back">
      <Navigation to="/app/e-documentation" text = "Back to e-Documentation Dasbhoard"/>
      </div>

      {dashboardStatsLoading ? (
        <LoadingItem />
      ) : (
        <>
          {" "}
          <div className="data-cards">
            <div className="card submitted">
              <p>TOTAL SUBMITTED</p>
              <p>{totalSubmitted || 0}</p>
            </div>

            <div className="card cleared">
              <p>TOTAL CLEARED</p>
              <p>{totalCleared || 0}</p>
            </div>

            <div className="card pending">
              <p>TOTAL PENDING</p>
              <p>{totalPending || 0}</p>
            </div>
            <div className="card rejected">
              <p>TOTAL REJECTED</p>
              <p>{totalRejected || 0}</p>
            </div>
          </div>
          <div className="filters">
            <DropDown
              label="Programme"
              options={formattedProgrammes}
              value={{ value: programme, label: programme }}
              field="programme"
              handleChange={setProgramme}
              width="100%"
            />
            <DropDown
              label="Level"
              options={allLevels}
              value={{ label: level, value: level }}
              defaultValue=""
              field="level"
              handleChange={setLevel}
              width="97%"
            />
            <DropDown
              label="Document Type"
              options={filterDocumentTypesByLevel}
              value={{ label: documentType, value: documentType }}
              field="document"
              handleChange={setDocumentType}
              width="97%"
            />
            <div className="clear-filters" onClick={handleClearFilters}>
              <MdClear style={{ fontSize: "24px" }} />
            </div>
          </div>
          <div className="e-documentation-table-search">
            <h5>Documents table</h5>
            <SearchBox
              placeholder="Search..."
              setSearchParameter={setSearchParameter}
              searchParameter={searchParameter}
            />
          </div>
          <DocumentationTable items={items} />
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination_items"
            pageClassName="page_num"
            pageLinkClassName="page_link"
            activeClassName="active_page_link"
            previousClassName="previous_page_link"
            nextClassName="next_page_link"
          />
          {items?.length ? (
            <div className="result-count">
              <p>
                Showing {items?.length} of {totalCount} results
              </p>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default EDocumentationDashboardData;
