export const appConstantsPortal = {
  LOAD_DASHBOARD_SUCCESS: "LOAD_DASHBOARD_SUCCESS",
  LOAD_DASHBOARD_PENDING: "LOAD_DASHBOARD_PENDING",
  LOAD_DASHBOARD_FAILURE: "LOAD_DASHBOARD_FAILURE",
  LOAD_DASHBOARD_RESET: "LOAD_DASHBOARD_RESET",

  FETCH_COURSE_REG_META_SUCCESS: "FETCH_COURSE_REG_META_SUCCESS",
  FETCH_COURSE_REG_META_PENDING: "FETCH_COURSE_REG_META_PENDING",
  FETCH_COURSE_REG_META_FAILURE: "FETCH_COURSE_REG_META_FAILURE",
  FETCH_COURSE_REG_META_RESET: "FETCH_COURSE_REG_META_RESET",

  LOAD_PRESET_COURSE_REG_SUCCESS: "LOAD_PRESET_COURSE_REG_SUCCESS",
  LOAD_PRESET_COURSE_REG_PENDING: "LOAD_PRESET_COURSE_REG_PENDING",
  LOAD_PRESET_COURSE_REG_FAILURE: "LOAD_PRESET_COURSE_REG_FAILURE",
  LOAD_PRESET_COURSE_REG_RESET: "LOAD_PRESET_COURSE_REG_RESET",

  SAVE_PRESET_COURSE_REG_SUCCESS: "SAVE_PRESET_COURSE_REG_SUCCESS",
  SAVE_PRESET_COURSE_REG_PENDING: "SAVE_PRESET_COURSE_REG_PENDING",
  SAVE_PRESET_COURSE_REG_FAILURE: "SAVE_PRESET_COURSE_REG_FAILURE",
  SAVE_PRESET_COURSE_REG_RESET: "SAVE_PRESET_COURSE_REG_RESET",

  DELETE_PRESET_COURSE_REG_SUCCESS: "DELETE_PRESET_COURSE_REG_SUCCESS",
  DELETE_PRESET_COURSE_REG_PENDING: "DELETE_PRESET_COURSE_REG_PENDING",
  DELETE_PRESET_COURSE_REG_FAILURE: "DELETE_PRESET_COURSE_REG_FAILURE",
  DELETE_PRESET_COURSE_REG_RESET: "DELETE_PRESET_COURSE_REG_RESET",

  GET_STUDENTS_LIST_SUCCESS: "GET_STUDENTS_LIST_SUCCESS",
  GET_STUDENTS_LIST_PENDING: "GET_STUDENTS_LIST_PENDING",
  GET_STUDENTS_LIST_FAILURE: "GET_STUDENTS_LIST_FAILURE",
  GET_STUDENTS_LIST_RESET: "GET_STUDENTS_LIST_RESET",

  GET_A_STUDENT_SUCCESS: "GET_A_STUDENT_SUCCESS",
  GET_A_STUDENT_PENDING: "GET_A_STUDENT_PENDING",
  GET_A_STUDENT_FAILURE: "GET_A_STUDENT_FAILURE",
  GET_A_STUDENT_RESET: "GET_A_STUDENT_RESET",

  UPDATE_A_STUDENT_SUCCESS: "UPDATE_A_STUDENT_SUCCESS",
  UPDATE_A_STUDENT_PENDING: "UPDATE_A_STUDENT_PENDING",
  UPDATE_A_STUDENT_FAILURE: "UPDATE_A_STUDENT_FAILURE",
  UPDATE_A_STUDENT_RESET: "UPDATE_A_STUDENT_RESET",

  FETCH_BIODATA_META_SUCCESS: "FETCH_BIODATA_META_SUCCESS",
  FETCH_BIODATA_META_PENDING: "FETCH_BIODATA_META_PENDING",
  FETCH_BIODATA_META_FAILURE: "FETCH_BIODATA_META_FAILURE",
  FETCH_BIODATA_META_RESET: "FETCH_BIODATA_META_RESET",

  GET_AWARDS_IN_VIEW_SUCCESS: "GET_AWARDS_IN_VIEW_SUCCESS",
  GET_AWARDS_IN_VIEW_PENDING: "GET_AWARDS_IN_VIEW_PENDING",
  GET_AWARDS_IN_VIEW_FAILURE: "GET_AWARDS_IN_VIEW_FAILURE",
  GET_AWARDS_IN_VIEW_RESET: "GET_AWARDS_IN_VIEW_RESET",

  ADD_DELETE_AWARDS_IN_VIEW_SUCCESS: "ADD_DELETE_AWARDS_IN_VIEW_SUCCESS",
  ADD_DELETE_AWARDS_IN_VIEW_PENDING: "ADD_DELETE_AWARDS_IN_VIEW_PENDING",
  ADD_DELETE_AWARDS_IN_VIEW_FAILURE: "ADD_DELETE_AWARDS_IN_VIEW_FAILURE",
  ADD_DELETE_AWARDS_IN_VIEW_RESET: "ADD_DELETE_AWARDS_IN_VIEW_RESET",

  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_PENDING: "GET_COUNTRIES_PENDING",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",
  GET_COUNTRIES_RESET: "GET_COUNTRIES_RESET",

  ADD_DELETE_COUNTRIES_SUCCESS: "ADD_DELETE_COUNTRIES_SUCCESS",
  ADD_DELETE_COUNTRIES_PENDING: "ADD_DELETE_COUNTRIES_PENDING",
  ADD_DELETE_COUNTRIES_FAILURE: "ADD_DELETE_COUNTRIES_FAILURE",
  ADD_DELETE_COUNTRIES_RESET: "ADD_DELETE_COUNTRIES_RESET",

  GET_HIGHEST_QUALIFICATIONS_SUCCESS: "GET_HIGHEST_QUALIFICATIONS_SUCCESS",
  GET_HIGHEST_QUALIFICATIONS_PENDING: "GET_HIGHEST_QUALIFICATIONS_PENDING",
  GET_HIGHEST_QUALIFICATIONS_FAILURE: "GET_HIGHEST_QUALIFICATIONS_FAILURE",
  GET_HIGHEST_QUALIFICATIONS_RESET: "GET_HIGHEST_QUALIFICATIONS_RESET",

  ADD_DELETE_HIGHEST_QUALIFICATIONS_SUCCESS:
    "ADD_DELETE_HIGHEST_QUALIFICATIONS_SUCCESS",
  ADD_DELETE_HIGHEST_QUALIFICATIONS_PENDING:
    "ADD_DELETE_HIGHEST_QUALIFICATIONS_PENDING",
  ADD_DELETE_HIGHEST_QUALIFICATIONS_FAILURE:
    "ADD_DELETE_HIGHEST_QUALIFICATIONS_FAILURE",
  ADD_DELETE_HIGHEST_QUALIFICATIONS_RESET:
    "ADD_DELETE_HIGHEST_QUALIFICATIONS_RESET",

  GET_SPECIALITIES_SUCCESS: "GET_SPECIALITIES_SUCCESS",
  GET_SPECIALITIES_PENDING: "GET_SPECIALITIES_PENDING",
  GET_SPECIALITIES_FAILURE: "GET_SPECIALITIES_FAILURE",
  GET_SPECIALITIES_RESET: "GET_SPECIALITIES_RESET",

  ADD_DELETE_SPECIALITIES_SUCCESS: "ADD_DELETE_SPECIALITIES_SUCCESS",
  ADD_DELETE_SPECIALITIES_PENDING: "ADD_DELETE_SPECIALITIES_PENDING",
  ADD_DELETE_SPECIALITIES_FAILURE: "ADD_DELETE_SPECIALITIES_FAILURE",
  ADD_DELETE_SPECIALITIES_RESET: "ADD_DELETE_SPECIALITIES_RESET",

  SET_CURRENT_SESSION_SUCCESS: "SET_CURRENT_SESSION_SUCCESS",
  SET_CURRENT_SESSION_PENDING: "SET_CURRENT_SESSION_PENDING",
  SET_CURRENT_SESSION_FAILURE: "SET_CURRENT_SESSION_FAILURE",
  SET_CURRENT_SESSION_RESET: "SET_CURRENT_SESSION_RESET",

  GET_CURRENT_SESSION_SUCCESS: "GET_CURRENT_SESSION_SUCCESS",
  GET_CURRENT_SESSION_PENDING: "GET_CURRENT_SESSION_PENDING",
  GET_CURRENT_SESSION_FAILURE: "GET_CURRENT_SESSION_FAILURE",
  GET_CURRENT_SESSION_RESET: "GET_CURRENT_SESSION_RESET",

  RESET_STUDENT_PASSWORD_SUCCESS: "RESET_STUDENT_PASSWORD_SUCCESS",
  RESET_STUDENT_PASSWORD_PENDING: "RESET_STUDENT_PASSWORD_PENDING",
  RESET_STUDENT_PASSWORD_FAILURE: "RESET_STUDENT_PASSWORD_FAILURE",
  RESET_STUDENT_PASSWORD_RESET: "RESET_STUDENT_PASSWORD_RESET",

  RESET_ADMIN_PASSWORD_SUCCESS: "RESET_ADMIN_PASSWORD_SUCCESS",
  RESET_ADMIN_PASSWORD_PENDING: "RESET_ADMIN_PASSWORD_PENDING",
  RESET_ADMIN_PASSWORD_FAILURE: "RESET_ADMIN_PASSWORD_FAILURE",
  RESET_ADMIN_PASSWORD_RESET: "RESET_ADMIN_PASSWORD_RESET",

  UPDATE_A_STUDENT_STATUS_SUCCESS: "UPDATE_A_STUDENT_STATUS_SUCCESS",
  UPDATE_A_STUDENT_STATUS_PENDING: "UPDATE_A_STUDENT_STATUS_PENDING",
  UPDATE_A_STUDENT_STATUS_FAILURE: "UPDATE_A_STUDENT_STATUS_FAILURE",
  UPDATE_A_STUDENT_STATUS_RESET: "UPDATE_A_STUDENT_STATUS_RESET",

  UPDATE_A_STUDENT_ORIGIN_SUCCESS: "UPDATE_A_STUDENT_ORIGIN_SUCCESS",
  UPDATE_A_STUDENT_ORIGIN_PENDING: "UPDATE_A_STUDENT_ORIGIN_PENDING",
  UPDATE_A_STUDENT_ORIGIN_FAILURE: "UPDATE_A_STUDENT_ORIGIN_FAILURE",
  UPDATE_A_STUDENT_ORIGIN_RESET: "UPDATE_A_STUDENT_ORIGIN_RESET",

  UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS:
    "UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS",
  UPDATE_A_STUDENT_COURSE_OFSTUDY_PENDING:
    "UPDATE_A_STUDENT_COURSE_OFSTUDY_PENDING",
  UPDATE_A_STUDENT_COURSE_OFSTUDY_FAILURE:
    "UPDATE_A_STUDENT_COURSE_OFSTUDY_FAILURE",
  UPDATE_A_STUDENT_COURSE_OFSTUDY_RESET:
    "UPDATE_A_STUDENT_COURSE_OFSTUDY_RESET",

  GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS:
    "GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS",
  GET_STUDENT_PROGRAMCHANGE_HISTORY_PENDING:
    "GET_STUDENT_PROGRAMCHANGE_HISTORY_PENDING",
  GET_STUDENT_PROGRAMCHANGE_HISTORY_FAILURE:
    "GET_STUDENT_PROGRAMCHANGE_HISTORY_FAILURE",
  GET_STUDENT_PROGRAMCHANGE_HISTORY_RESET:
    "GET_STUDENT_PROGRAMCHANGE_HISTORY_RESET",

  CREATE_A_USER_SUCCESS: "CREATE_A_USER_SUCCESS",
  CREATE_A_USER_PENDING: "CREATE_A_USER_PENDING",
  CREATE_A_USER_FAILURE: "CREATE_A_USER_FAILURE",
  CREATE_A_USER_RESET: "CREATE_A_USER_RESET",

  ACTIVATE_OR_DEACTIVATE_USER_SUCCESS: "ACTIVATE_OR_DEACTIVATE_USER_SUCCESS",
  ACTIVATE_OR_DEACTIVATE_USER_PENDING: "ACTIVATE_OR_DEACTIVATE_USER_PENDING",
  ACTIVATE_OR_DEACTIVATE_USER_FAILURE: "ACTIVATE_OR_DEACTIVATE_USER_FAILURE",
  ACTIVATE_OR_DEACTIVATE_USER_RESET: "ACTIVATE_OR_DEACTIVATE_USER_RESET",

  CREATE_A_ROLE_SUCCESS: "CREATE_A_ROLE_SUCCESS",
  CREATE_A_ROLE_PENDING: "CREATE_A_ROLE_PENDING",
  CREATE_A_ROLE_FAILURE: "CREATE_A_ROLE_FAILURE",
  CREATE_A_ROLE_RESET: "CREATE_A_ROLE_RESET",

  UPDATE_A_USER_ROLE_SUCCESS: "UPDATE_A_USER_ROLE_SUCCESS",
  UPDATE_A_USER_ROLE_PENDING: "UPDATE_A_USER_ROLE_PENDING",
  UPDATE_A_USER_ROLE_FAILURE: "UPDATE_A_USER_ROLE_FAILURE",
  UPDATE_A_USER_ROLE_RESET: "UPDATE_A_USER_ROLE_RESET",

  GET_ALL_APIS_SUCCESS: "GET_ALL_APIS_SUCCESS",
  GET_ALL_APIS_PENDING: "GET_ALL_APIS_PENDING",
  GET_ALL_APIS_FAILURE: "GET_ALL_APIS_FAILURE",
  GET_ALL_APIS_RESET: "GET_ALL_APIS_RESET",

  GET_ALL_USER_ROLES_SUCCESS: "GET_ALL_USER_ROLES_SUCCESS",
  GET_ALL_USER_ROLES_PENDING: "GET_ALL_USER_ROLES_PENDING",
  GET_ALL_USER_ROLES_FAILURE: "GET_ALL_USER_ROLES_FAILURE",
  GET_ALL_USER_ROLES_RESET: "GET_ALL_USER_ROLES_RESET",

  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_PENDING: "GET_ALL_USERS_PENDING",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",
  GET_ALL_USERS_RESET: "GET_ALL_USERS_RESET",

  GET_A_USER_SUCCESS: "GET_A_USER_SUCCESS",
  GET_A_USER_PENDING: "GET_A_USER_PENDING",
  GET_A_USER_FAILURE: "GET_A_USER_FAILURE",
  GET_A_USER_RESET: "GET_A_USER_RESET",

  GET_A_USER_PROFILE_SUCCESS: "GET_A_USER_PROFILE_SUCCESS",
  GET_A_USER_PROFILE_PENDING: "GET_A_USER_PROFILE_PENDING",
  GET_A_USER_PROFILE_FAILURE: "GET_A_USER_PROFILE_FAILURE",
  GET_A_USER_PROFILE_RESET: "GET_A_USER_PROFILE_RESET",

  ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS:
    "ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS",
  ASSIGN_COURSE_ADVISOR_TO_DEPT_PENDING:
    "ASSIGN_COURSE_ADVISOR_TO_DEPT_PENDING",
  ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE:
    "ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE",
  ASSIGN_COURSE_ADVISOR_TO_DEPT_RESET: "ASSIGN_COURSE_ADVISOR_TO_DEPT_RESET",

  NEW_USER_META_SUCCESS: "NEW_USER_META_SUCCESS",
  NEW_USER_META_PENDING: "NEW_USER_META_PENDING",
  NEW_USER_META_FAILURE: "NEW_USER_META_FAILURE",
  NEW_USER_META_RESET: "NEW_USER_META_RESET",

  GET_STUDENT_INVOICE_SUCCESS: "GET_STUDENT_INVOICE_SUCCESS",
  GET_STUDENT_INVOICE_PENDING: "GET_STUDENT_INVOICE_PENDING",
  GET_STUDENT_INVOICE_FAILURE: "GET_STUDENT_INVOICE_FAILURE",
  GET_STUDENT_INVOICE_RESET: "GET_STUDENT_INVOICE_RESET",

  UPDATE_STUDENT_INVOICE_SUCCESS: "UPDATE_STUDENT_INVOICE_SUCCESS",
  UPDATE_STUDENT_INVOICE_PENDING: "UPDATE_STUDENT_INVOICE_PENDING",
  UPDATE_STUDENT_INVOICE_FAILURE: "UPDATE_STUDENT_INVOICE_FAILURE",
  UPDATE_STUDENT_INVOICE_RESET: "UPDATE_STUDENT_INVOICE_RESET",

  GET_BURSARY_SCHOOLFEE_REPORTS_SUCCESS:
    "GET_BURSARY_SCHOOLFEE_REPORTS_SUCCESS",
  GET_BURSARY_SCHOOLFEE_REPORTS_PENDING:
    "GET_BURSARY_SCHOOLFEE_REPORTS_PENDING",
  GET_BURSARY_SCHOOLFEE_REPORTS_FAILURE:
    "GET_BURSARY_SCHOOLFEE_REPORTS_FAILURE",
  GET_BURSARY_SCHOOLFEE_REPORTS_RESET: "GET_BURSARY_SCHOOLFEE_REPORTS_RESET",

  DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_SUCCESS:
    "DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_SUCCESS",
  DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_PENDING:
    "DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_PENDING",
  DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_FAILURE:
    "DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_FAILURE",
  DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_RESET:
    "DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_RESET",

  GET_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS:
    "GET_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS",
  GET_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING:
    "GET_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING",
  GET_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE:
    "GET_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE",
  GET_BURSARY_ACCEPTANCEFEE_REPORTS_RESET:
    "GET_BURSARY_ACCEPTANCEFEE_REPORTS_RESET",

  DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS:
    "DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS",
  DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING:
    "DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING",
  DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE:
    "DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE",
  DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_RESET:
    "DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_RESET",

  GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS:
    "GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS",
  GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING:
    "GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING",
  GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE:
    "GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE",
  GET_BURSARY_ADDITIONALFEE_REPORTS_RESET:
    "GET_BURSARY_ADDITIONALFEE_REPORTS_RESET",

  DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS:
    "DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS",
  DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_PENDING:
    "DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_PENDING",
  DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE:
    "DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE",
  DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_RESET:
    "DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_RESET",

  GET_MATRICULATION_REPORTS_SUCCESS: "GET_MATRICULATION_REPORTS_SUCCESS",
  GET_MATRICULATION_REPORTS_PENDING: "GET_MATRICULATION_REPORTS_PENDING",
  GET_MATRICULATION_REPORTS_FAILURE: "GET_MATRICULATION_REPORTS_FAILURE",
  GET_MATRICULATION_REPORTS_RESET: "GET_MATRICULATION_REPORTS_RESET",

  DOWNLOAD_MATRICULATION_REPORT_SUCCESS:
    "DOWNLOAD_MATRICULATION_REPORT_SUCCESS",
  DOWNLOAD_MATRICULATION_REPORT_PENDING:
    "DOWNLOAD_MATRICULATION_REPORT_PENDING",
  DOWNLOAD_MATRICULATION_REPORT_FAILURE:
    "DOWNLOAD_MATRICULATION_REPORT_FAILURE",
  DOWNLOAD_MATRICULATION_REPORT_RESET: "DOWNLOAD_MATRICULATION_REPORT_RESET",

  GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS:
    "GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS",
  GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING:
    "GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING",
  GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE:
    "GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE",
  GET_NEW_STUDENTS_PAYMENTS_REPORTS_RESET:
    "GET_NEW_STUDENTS_PAYMENTS_REPORTS_RESET",

  DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS:
    "DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS",
  DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING:
    "DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING",
  DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE:
    "DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE",
  DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_RESET:
    "DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_RESET",

  GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS:
    "GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS",
  GET_STUDENTS_REGISTRATION_REPORTS_PENDING:
    "GET_STUDENTS_REGISTRATION_REPORTS_PENDING",
  GET_STUDENTS_REGISTRATION_REPORTS_FAILURE:
    "GET_STUDENTS_REGISTRATION_REPORTS_FAILURE",
  GET_STUDENTS_REGISTRATION_REPORTS_RESET:
    "GET_STUDENTS_REGISTRATION_REPORTS_RESET",

  DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS:
    "DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS",
  DOWNLOAD_STUDENTS_REGISTRATION_REPORT_PENDING:
    "DOWNLOAD_STUDENTS_REGISTRATION_REPORT_PENDING",
  DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE:
    "DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE",
  DOWNLOAD_STUDENTS_REGISTRATION_REPORT_RESET:
    "DOWNLOAD_STUDENTS_REGISTRATION_REPORT_RESET",

  GET_NELFUND_REPORTS_SUCCESS: "GET_NELFUND_REPORTS_SUCCESS",
  GET_NELFUND_REPORTS_PENDING: "GET_NELFUND_REPORTS_PENDING",
  GET_NELFUND_REPORTS_FAILURE: "GET_NELFUND_REPORTS_FAILURE",

  GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS: "GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS",
  GET_NELFUND_DOWNLOAD_REPORTS_PENDING: "GET_NELFUND_DOWNLOAD_REPORTS_PENDING",
  GET_NELFUND_DOWNLOAD_REPORTS_FAILURE: "GET_NELFUND_DOWNLOAD_REPORTS_FAILURE",
  GET_NELFUND_DOWNLOAD_REPORTS_RESET: "GET_NELFUND_DOWNLOAD_REPORTS_RESET",

  PRINT_BIODATA_SUCCESS: "PRINT_BIODATA_SUCCESS",
  PRINT_BIODATA_FAILURE: "PRINT_BIODATA_FAILURE",

  GET_EO_REGISTRATION_REPORTS_SUCCESS: "GET_EO_REGISTRATION_REPORTS_SUCCESS",
  GET_EO_REGISTRATION_REPORTS_PENDING: "GET_EO_REGISTRATION_REPORTS_PENDING",
  GET_EO_REGISTRATION_REPORTS_FAILURE: "GET_EO_REGISTRATION_REPORTS_FAILURE",
  GET_EO_REGISTRATION_REPORTS_RESET: "GET_EO_REGISTRATION_REPORTS_RESET",

  DOWNLOAD_EO_REGISTRATION_REPORTS_SUCCESS:
    "DOWNLOAD_EO_REGISTRATION_REPORTS_SUCCESS",
  DOWNLOAD_EO_REGISTRATION_REPORTS_PENDING:
    "DOWNLOAD_EO_REGISTRATION_REPORTS_PENDING",
  DOWNLOAD_EO_REGISTRATION_REPORTS_FAILURE:
    "DOWNLOAD_EO_REGISTRATION_REPORTS_FAILURE",
  DOWNLOAD_EO_REGISTRATION_REPORTS_RESET:
    "DOWNLOAD_EO_REGISTRATION_REPORTS_RESET",
  GET_SESSION_HISTORY: 'GET_SESSION_HISTORY',


  UPDATE_DEFERRED_STUDENT_STATUS_SUCCESS: "UPDATE_DEFERRED_STUDENT_STATUS_SUCCESS",
  UPDATE_DEFERRED_STUDENT_STATUS_PENDING: "UPDATE_DEFERRED_STUDENT_STATUS_PENDING",
  UPDATE_DEFERRED_STUDENT_STATUS_FAILURE: "UPDATE_DEFERRED_STUDENT_STATUS_FAILURE",
  UPDATE_DEFERRED_STUDENT_STATUS_RESET: "UPDATE_DEFERRED_STUDENT_STATUS_RESET",
};
