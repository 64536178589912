import React from 'react'
import { Link } from 'react-router-dom'
import Navigation from '../../../shared/components/navigation/Navigation'

const ReportsCards = () => {
  return (
    <div className="putme-header">
    <div className="back">
    <Navigation to="/app/postutme" text="Back to Post-UTME Dasbhoard" />
  </div>

    <div className="settings_list">
    <div className="each_setting">
      <div className="setting_name">Submission Reports</div>
      <div className="setting_link">
        <Link className="btn" to="/app/postutme/reports">
          Generate Reports
        </Link>{" "}
      </div>
    </div>
    <div className="each_setting">
      <div className="setting_name">PASS Feed Reports</div>
      <div className="setting_link">
        <Link className="btn" to="/app/putme/passfeedback-reports">
          Generate Reports
        </Link>{" "}
      </div>
    </div>

    <div className="each_setting">
      <div className="setting_name">PASS Feed With Scratch Card Reports</div>
      <div className="setting_link">
        <Link className="btn" to="/app/putme/passfeedback-scratchcard-reports">
          Generate Reports
        </Link>{" "}
      </div>
    </div>

    <div className="each_setting">
      <div className="setting_name">Eligibility Reports</div>
      <div className="setting_link">
        <Link className="btn" to="/app/putme/eligibility-reports">
          Generate Reports
        </Link>{" "}
      </div>
    </div>
  </div>
  </div>
  )
}

export default ReportsCards

