
import Dash4 from "../../../assets/dash-4.png";
import "./index.scss";

const PostUtmeCard = ({ itemName, color, count }: any) => {

  return (
    <div className="putme_stat" style={{ color }}>
      <div className="stat_icon">
        <img src={Dash4} alt="" />
      </div>
      <div className="stat_values">
        <div className="stat">{itemName}</div>
        <div className="vaalue">
          <h2> {count} </h2>
        </div>
      </div>
    </div>
  );
};

export default PostUtmeCard;
