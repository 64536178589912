import { connect } from "react-redux";
import { useEffect, useState } from "react";
import distinctColors from "distinct-colors";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal/";
import Dash1 from "../../assets/dash-1.png";
import Dash2 from "../../assets/dash-2.png";
import Dash3 from "../../assets/dash-3.png";
import Dash4 from "../../assets/dash-4.png";
import Dash5 from "../../assets/dash-5.png";
import Dash6 from "../../assets/dash-6.png";
import Dash7 from "../../assets/dash-7.png";
import Dash8 from "../../assets/dash-8.png";
import Dash9 from "../../assets/dash-9.png";
import Dash10 from "../../assets/dash-10.png";
import "./index.scss";
const adminGetDashabordRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadDashboard(payload);
};

const DashboardContentItems = ({ data }: any) => {
  const [activeTab, setActiveTab] = useState<string>("tab_1");
  const dataInfo = data?.request_data?.response?.data;

  var palette: any[] =
    distinctColors({ count: Object.keys(dataInfo).length }) || [];

  let allColors: any[] = palette.map((each: any) => `${each}`);

  return (
    <div>
      <div className="all_tabs_wrap">
        <div
          onClick={() => setActiveTab("tab_1")}
          className={activeTab === "tab_1" ? "each_tab is_Active" : "each_tab"}
        >
          Student Data
        </div>
        <div
          onClick={() => setActiveTab("tab_2")}
          className={activeTab === "tab_2" ? "each_tab is_Active" : "each_tab"}
        >
          Acceptance Fees
        </div>
        <div
          onClick={() => setActiveTab("tab_3")}
          className={activeTab === "tab_3" ? "each_tab is_Active" : "each_tab"}
        >
          School Fees
        </div>
        <div
          onClick={() => setActiveTab("tab_4")}
          className={activeTab === "tab_4" ? "each_tab is_Active" : "each_tab"}
        >
          Other Fees
        </div>
        <div
          onClick={() => setActiveTab("tab_5")}
          className={activeTab === "tab_5" ? "each_tab is_Active" : "each_tab"}
        >
          Academics
        </div>
      </div>

      <div className="dashboard_stats">
        {activeTab === "tab_1" && (
          <div className="tab1_container">
            <div className="top_container">
              <div
                className="top_stat"
                style={{ backgroundColor: allColors[27] }}
              >
                <div className="top_icon">
                  <img src={Dash1} alt="" />
                </div>
                <div className="top_values">
                  <div className="stat">CURRENT SESSION</div>
                  <div className="vaalue">{dataInfo?.currentSession} </div>
                </div>
              </div>
            </div>

            <div className="dashboard_stats">
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[7] }}
              >
                <div className="stat_icon">
                  <img src={Dash2} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">TOTAL STUDENTS</div>
                  <div className="vaalue">{dataInfo?.totalStudents} </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[2] }}
              >
                <div className="stat_icon">
                  <img src={Dash3} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">NEW STUDENTS</div>
                  <div className="vaalue">
                    {dataInfo?.totalNewAdmittedStudents}{" "}
                  </div>
                </div>
              </div>

              <div
                className="each_stat"
                style={{ backgroundColor: allColors[26] }}
              >
                <div className="stat_icon">
                  <img src={Dash8} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">RETURNING STUDENTS</div>
                  <div className="vaalue">
                    {dataInfo?.totalReturningStudents}{" "}
                  </div>
                </div>
              </div>

              <div
                className="each_stat"
                style={{ backgroundColor: allColors[9] }}
              >
                <div className="stat_icon">
                  <img src={Dash10} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">TOTAL INDIGENES</div>
                  <div className="vaalue">{dataInfo?.totalIndigene} </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[28] }}
              >
                <div className="stat_icon">
                  <img src={Dash10} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">TOTAL NON - INDIGENES</div>
                  <div className="vaalue">{dataInfo?.totalNonIndigene} </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[1] }}
              >
                <div className="stat_icon">
                  <img src={Dash10} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">TOTAL NON - NIGERIANS</div>
                  <div className="vaalue">{dataInfo?.totalNonNigerian} </div>
                </div>
              </div>

              <div
                className="each_stat"
                style={{ backgroundColor: allColors[5] }}
              >
                <div className="stat_icon">
                  <img src={Dash3} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">TOTAL NEW STUDENTS (INDIGENES)</div>
                  <div className="vaalue">
                    {dataInfo?.totalNewAdmittedStudentsByIndigene}{" "}
                  </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[18] }}
              >
                <div className="stat_icon">
                  <img src={Dash3} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">
                    TOTAL NEW STUDENTS <br /> (NON - INDIGENES)
                  </div>
                  <div className="vaalue">
                    {dataInfo?.totalNewAdmittedStudentsByNonIndigene}{" "}
                  </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[4] }}
              >
                <div className="stat_icon">
                  <img src={Dash3} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">
                    TOTAL NEW STUDENTS <br /> (NON - NIGERIANS)
                  </div>
                  <div className="vaalue">
                    {dataInfo?.totalNewAdmittedStudentsByNonNigerian}{" "}
                  </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[15] }}
              >
                <div className="stat_icon">
                  <img src={Dash8} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">
                    TOTAL RETURNING STUDENTS <br /> (INDIGENES)
                  </div>
                  <div className="vaalue">
                    {dataInfo?.totalReturningStudentsByIndigene}{" "}
                  </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[19] }}
              >
                <div className="stat_icon">
                  <img src={Dash8} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">
                    TOTAL RETURNING STUDENTS <br /> (NON - INDIGENES)
                  </div>
                  <div className="vaalue">
                    {dataInfo?.totalReturningStudentsByNonIndigene}{" "}
                  </div>
                </div>
              </div>
              <div
                className="each_stat"
                style={{ backgroundColor: allColors[14] }}
              >
                <div className="stat_icon">
                  <img src={Dash8} alt="" />
                </div>
                <div className="stat_values">
                  <div className="stat">
                    TOTAL RETURNING STUDENTS <br /> (NON - NIGERIANS)
                  </div>
                  <div className="vaalue">
                    {dataInfo?.totalReturningStudentsByNonNigerian}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "tab_2" && (
          <>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[1] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptanceInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[4] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptancePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[5] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptanceUnpaidInvoice}{" "}
                </div>
              </div>
            </div>

            <div
              className="each_stat"
              style={{ backgroundColor: allColors[12] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (INDIGENES)</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptanceInvoiceByIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[3] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (NON-INDIGINES)</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptanceInvoiceByNonIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[28] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (NON-NIGERIANS)</div>
                <div className="vaalue">
                  {dataInfo?.totalAcceptanceInvoiceByNonNigerian}{" "}
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === "tab_3" && (
          <>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[6] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL INVOICES</div>
                <div className="vaalue">{dataInfo?.totalInvoice} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[7] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES</div>
                <div className="vaalue">{dataInfo?.totalPaidInvoice} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[8] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING INVOICES</div>
                <div className="vaalue">{dataInfo?.totalUnpaidInvoice} </div>
              </div>
            </div>

            <div
              className="each_stat"
              style={{ backgroundColor: allColors[9] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (INDIGENES)</div>
                <div className="vaalue">
                  {dataInfo?.totalPaidInvoiceByIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[15] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (NON-INDIGENES)</div>
                <div className="vaalue">
                  {dataInfo?.totalPaidInvoiceByNonIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[14] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PAID INVOICES (NON-NIGERIANS)</div>
                <div className="vaalue">
                  {dataInfo?.totalPaidInvoiceByNonNigerian}{" "}
                </div>
              </div>
            </div>

            <div
              className="each_stat"
              style={{ backgroundColor: allColors[23] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID NEW STUDENTS <br /> (INDIGENES)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidNewStudentsByIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[13] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID NEW STUDENTS <br /> (NON-INDIGENES)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidNewStudentsByNonIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[4] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID NEW STUDENTS <br /> (NON-NIGERIANS)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidNewStudentsByNonNigerian}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[30] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID RETURNING STUDENTS <br /> (INDIGENES)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidReturningStudentsByIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[17] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID RETURNING STUDENTS <br /> (NON-INDIGENES)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidReturningStudentsByNonIndigene}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[1] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL PAID RETURNING STUDENTS <br /> (NON-NIGERIANS)
                </div>
                <div className="vaalue">
                  {dataInfo?.totalPaidReturningStudentsByNonNigerian}{" "}
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === "tab_4" && (
          <>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[9] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL FACULTY FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalFacultyFeeInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[10] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL FACULTY FEES PAID INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalFacultyFeePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[11] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING FACULTY FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalFacultyFeeUnpaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[12] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL SUG FEES INVOICES</div>
                <div className="vaalue">{dataInfo?.totalSugFeeInvoice} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[13] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL SUG FEES PAID INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalSugFeePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[14] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING SUG FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalSugFeeUnpaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[15] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL GST FEES INVOICES</div>
                <div className="vaalue">{dataInfo?.totalGstFeeInvoice} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[9] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL GST FEES PAID INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalGstFeePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[8] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING GST FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalGstFeeUnpaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[18] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL ENTREPRENEUR FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalEntrepreneurFeeInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[19] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">
                  TOTAL ENTREPRENEUR FEES PAID INVOICES
                </div>
                <div className="vaalue">
                  {dataInfo?.totalEntrepreneurFeePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[20] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING ENTREPRENEUR FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalEntrepreneureeUnpaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[21] }}
            >
              <div className="stat_icon">
                <img src={Dash4} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL NANISS FEES INVOICES</div>
                <div className="vaalue">{dataInfo?.totalNanissFeeInvoice} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[8] }}
            >
              <div className="stat_icon">
                <img src={Dash5} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL NANISS FEES PAID INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalNanissFeePaidInvoice}{" "}
                </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[23] }}
            >
              <div className="stat_icon">
                <img src={Dash6} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">PENDING NANISS FEES INVOICES</div>
                <div className="vaalue">
                  {dataInfo?.totalNanissUnpaidInvoice}{" "}
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === "tab_5" && (
          <>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[24] }}
            >
              <div className="stat_icon">
                <img src={Dash7} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL FACULTY</div>
                <div className="vaalue">{dataInfo?.totalFaculty} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[25] }}
            >
              <div className="stat_icon">
                <img src={Dash8} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL DEPARTMENT</div>
                <div className="vaalue">{dataInfo?.totalDepartment} </div>
              </div>
            </div>
            <div
              className="each_stat"
              style={{ backgroundColor: allColors[26] }}
            >
              <div className="stat_icon">
                <img src={Dash9} alt="" />
              </div>
              <div className="stat_values">
                <div className="stat">TOTAL PROGRAMMES</div>
                <div className="vaalue">{dataInfo?.totalProgramme} </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const LoadDashboard = ({ pageProps }: any) => {
  let loadAllStudentsRequest = pageProps?.adminGetDashboardRequest;
  useEffect(() => {
    let payload = "";
    adminGetDashabordRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.LOAD_DASHBOARD_PENDING && (
        <div className="select_all_msm">
          <LoadingItem />
        </div>
      )}
      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.LOAD_DASHBOARD_FAILURE && (
        <div className="justify-center">
          <AlertMsg
            type="error"
            message={loadAllStudentsRequest?.request_data.error}
          />
        </div>
      )}

      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.LOAD_DASHBOARD_SUCCESS && (
        <DashboardContentItems data={loadAllStudentsRequest} />
      )}
    </>
  );
};

const DashboardContent = ({ pageProps }: any) => {
  return (
    <div className="dashboard_wrap">
      <LoadDashboard pageProps={pageProps} />
    </div>
  );
};

const AdminDashboardWrap = (pageProps: any) => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  return (
    <InAppTemplate
      pageTitle={`DASHBOARD FOR CURRENT SESSION ${loggedAccountInfo?.currentSession?.session}`}
      childComponent={<DashboardContent pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminLoadDashboard: adminInAppActions.AdminLoadDashboard,
};
const mapStateToProps = (state: any) => ({
  adminGetDashboardRequest:
    state.allUGradPortalReducers.adminGetDashboardReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardWrap);
