import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import Room from "./Room";
import { ToastContainer } from "react-toastify";

const RoomDetails = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="Hostel Room Management"
        childComponent={<Room />}
      />
    </>
  );
};

export default RoomDetails;
