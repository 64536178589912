import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { Dispatch } from "redux";
import { history } from "../../../shared/_helpers/history";

import { appConstantsOnboarding } from "../../action-constants/onboarding";

import { handleRequestErrors } from "../../../shared/utils";
import { appConstantsPortal } from "../../action-constants/portal"; 

const sessionHistoryAction = (payload:any)=>{
  return {
    type: appConstantsPortal.GET_SESSION_HISTORY,
    payload
  }
}

const AdminLogin = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let userData: any;
      let apiConsume = ApiService.request({
        url: `${routes.ADMIN_LOGIN}`,
        http_method: "POST",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          userData = { ...response.data };
          userData.lastLogForAuth = Date.now();
          localStorage.setItem("adXAuth", JSON.stringify(userData));

          let apiConsume2 = ApiService.request({
            url: `${routes.GET_CURRENT_SESSION}`,
            http_method: "GET",
            data: null,
            headers: { "X-Signature": routes.apiKey },
          });
          // dispatch(request(apiConsume));
          return apiConsume2
            .then((response2: any) => {
              // userData = { ...response.data };
              userData.currentSession = response2.data;
              localStorage.setItem("adXAuth", JSON.stringify(userData));

              // dispatch(success(response.data, actionPayload?.userName))

              // let decodedToken: any = parseJwt(response.data?.access_token)
              // if (decodedToken?.role !== "Password_Resetter") {
              //     history.replace("/app")
              // } else

              // {
              //     history.replace("/app/portal-settings/reset-student-password")
              // }

              let apiConsume3 = ApiService.request({
                url: `${routes.GET_A_USER}`,
                http_method: "GET",
                data: null,
                headers: { "X-Signature": routes.apiKey },
              });

              return apiConsume3
                .then((response3: any) => {
                  userData.profile = response3?.data;
                  localStorage.setItem("adXAuth", JSON.stringify(userData));

                  dispatch(success(response.data, actionPayload?.userName));

                  if (response3?.data?.hasChangedDefaultPassword === true) {
                    let userRoles: any[] = userData?.profile?.userRoles || [];
                    userRoles = userRoles.filter((c: any, index: any) => {
                      return userRoles.indexOf(c) === index;
                    });

                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("Password_Resetter")
                    ) {
                      history.replace(
                        "/app/portal-settings/reset-student-password"
                      );
                    }

                    // if(userRoles.length==1 && userRoles.includes("Course_Advisor")){
                    //     history.replace("/app/course-management/undergraduate")
                    // }

                    if (
                      userRoles.includes("Admin") ||
                      userRoles.includes("Bursary")
                    ) {
                      history.replace("/app");
                    }

                    if (
                      userRoles.length >= 1 &&
                      (userRoles.includes("Course_Advisor") || userRoles.includes("Course_Presetter"))
                    ) {
                      history.replace("/app/course-management/undergraduate");
                    }
                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("Course_Manager")
                    ) {
                      history.replace("/app/course-subject");
                    }
                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("EO_Report")
                    ) {
                      history.replace("/app/eo-reports/exam/registration-report");
                    }
                    if (userRoles.includes("Admission_Manager")) {
                      history.replace("/app/admissions-management");
                    }

                    if (userRoles.includes("PostUtme_Manager")) {
                      history.replace("/app/postutme");
                    }
                    if (userRoles.includes("EDocumentation_Manager")) {
                      history.replace("/app/e-documentation");
                    }

                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("Student_Creator")
                    ) {
                      history.replace("/app/portal-settings");
                    }
                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("Student_Manager")
                    ) {
                      history.replace("/app/portal-settings");
                    }

                    if (
                      userRoles.includes("Nanniss_Manager") ||
                      userRoles.includes("Sug_Manager")
                    ) {
                      history.replace("/app/bursary-reports/additional-fees");
                    }
                    if (
                      userRoles.includes("Hostel_Manager") ||
                      userRoles.includes("Hostel_Porter")
                    ) {
                      history.replace("/app/hostel/dashboard");
                    }

                    if (
                      userRoles.length >= 1 &&
                      userRoles.includes("Mis_Manager")
                    ) {
                      history.replace("/app/portal-settings");
                    }
                    // if (
                    //   userRoles.includes("Mis_Manager") 
                    // ) {
                    //   history.replace("/app/portal-settings");
                    // }
                
                    
                  } else {
                    history.replace(
                      "/app/portal-settings/reset-admin-password"
                    );
                  }
                })
                .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
                });
            })
            .catch((error: any) => {
              dispatch(failure(handleRequestErrors(error)));
            });
        })
        .catch((error: any) => {
          if (error?.response?.data === "invalid_grant") {
            dispatch(failure("Username or Password is incorrect"));
          } else {
            dispatch(failure(handleRequestErrors(error)));
          }
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsOnboarding.LOGIN_USER_PENDING, params };
  }
  function success(response: any, username: string) {
    return {
      type: appConstantsOnboarding.LOGIN_USER_SUCCESS,
      response,
      username,
    };
  }
  function failure(error: any) {
    return { type: appConstantsOnboarding.LOGIN_USER_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsOnboarding.LOGIN_USER_RESET, clear_data: "" };
  }
};

const AdminForgotPw = (actionPayload: any) => {


  if (actionPayload !== "CLEAR") {

      return (dispatch: Dispatch) => {
          
          let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_FORGOT_PW}${actionPayload}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
          dispatch(request(apiConsume));
          return apiConsume
              .then((response: any) => {
                  dispatch(success(response?.data))
              })
              .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
              });
      };
  }

  return (dispatch: Dispatch) => {
      dispatch(clear());
  };

  function request(params: any) {
      return { type: appConstantsOnboarding.FORGOT_PASSWORD_PENDING, params };
  }
  function success(response: any) {
      return { type: appConstantsOnboarding.FORGOT_PASSWORD_SUCCESS, response };
  }
  function failure(error: any) {
      return { type: appConstantsOnboarding.FORGOT_PASSWORD_FAILURE, error };
  }
  function clear() {
      return { type: appConstantsOnboarding.FORGOT_PASSWORD_RESET, clear_data: "" };
  }
}

const AdminVerifyForgotPwCode = (actionPayload: any) => {


  if (actionPayload !== "CLEAR") {

      return (dispatch: Dispatch) => {
         
          let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_VERIFY_PW_TOKEN}${actionPayload}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
          dispatch(request(apiConsume));
          return apiConsume
              .then((response: any) => {
                  dispatch(success(response?.data))
              })
              .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
              });
      };
  }

  return (dispatch: Dispatch) => {
      dispatch(clear());
  };

  function request(params: any) {
      return { type: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_PENDING, params };
  }
  function success(response: any) {
      return { type: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_SUCCESS, response };
  }
  function failure(error: any) {
      return { type: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_FAILURE, error };
  }
  function clear() {
      return { type: appConstantsOnboarding.VERFIY_PASSWORD_TOKEN_RESET, clear_data: "" };
  }
}

const AdminUpdateNewPassword = (actionPayload: any) => {


  if (actionPayload !== "CLEAR") {

      return (dispatch: Dispatch) => {
          
          let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_RESET_PW}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
          dispatch(request(apiConsume));
          return apiConsume
              .then((response: any) => {
                  dispatch(success(response?.data))
              })
              .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
              });
      };
  }

  return (dispatch: Dispatch) => {
      dispatch(clear());
  };

  function request(params: any) {
      return { type: appConstantsOnboarding.UPDATE_NEW_PASSWORD_PENDING, params };
  }
  function success(response: any) {
      return { type: appConstantsOnboarding.UPDATE_NEW_PASSWORD_SUCCESS, response };
  }
  function failure(error: any) {
      return { type: appConstantsOnboarding.UPDATE_NEW_PASSWORD_FAILURE, error };
  }
  function clear() {
      return { type: appConstantsOnboarding.UPDATE_NEW_PASSWORD_RESET, clear_data: "" };
  }
}

export const getSchoolSessionHistory = () => async(dispatch: Dispatch)=>{
  const response =  await ApiService.request({ url: routes.SCHOOL_SESSION_HISTORY, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } })
  if(response.status === 200){
    dispatch(sessionHistoryAction(response.data))
  }

}

const Logout = () => {
  localStorage.removeItem("adXAuth");
  localStorage.removeItem("state");
  history.push("/");
};
const clearStateAction =()=> {
  return { type: appConstantsOnboarding.LOGOUT };
}
export const resetReload = () => {
  localStorage.removeItem("adRld");
};
export const adminOnboarding = {
  AdminLogin,
  AdminForgotPw,
  AdminVerifyForgotPwCode,
  AdminUpdateNewPassword,
  Logout,
  clearStateAction
};
