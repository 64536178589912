import React, { useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { DotLoader } from "react-spinners";
import InputField from "../../../../shared/components/InputField";
import "./index.scss";
import SearchMatricTable from "./SearchMatricTable";
import { getStudentMatricSearch  } from "../../../../redux/action/hostels";

const SearchMatric = () => {

  const initialValue = {
    matricNumber: "",
  };

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>(null);

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });


  return (
    <div className="search-matric-container">
      <div className="report-container">
        <div className="back">
          <Navigation to="/app/hostel/reports" text="Back to Hostel Reports" />
        </div>

        <div className="report-page">
          <Formik
            initialValues={initialValue}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              const { matricNumber } = values;
              setLoading(true);
              setShow(false);
              try {
                const response = await getStudentMatricSearch(matricNumber)
                if (response.status === 200) {
                  setShow(true);
                  setData(response.data);
                }else if (response?.response?.data?.status === 404) {
                  setShow(false);
                  toast.error("No record found");
                }else {
                  toast.info("No record found");
                }
              } catch (error) {
                toast.error("Failed to fetch records");
              } finally {
                setLoading(false);
              }
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldTouched,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <div className="form-container">
                    <div className="form-input">
                      <InputField
                        field="matricNumber"
                        label="Matric Number"
                        value={values?.matricNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>

                    <div className="report-submit-btn">
                      <Button
                        className="submit-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={loading}
                            size={30}
                            aria-label="Searching..."
                          />
                        ) : (
                          "Search"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <div className="search-table">
        {data?.matricNumber  && (<SearchMatricTable item={data} show={show}  />) }
      </div>
    </div>
  );
};

export default SearchMatric;
