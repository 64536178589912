import React, { useState, ChangeEvent } from "react";
import { DotLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import Fileupload from "../../assets/Upload.png";
import { bulkUploadAdmissionList } from "../../redux/action/admission";
import { useDispatch, useSelector } from "react-redux";

const StudentsUpload = ({ show, setShow }: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const { bulkUploadLoading } = useSelector(
    (state: any) => state.admissionReducer
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      await dispatch(bulkUploadAdmissionList(formData, session, setShow));
    }
  };

  return (
    <Modal
      show={show}
      contentClassName="students-upload-modal"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="admission-bulk-upload">
        <Container>
          <h4>UPLOAD EXCEL FILE</h4>
          <img src={Fileupload} alt="file upload" />
          <input
            style={{ cursor: "pointer" }}
            accept=".xls,.xlsx"
            type="file"
            size={1048576}
            onChange={handleFileChange}
          />
        </Container>
        <Modal.Footer>
          <ul>
            <li>Maximum file size allowed is 1MB.</li>
            <li>
              Please ensure to download and put your data in the provided
              template.
            </li>
          </ul>
          <Button
            className="upload-btn"
            onClick={handleUpload}
            disabled={bulkUploadLoading || !selectedFile}
          >
            {bulkUploadLoading ? (
              <DotLoader
                color="white"
                loading={bulkUploadLoading}
                size={30}
                aria-label="Submitting"
              />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default StudentsUpload;
