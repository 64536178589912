import { CLEAR_STATE } from "../../action-constants/admissions";
import * as types from "../../action-constants/edocumentation";

const initialState = {
  allRequests: [],
  dashboardStatsLoading: false,
  eDocumentationRequestsLoading: false,
  documentTypes: [],
  allPendingClearanceRequests: [],
  pendingClearanceRequestsLoading: false,
};

export default function edocumentationReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case types.GET_DASHBOARD_STATS:
      return {
        ...state,
        ...action.payload,
      };
    case types.DASHBOARD_STARTS_LOADING:
      return {
        ...state,
        dashboardStatsLoading: action.payload,
      };

    case types.GET_ALL_REQUESTS:
      return {
        ...state,
        ...action.payload,
      };
    case types.EDOCUMENTATION_REQUEST_LOADING:
      return {
        ...state,
        eDocumentationRequestsLoading: action.payload,
      };
    case types.DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload,
      };
    case types.PENDING_CLEARANCE_REQUESTS_LOADING:
      return {
        ...state,
        pendingClearanceRequestsLoading: action.payload,
      };
    case types.ALL_PENDING_CLEARANCE_REQUESTS:
      return {
        ...state,
        allPendingClearanceRequests: action.payload,
      };
    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
