import { Link } from "react-router-dom";
import "./navigation.scss";
import undo from "../../../assets/Undo.png";

interface NavigationProps {
  to: string;
  text: string;

}
const Navigation: React.FC<NavigationProps> = ({ to, text }) => {
  return (
   
      <Link className="back-link" to={to}>
        <span>
          <img className="icon" src={undo} alt="Icon" />
          <p>{text}</p>
        </span>
    </Link>
 
  );
};

export default Navigation;
