import React from "react";
import "./index.scss";

const ReportsTable = ({ studentsReports, program, programmeType }: any) => {
  const truncateText = (str: string) => {
    if (!str || !str.length) return "-";
    return str?.substring(0, 3);
  };

  function toPascalCase(str: string) {
    if (!str.length) return "";
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split(" ") // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(" "); // Join the words back with spaces
  }

  function countSubjectsWithoutAR(subjects: any[]) {
    // Filter out the subjects with grade 'AR' and count the rest
    return subjects.filter((subject) => subject.grade !== "AR")?.length;
  }

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";
  let user: any = JSON.parse(loggedInUser) || {};
  const session = user?.currentSession?.session;

  const examDate = (month: string, year: string) => {
    if (!year || !month) return "";
    return `${month}-${year}`;
  };

  return (
    <div id="table-to-export" className="table-to-export">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://i.ibb.co/GspYCGX/logo2.png"
            alt="logo2"
            style={{ width: "90px", marginRight: "10px" }}
          />
          <h5>{programmeType}</h5>
        </div>
        <div style={{ textAlign: "center" }}>
          <h4 style={{ fontWeight: "bold" }}>
            IBRAHIM BADAMASI BABANGIDA UNIVERSITY, LAPAI.
          </h4>
          <h4 style={{ fontWeight: "bold" }}>
            {session} O'Level Result Screening Exercise.
          </h4>
        </div>
        <h4>{program?.toUpperCase()}</h4>
      </div>
      <table>
        <thead>
          <tr>
            <th className="normal-border head-background">S/N</th>
            <th className="normal-border head-background">JAMB No/ NAME</th>
            <th className="normal-border head-background">SEX</th>
            <th className="normal-border head-background">EXAM TYPE</th>
            <th className="normal-border head-background">EXAM No</th>
            <th className="normal-border head-background">EXAM DATE</th>
            <th className="normal-border head-background">RESULT PIN</th>
            <th className="normal-border head-background">RESULT S/n</th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S1
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R1
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S2
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R2
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S3
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R3
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S4
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R4
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S5
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R5
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S6
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R6
            </th>

            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S7
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R7
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S8
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R8
            </th>
            <th
              className="normal-border head-background"
              style={{ borderRight: "none" }}
            >
              S9
            </th>
            <th
              className="normal-border head-background"
              style={{ borderLeft: "none" }}
            >
              R9
            </th>
            <th className="normal-border head-background">C's</th>
            <th className="normal-border head-background">REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {studentsReports.map((record: any, index: number) => (
            <React.Fragment key={index}>
              <tr className={(index + 1) % 2 === 0 ? "colored-row" : ""}>
                <td rowSpan={2} className="normal-border">
                  {index + 1}
                </td>
                <td className="normal-border" style={{ width: "60px" }}>
                  {record.registrationNumber}
                </td>
                <td
                  rowSpan={2}
                  className="normal-border"
                  style={{ verticalAlign: "bottom" }}
                >
                  {record.sex}
                </td>
                <td className="normal-border" style={{ width: "50px" }}>
                  {record.examType1}
                </td>
                <td className="normal-border">{record.examNumber1}</td>
                <td className="normal-border" style={{ width: "50px" }}>
                  {examDate(record?.examMonth1, record.examYear1)}
                </td>
                <td className="normal-border">{record.scratchCardPin1}</td>
                <td className="normal-border">
                  {record.scratchCardSerialNumber1}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[0]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[0]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[1]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[1]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[2]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[2]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[3]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[3]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[4]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[4]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[5]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[5]?.grade
                    : "-"}
                </td>

                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[6]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[6]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[7]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[7]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult1
                    ? truncateText(record?.oLevelResult1[8]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult1
                    ? record?.oLevelResult1[8]?.grade
                    : "-"}
                </td>

                <td className="normal-border">
                  {countSubjectsWithoutAR(record?.oLevelResult1) || "-"}
                </td>
                <td
                  rowSpan={2}
                  className="normal-border"
                  style={{ verticalAlign: "bottom" }}
                >
                  {" "}
                  {""}
                </td>
                {/*
              <td className="normal-border">C4</td>
              <td className="normal-border">C4</td>
              <td className="normal-border">C4</td> */}
              </tr>
              <tr className={(index + 1) % 2 === 0 ? "colored-row" : ""}>
                <td className="normal-border" style={{ width: "60px" }}>
                  {toPascalCase(record?.fullName)}
                </td>
                <td className="normal-border">{record.examType2}</td>
                <td className="normal-border">{record.examNumber2}</td>
                <td className="normal-border">
                  {examDate(record?.examMonth2, record?.examYear2)}
                </td>
                <td className="normal-border">{record?.scratchCardPin2}</td>
                <td className="normal-border">
                  {record?.scratchCardSerialNumber2}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[0]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[0]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[1]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[1]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[2]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[2]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[3]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[3]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[4]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[4]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[5]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[5]?.grade
                    : "-"}
                </td>

                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[6]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[6]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[7]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[7]?.grade
                    : "-"}
                </td>
                <td className="normal-border" style={{ borderRight: "none" }}>
                  {record?.oLevelResult2
                    ? truncateText(record?.oLevelResult2[8]?.subject)
                    : null}
                </td>
                <td className="normal-border" style={{ borderLeft: "none" }}>
                  {record?.oLevelResult2
                    ? record?.oLevelResult2[8]?.grade
                    : "-"}
                </td>

                <td className="normal-border">
                  {countSubjectsWithoutAR(record?.oLevelResult2) || "-"}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
