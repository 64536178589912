import React from 'react'
import { ToastContainer } from 'react-toastify';
import { InAppTemplate } from '../../../../shared/templates/portal';
import SearchMatric from './SearchMatric';

const HostelSearchByMatric = () => {
  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ marginTop: "20px" }}
    />
    <InAppTemplate
      pageTitle="HOSTEL SEARCH MATRIC"
      childComponent={< SearchMatric/>}
    />
  </>
  )
}

export default HostelSearchByMatric;
