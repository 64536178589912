import { CLEAR_STATE } from "../../action-constants/admissions";
import * as types from "../../action-constants/course-subject";

const initialState = {
  items: [],
  allDepartments: [],
  currentPage: 1,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  allCoursesLoading: false,
  addCourseLoading: false,
};

export default function courseSubjectReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case types.LOAD_ALL_DEPARTMENTS:
      return {
        ...state,
        allDepartments: action.payload,
      };
    case types.GET_ALL_COURSE_SUBJECT_LOADING:
      return {
        ...state,
        allCoursesLoading: action.payload,
      };
    case types.GET_ALL_COURSE_SUBJECT:
      return {
        ...state,
        ...action.payload,
      };
    case types.ADD_COURSE_LOADING:
      return {
        ...state,
        addCourseLoading: action.payload,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
