import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import "react-tooltip/dist/react-tooltip.css";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InAppTemplate } from "../../shared/templates/portal/";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { getStates, getStateLgas } from "../../shared/states";
import ViewIco from "../../assets/view.png";
import EditIco from "../../assets/pencil.png";
import PrintIco from "../../assets/print.png";
import DummyImage from "../../assets/dummy-user.jpeg";
import "./index.scss";
import SearchBox from "../../shared/components/searchbox";
import StatusIndicator from "../../shared/components/status-indicator";

const adminGetStudentsListRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetStudentsList(payload);
};

const adminSearchStudentsRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminSearchStudents(payload);
};

const adminGetBiodataMetaRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadBiodataMeta(payload);
};

const adminGetAStudentRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAStudent(payload);
};

const adminUpdateStudentsBioDataRequest = async ({
  pageProps,
  payload,
  passport,
}: any) => {
  await pageProps.adminUpdateAStudent(payload, passport);
};

const printBiodataRequest = async ({ pageProps, payload }: any) => {
  await pageProps.printBiodata(payload);
};

const StudentEditBiodataInfo = ({ pageProps, setShowEditBioData }: any) => {
  const [studentDob, onDobChange] = useState<any>();
  const [stateOfOrigin, setStateOfOrigin] = useState<any>();
  const [stateLgas, setStateLgas] = useState<any[]>([]);
  const [defaultLga, setDefaultLga] = useState<any>();
  const [studentImgFile, setStudentImgFile] = useState<any>();
  const [studentPassport, setStudentPassPort] = useState<any>({
    image:
      pageProps.adminGetAStudentRequest?.request_data?.response?.data?.photoId,
  });
  const [imageError, setImageError] = useState<any>();
  const [isImagePending, setIsImagePending] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>("bio");
  const [chosenFaculty, setChosenFaculty] = useState<any>();
  const [chosenDept, setChosenDept] = useState<any>();
  const [chosenProgramme, setChosenProgramme] = useState<any>();
  const [allDeptsInFaculty, setAllDeptsInFaculty] = useState<any[]>([]);
  const [allProgInDept, setAllProgInDept] = useState<any[]>([]);
  const [allAwards, setAllAwards] = useState<any[]>([]);
  const [countriesList, setAllCounntriesList] = useState<any[]>([]);
  let requestInfo =
    pageProps.adminGetAStudentRequest?.request_data?.response?.data;
  let biodataMetaInfo =
    pageProps.adminGetBioDataMetaRequest?.request_data?.response;
  let updateBiorequest = pageProps.adminUpdateAStudentRequest;

  useEffect(() => {
    if (requestInfo?.state) {
      let lga = getStateLgas(requestInfo?.state);
      setStateLgas(lga);
      if (requestInfo?.lga) {
        setDefaultLga(requestInfo?.lga);
      }
    }
    if (biodataMetaInfo?.allAwards) {
      setAllAwards(biodataMetaInfo?.allAwards);
    }
    if (biodataMetaInfo?.countriesList) {
      setAllCounntriesList(biodataMetaInfo?.countriesList);
    }
    // saveFaculty({
    //     value:requestInfo?.facultyUniqueId,
    //     label:requestInfo?.faculty
    // })
    let allDept: any[] = [];
    biodataMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
      if (
        allDept.findIndex(
          (dept: any) => dept?.value === eachData?.departmentUniqueId
        ) === -1 &&
        eachData?.faculty?.facultyUniqueId === requestInfo?.facultyUniqueId
      ) {
        allDept.push({
          value: eachData?.departmentUniqueId,
          label: eachData?.departmentName,
        });
      }
    });
    setAllDeptsInFaculty(allDept);

    let allCourseOfStudy: any = [];
    biodataMetaInfo?.allProgrammes.forEach((eachData: any) => {
      if (
        allCourseOfStudy.findIndex(
          (course: any) => course?.value === eachData?.programmeUniqueId
        ) === -1 &&
        eachData?.departmentUniqueId === requestInfo?.departmentUniqueId
      ) {
        allCourseOfStudy.push({
          value: eachData?.programmeUniqueId,
          label: eachData?.programmeName,
        });
      }
    });
    setAllProgInDept(allCourseOfStudy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTab = (selectedTab: string) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    if (stateOfOrigin) {
      let lga = getStateLgas(stateOfOrigin);
      setStateLgas(lga);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateOfOrigin]);

  useEffect(() => {
    if (chosenFaculty?.value) {
      let allDept: any[] = [];

      setChosenProgramme("");
      setChosenDept("");
      biodataMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
        if (
          allDept.findIndex(
            (dept: any) => dept?.value === eachData?.departmentUniqueId
          ) === -1 &&
          eachData?.faculty?.facultyUniqueId === chosenFaculty?.value
        ) {
          allDept.push({
            value: eachData?.departmentUniqueId,
            label: eachData?.departmentName,
          });
        }
      });
      setAllDeptsInFaculty(allDept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFaculty]);

  useEffect(() => {
    if (chosenDept?.value) {
      setChosenProgramme("");
      let allCourseOfStudy: any = [];
      biodataMetaInfo?.allProgrammes.forEach((eachData: any) => {
        if (
          allCourseOfStudy.findIndex(
            (course: any) => course?.value === eachData?.programmeUniqueId
          ) === -1 &&
          eachData?.departmentUniqueId === chosenDept?.value
        ) {
          allCourseOfStudy.push({
            value: eachData?.programmeUniqueId,
            label: eachData?.programmeName,
          });
        }
      });
      setAllProgInDept(allCourseOfStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDept]);

  useEffect(() => {
    if (chosenDept?.value) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProgramme]);

  const handleLogoUpload = (e: any) => {
    e.preventDefault();

    if (e.target.files.length > 0) {
      setStudentPassPort({});
      let reader = new FileReader();
      const img: any = document.createElement("img");
      let file = e.target.files[0];
      setStudentImgFile(file);
      let imageObj: any = {};

      reader.onload = (e: any) => {
        let fileSize = file.size / 1024;
        img.src = reader.result;

        if (file.type === "image/jpeg" || file.type === "image/jpg") {
          imageObj = { file, image: reader.result };

          setImageError("");
        } else {
          setImageError("ONLY Jpeg format is allowed");
        }

        if (fileSize >= 30) {
          setImageError("Image size must be less than 30kb");
        } else {
          setImageError("");
        }
      };

      reader.readAsDataURL(file);

      img.onload = (e: any) => {
        if (img.width === 150 && img.height === 150) {
          setImageError("");
          setStudentPassPort(imageObj);
          setIsImagePending(false);
        } else {
          setImageError("Image must be Exactly 150px by 150px");
        }
      };
    }
  };

  let allFaculties: any = [];

  if (biodataMetaInfo?.allDeptAndFaculty) {
    biodataMetaInfo?.allDeptAndFaculty.forEach((eachData: any) => {
      if (
        allFaculties.findIndex(
          (faculty: any) =>
            faculty?.value === eachData?.faculty?.facultyUniqueId
        ) === -1
      ) {
        allFaculties.push({
          value: eachData?.faculty?.facultyUniqueId,
          label: eachData?.faculty?.facultyName,
        });
      }
    });
  }

  const saveFaculty = (selectedFaculty: any) => {
    setChosenFaculty(selectedFaculty);
    // setChosenDept({})
  };

  const saveDept = (selectedDept: any) => {
    setChosenDept(selectedDept);
    // setChosenProgramme({})
  };
  const saveProgramme = (chosenProgramme: any) => {
    setChosenProgramme(chosenProgramme);
  };

  const dateReformatter = (date: any) => {
    let dateValue = requestInfo?.dateOfBirth.split("T");
    dateValue = dateValue[0].split("-");
    let yearTxt,
      dayTxt,
      monthTxt,
      formattedDateTxt: any = "";
    if (dateValue.length === 3) {
      if (dateValue[0].length === 4) {
        // check that first item is year
        yearTxt = dateValue[0];

        if (dateValue[1] <= 12) {
          dayTxt = dateValue[2];
          monthTxt = dateValue[1];
        }
        if (dateValue[1] > 12) {
          dayTxt = dateValue[1];
          monthTxt = dateValue[2];
        }
      }
      if (dateValue[2].length === 4) {
        // check that first item is year
        yearTxt = dateValue[2];

        if (dateValue[1] <= 12) {
          dayTxt = dateValue[0];
          monthTxt = dateValue[1];
        }
        if (dateValue[1] > 12) {
          dayTxt = dateValue[1];
          monthTxt = dateValue[0];
        }
      }

      dayTxt = dayTxt < 10 && dayTxt.length < 2 ? `0${dayTxt}` : dayTxt;
      monthTxt =
        monthTxt < 10 && monthTxt.length < 2 ? `0${monthTxt}` : monthTxt;

      formattedDateTxt = new Date(`${monthTxt}/${dayTxt}/${yearTxt}`);
    }

    return formattedDateTxt;
  };

  let allStates = getStates();
  const sortAlphaNum = (a: any, b: any) =>
    a?.localeCompare(b, "en", { numeric: true });
  allStates = allStates.sort(sortAlphaNum);

  let checkValidationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required."),
    maritalStatus: Yup.string().required("Marital Status is required."),
    fullName: Yup.string().required("Full Name is required."),
    firstName: Yup.string().required("First Name is required."),
    surName: Yup.string().required("Surname is required."),
    stateOfOrigin: Yup.string().required("State of origin is required."),
    lga: Yup.string().required("LGA is required."),
    nationality: Yup.string().required("Country is required."),
    dateOfBirth: Yup.string().required("Date of birth is Required"),
    placeOfBirth: Yup.string().required("Place of birth is required."),
    phoneNumber: Yup.string()
      .matches(/^\+?[0-9]{7,20}$/, "Phone number must be 8 digits or more")
      .required("Phone number is required"),
    email: Yup.string().email().required("Valid email is required."),
    presentContactAddress: Yup.string().required(
      "Present Address is required."
    ),
    permanentHomeAddress: Yup.string().required(
      "Permanent Address is required."
    ),
    nextOfKin: Yup.string().required("Next of Kin is required."),
    nextOfKinRelationship: Yup.string().required(
      "Next of Kin relatinship is required."
    ),
    nextOfKinPhoneNumber: Yup.string()
      .matches(/^\+?[0-9]{7,20}$/, "Phone number must be 8 digits or more")
      .required("Phone number is required"), // email: Yup.string().required("Email is required."),

    nextOfKinAddress: Yup.string().required("Next of Kin address is required."),
    sponsorType: Yup.string().required("Sponsor type is required."),
    sponsorName: Yup.string().required("Sponsor name is required."),
    sponsorAddress: Yup.string().required("Sponsor address is required."),
    healthStatus: Yup.string().required("Health status is required."),
    bloodGroup: Yup.string().required("Blood group is required."),
    programType: Yup.string().required("Programme type is required."),
    studyMode: Yup.string().required("Study mode is required."),
    modeOfEntry: Yup.string().required("Mode entry is required."),
    level: Yup.string().required("Student level is required."),
    matricNumber: Yup.string().required("Matric Number is required."),
    programDuration: Yup.string().required("Programme duration is required."),
    isActive: Yup.string().required("Student Status is required."),
    awardInView: Yup.string().required("Award-In-View is required."),
    entryYear: Yup.string().required("Entry Year is required."),
    department: Yup.string().required("Department is required."),
    faculty: Yup.string().required("Faculty is required."),
    programme: Yup.string().required("Course of study is required."),
    currentSession: Yup.string().required(
      "Student current session is required."
    ),
    registrationNumber: Yup.string().required(
      "Student UTME number is required."
    ),
  });

  let dateformatted: any;
  if (requestInfo?.dateOfBirth) {
    // dateValue = requestInfo?.dateOfBirth.split("T");
    // dateValue = dateValue[0].split("-");

    // if (dateValue.length == 3) {
    //   dateformatted = `${
    //     dateValue[1].length > 1 ? dateValue[1] : `0${dateValue[1]}`
    //   }/${dateValue[2]}/${dateValue[0]}`;

    //     dateformatted = new Date(dateformatted);
    // }
    dateformatted = dateReformatter(requestInfo?.dateOfBirth);
  }

  let initialValues: any = {
    gender: requestInfo?.gender || "",
    fullName: requestInfo?.fullName || "",
    surName: requestInfo?.surName || "",
    firstName: requestInfo?.firstName || "",
    otherNames: requestInfo?.otherNames || "",
    phoneNumber: requestInfo?.phoneNumber || "",
    religion: requestInfo.religion
      ? requestInfo.religion.toLowerCase()
      : requestInfo.religion,
    stateOfOrigin: requestInfo?.state ? requestInfo?.state.toLowerCase() : "",
    lga: defaultLga ? defaultLga.toLowerCase() : "",
    dateOfBirth: dateformatted || "",
    // dateOfBirth: dateformatted? requestInfo?.dateOfBirth || "",
    placeOfBirth: requestInfo?.placeOfBirth || "",
    maritalStatus: requestInfo.maritalStatus
      ? requestInfo.maritalStatus.toLowerCase()
      : requestInfo.maritalStatus,
    presentContactAddress: requestInfo?.presentContactAddress || "",
    permanentHomeAddress: requestInfo?.permanentHomeAddress || "",
    nextOfKin: requestInfo?.nextOfKin || "",
    nextOfKinRelationship: requestInfo?.nextOfKinRelationship
      ? requestInfo?.nextOfKinRelationship.toLowerCase()
      : "",
    nextOfKinPhoneNumber: requestInfo?.nextOfKinPhoneNumber || "",
    nextOfKinAddress: requestInfo?.nextOfKinAddress || "",
    sponsorType: requestInfo?.sponsorType || "",
    sponsorName: requestInfo?.sponsorName || "",
    sponsorAddress: requestInfo?.sponsorAddress || "",
    institutionObtained: requestInfo?.institutionObtained || "",
    previousUniversity: requestInfo?.previousUniversity || "",
    subjectOfFirstDegree: requestInfo?.subjectOfFirstDegree || "",
    healthStatus: requestInfo?.healthStatus
      ? requestInfo?.healthStatus.toLowerCase()
      : "",
    disability: requestInfo?.disability || "None",
    bloodGroup: requestInfo?.bloodGroup || "",
    medication: requestInfo?.medication || "",
    extraActivities:
      requestInfo?.extraActivities || requestInfo?.exteraActivities || "",
    programType: requestInfo?.programType || "",
    studyMode: requestInfo?.studyMode || "",
    modeOfEntry:
      String(requestInfo?.modeOfEntry) === "100" ||
      requestInfo?.modeOfEntry === "UTME"
        ? "UTME"
        : requestInfo?.modeOfEntry || "",
    awardInView: requestInfo?.awardInView || "",
    programDuration: requestInfo?.programDuration || "",
    level: requestInfo?.level || "",
    matricNumber: requestInfo?.matricNumber || "",
    email: requestInfo?.email || "",
    nationality: requestInfo?.nationality.toLowerCase() || "",
    isActive: requestInfo?.isActive ? "Active" : "InActive",
    entryYear: requestInfo?.entryYear || "",
    department: requestInfo?.department || "",
    departmentUniqueId: requestInfo?.departmentUniqueId || "",
    faculty: requestInfo?.faculty || "",
    facultyUniqueId: requestInfo?.facultyUniqueId || "",
    programme: requestInfo?.programme || "",
    programmeUniqueId: requestInfo?.programmeUniqueId || "",
    highestQualification: requestInfo?.highestQualification || "",
    currentSession: requestInfo?.currentSession,
    registrationNumber: requestInfo?.registrationNumber || "",
  };

  let timestamp = new Date();

  return (
    <div>
      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_SUCCESS && (
        <>
          {updateBiorequest.request_status !==
            appConstantsPortal.UPDATE_A_STUDENT_SUCCESS && (
            <div className=" edit_details">
              <div className="all_tabs">
                <div
                  className={
                    activeTab === "bio" ? "each_tab active" : "each_tab"
                  }
                  onClick={() => selectTab("bio")}
                >
                  Student Details
                </div>
                <div
                  className={
                    activeTab === "contact" ? "each_tab active" : "each_tab"
                  }
                  onClick={() => selectTab("contact")}
                >
                  Contact Information
                </div>
                <div
                  className={
                    activeTab === "academic" ? "each_tab active" : "each_tab"
                  }
                  onClick={() => selectTab("academic")}
                >
                  Academic Information
                </div>
                <div
                  className={
                    activeTab === "others" ? "each_tab active" : "each_tab"
                  }
                  onClick={() => selectTab("others")}
                >
                  Other Information
                </div>
              </div>
              {allAwards.length >= 1 && countriesList.length >= 1 && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={checkValidationSchema}
                  onSubmit={async (values) => {
                    let photoId = requestInfo?.photoId
                      ? requestInfo?.photoId.substring(
                          requestInfo?.photoId.lastIndexOf("/") + 1
                        )
                      : null;

                    if (photoId !== null || studentImgFile) {
                      setIsImagePending(false);

                      let dateValue = `${values?.dateOfBirth.getDate()}-${
                        values?.dateOfBirth.getMonth() + 1 >= 10
                          ? values?.dateOfBirth.getMonth() + 1
                          : `0${values?.dateOfBirth.getMonth() + 1}`
                      }-${values?.dateOfBirth.getFullYear()}`;
                      let payload: any = {
                        studentUniqueId: requestInfo?.studentUniqueId,
                        fullName: values?.fullName,
                        email: values?.email,
                        phoneNumber: values?.phoneNumber,
                        surName: values?.surName,
                        photoId,
                        firstName: values?.firstName,
                        otherNames: values?.otherNames,
                        stateOfOrigin: values?.stateOfOrigin,
                        currentSemester: requestInfo?.currentSemester,
                        currentSession: values?.currentSession,
                        hasRegisteredForCurrentSession:
                          requestInfo?.hasRegisteredForCurrentSession,
                        hasHostelAccommodation:
                          requestInfo?.hasHostelAccommodation,
                        matricNumber: values?.matricNumber,
                        level: values?.level,
                        registrationNumber: values?.registrationNumber,

                        faculty: values?.faculty,
                        facultyUniqueId: values?.facultyUniqueId,
                        department: values?.department,
                        departmentUniqueId: values?.departmentUniqueId,
                        programme: values?.programme,
                        programmeUniqueId: values?.programmeUniqueId,
                        gender: values?.gender,
                        dateOfBirth: dateValue,
                        placeOfBirth: values.placeOfBirth,
                        maritalStatus: values?.maritalStatus,
                        religion: values.religion,
                        nationality: values?.nationality,
                        state: values?.stateOfOrigin,
                        lga: values?.lga,
                        presentContactAddress: values?.presentContactAddress,
                        permanentHomeAddress: values.permanentHomeAddress,
                        nextOfKin: values.nextOfKin,
                        nextOfKinAddress: values.nextOfKinAddress,
                        nextOfKinPhoneNumber: values.nextOfKinPhoneNumber,
                        nextOfKinRelationship: values.nextOfKinRelationship,
                        sponsorType: values.sponsorType,
                        sponsorName: values.sponsorName,
                        sponsorAddress: values.sponsorAddress,
                        programType: values?.programType,
                        modeOfEntry: values?.modeOfEntry,
                        studyMode: values?.studyMode,
                        entryYear: values?.entryYear,
                        programDuration: values?.programDuration,
                        awardInView: values?.awardInView,
                        exteraActivities: values.extraActivities,
                        highestQualification: values.highestQualification,
                        institutionObtained: values.institutionObtained,
                        previousUniversity: values.previousUniversity,
                        subjectOfFirstDegree: values.subjectOfFirstDegree,
                        isActive: values?.isActive === "Active" ? true : false,
                        healthStatus: values.healthStatus,
                        bloodGroup: values.bloodGroup,
                        disability: values.disability,
                        medication: values.medication,
                      };

                      const passportInfo = new FormData();

                      passportInfo.append("File", studentImgFile);
                      passportInfo.append(
                        "StudentUniqueId",
                        requestInfo?.studentUniqueId
                      );

                      adminUpdateStudentsBioDataRequest({
                        pageProps,
                        payload,
                        passport: studentImgFile ? passportInfo : null,
                      });
                    } else {
                      setIsImagePending(true);
                    }
                  }}
                >
                  {(props: FormikProps<any>) => {
                    const {
                      values,
                      touched,
                      errors,
                      // handleBlur,
                      handleChange,
                      setFieldTouched,
                      setFieldValue,
                      // isSubmitting,
                    } = props;
                    return (
                      <Form>
                        {activeTab === "bio" && (
                          <>
                            <div className="each_edit_section">
                              <div className="edit_heading">
                                PASSPORT PHOTOGRAPH
                              </div>

                              <div className="passport_info_wrap">
                                <div className="passport-info">
                                  <div className="passport_upload">
                                    {studentPassport?.image && (
                                      <div className="photo_wrap">
                                        <img
                                          src={
                                            studentPassport?.image ||
                                            `${
                                              requestInfo.photoId
                                            }?v=${timestamp.getTime()}`
                                          }
                                          alt=""
                                        />
                                      </div>
                                    )}
                                    <div className="upload_ctas">
                                      <div className="file_input">
                                        <input
                                          accept="image/jpg, image/jpeg"
                                          onChange={(e: any) =>
                                            handleLogoUpload(e)
                                          }
                                          type="file"
                                          name=""
                                          id="passportImg"
                                        />
                                        <label
                                          htmlFor="passportImg"
                                          className=""
                                        >
                                          {studentPassport?.image ||
                                          requestInfo?.photoId
                                            ? "Change passport"
                                            : "Choose passport"}
                                        </label>
                                      </div>
                                    </div>
                                    {imageError && (
                                      <div className="img_upload_error">
                                        {" "}
                                        {imageError}
                                      </div>
                                    )}
                                  </div>
                                  <div className="uploads_checklist">
                                    <ul>
                                      <li>Jpeg format ONLY</li>
                                      <li>Less than 30KB</li>
                                      <li>Exactly 150px by 150px</li>
                                      <li>Recent Photograph of good quality</li>
                                      <li>Plain white background</li>
                                    </ul>
                                  </div>
                                </div>

                                <StatusIndicator
                                  isActive={requestInfo.isActive}
                                  isDeferred={requestInfo.isDeferred}
                                />

                              </div>
                            </div>
                            <div className="each_edit_section">
                              <div className="edit_heading">
                                STUDENT BIODATA
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">Surname * </label>
                                  <div className="">
                                    <Field
                                      value={values?.surName}
                                      id="surName"
                                      name="surName"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.surName && touched.surName
                                          ? "is-invalid-input "
                                          : !errors.surName &&
                                            values.surName !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="surName"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">First Name * </label>
                                  <div className="">
                                    <Field
                                      value={values?.firstName}
                                      id="firstName"
                                      name="firstName"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.firstName && touched.firstName
                                          ? "is-invalid-input "
                                          : !errors.firstName &&
                                            values.firstName !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="firstName"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">Middle Name </label>
                                  <div className="">
                                    <Field
                                      value={values?.otherNames}
                                      id="otherNames"
                                      name="otherNames"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.otherNames && touched.otherNames
                                          ? "is-invalid-input "
                                          : !errors.otherNames &&
                                            values.otherNames !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="otherNames"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">Full Name </label>
                                  <div className="">
                                    <Field
                                      value={values?.fullName}
                                      id="fullName"
                                      name="fullName"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.fullName && touched.fullName
                                          ? "is-invalid-input "
                                          : !errors.fullName &&
                                            values.fullName !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="fullName"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">Gender * </label>
                                  <div className="">
                                    <select
                                      name="gender"
                                      id="gender"
                                      value={values?.gender}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.gender && touched.gender
                                          ? "is-invalid-input "
                                          : !errors.gender &&
                                            values.gender !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="Male"> Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="gender"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Marital Status *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.maritalStatus &&
                                      touched.maritalStatus
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="maritalStatus"
                                      id="maritalStatus"
                                      value={values?.maritalStatus}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.maritalStatus &&
                                        touched.maritalStatus
                                          ? "is-invalid-input "
                                          : !errors.maritalStatus &&
                                            values.maritalStatus !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="single">Single</option>
                                      <option value="married">Married</option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="maritalStatus"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">
                                    State of Origin *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.stateOfOrigin &&
                                      touched.stateOfOrigin
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="stateOfOrigin"
                                      id="stateOfOrigin"
                                      value={values?.stateOfOrigin}
                                      onChange={(e: any) => {
                                        if (e.target.value) {
                                          setFieldTouched(
                                            "stateOfOrigin",
                                            true,
                                            true
                                          );
                                          setFieldValue(
                                            "stateOfOrigin",
                                            e.target.value
                                          );
                                          setStateOfOrigin(e.target.value);
                                        }
                                      }}
                                      className={`input-wrap ${
                                        errors.stateOfOrigin &&
                                        touched.stateOfOrigin
                                          ? "is-invalid-input "
                                          : !errors.stateOfOrigin &&
                                            values.stateOfOrigin !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      {allStates.map(
                                        (eachState: any, index: any) => {
                                          return (
                                            <option
                                              value={eachState.toLowerCase()}
                                              key={index}
                                            >
                                              {eachState}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="stateOfOrigin"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Local Govt. Area *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.lga && touched.lga
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="lga"
                                      id="lga"
                                      disabled={!values?.stateOfOrigin}
                                      value={values?.lga}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.lga && touched.lga
                                          ? "is-invalid-input "
                                          : !errors.lga && values.lga !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      {stateLgas &&
                                        stateLgas.map(
                                          (eachlga: any, index: any) => {
                                            return (
                                              <option
                                                value={eachlga.toLowerCase()}
                                                key={index}
                                              >
                                                {eachlga}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="lga"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Date of Birth *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.dateOfBirth && touched.dateOfBirth
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <DatePicker
                                      // onChangeRaw={this.handleDateChangeRaw}
                                      selected={
                                        values?.dateOfBirth || studentDob
                                      }
                                      onChange={(date: any) => {
                                        onDobChange(date);
                                        setFieldTouched("dateOfBirth", true);
                                        setFieldValue("dateOfBirth", date);
                                        // setFieldValue("dateOfBirth", date.toISOString())
                                      }}
                                      // selected={values.dateOfBirth}
                                      // minDate={new Date()}
                                      name=""
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      dateFormat="d MMMM, yyyy"
                                      maxDate={new Date()}
                                      placeholderText="Select Date of Birth"
                                      className={
                                        errors.dateOfBirth &&
                                        touched.dateOfBirth
                                          ? "is-invalid form-control"
                                          : "form-control "
                                      }
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="dateOfBirth"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">
                                    Country of Origin *
                                  </label>

                                  <div
                                    className={
                                      errors.nationality && touched.nationality
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="nationality"
                                      id="nationality"
                                      value={values?.nationality}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.nationality &&
                                        touched.nationality
                                          ? "is-invalid-input "
                                          : !errors.nationality &&
                                            values.nationality !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      {countriesList.map(
                                        (country: any, index: any) => {
                                          return (
                                            <option
                                              value={country?.name.toLowerCase()}
                                              key={index}
                                            >
                                              {country?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="nationality"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Place of Birth *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.placeOfBirth &&
                                      touched.placeOfBirth
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values.placeOfBirth}
                                      id="placeOfBirth"
                                      name="placeOfBirth"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.placeOfBirth &&
                                        touched.placeOfBirth
                                          ? "is-invalid-input "
                                          : !errors.placeOfBirth &&
                                            values.placeOfBirth !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="placeOfBirth"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">Religion * </label>
                                  <div
                                    className={
                                      errors.religion && touched.religion
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="religion"
                                      id="religion"
                                      value={values?.religion}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.religion && touched.religion
                                          ? "is-invalid-input "
                                          : !errors.religion &&
                                            values.religion !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="christian">
                                        Christian
                                      </option>
                                      <option value="islam">Islam</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {activeTab === "contact" && (
                          <div className="each_edit_section">
                            <div className="edit_heading">
                              CONTACT INFORMATION
                            </div>
                            <div className="edit_data two">
                              <div className="form-group">
                                <label className="label">
                                  Student Phone No{" "}
                                </label>
                                <div
                                  className={
                                    errors.phoneNumber && touched.phoneNumber
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.phoneNumber}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.phoneNumber && touched.phoneNumber
                                        ? "is-invalid-input "
                                        : !errors.phoneNumber &&
                                          values.phoneNumber !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="phoneNumber"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="label">
                                  Student Email *{" "}
                                </label>
                                <div
                                  className={
                                    errors.email && touched.email
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values.email}
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.email && touched.email
                                        ? "is-invalid-input "
                                        : !errors.email && values.email !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="email"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="edit_data ">
                              <div className="form-group">
                                <label className="label">
                                  Present Contact Address *{" "}
                                </label>
                                <div
                                  className={
                                    errors.presentContactAddress &&
                                    touched.presentContactAddress
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.presentContactAddress}
                                    id="presentContactAddress"
                                    name="presentContactAddress"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.presentContactAddress &&
                                      touched.presentContactAddress
                                        ? "is-invalid-input "
                                        : !errors.presentContactAddress &&
                                          values.presentContactAddress !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="presentContactAddress"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="edit_data ">
                              <div className="form-group">
                                <label className="label">
                                  Permanent Address{" "}
                                </label>
                                <div
                                  className={
                                    errors.permanentHomeAddress &&
                                    touched.permanentHomeAddress
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.permanentHomeAddress}
                                    id="permanentHomeAddress"
                                    name="permanentHomeAddress"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.permanentHomeAddress &&
                                      touched.permanentHomeAddress
                                        ? "is-invalid-input "
                                        : !errors.permanentHomeAddress &&
                                          values.permanentHomeAddress !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="permanentHomeAddress"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="edit_data three">
                              <div className="form-group">
                                <label className="label">
                                  Next Of Kin Name
                                </label>
                                <div
                                  className={
                                    errors.nextOfKin && touched.nextOfKin
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values.nextOfKin}
                                    id="nextOfKin"
                                    name="nextOfKin"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.nextOfKin && touched.nextOfKin
                                        ? "is-invalid-input "
                                        : !errors.nextOfKin &&
                                          values.nextOfKin !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="nextOfKin"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="label">Relationship</label>
                                <div
                                  className={
                                    errors.nextOfKinRelationship &&
                                    touched.nextOfKinRelationship
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="nextOfKinRelationship"
                                    id="nextOfKinRelationship"
                                    value={values?.nextOfKinRelationship}
                                    onChange={handleChange}
                                    className={`input-wrap ${
                                      errors.nextOfKinRelationship &&
                                      touched.nextOfKinRelationship
                                        ? "is-invalid-input "
                                        : !errors.nextOfKinRelationship &&
                                          values.nextOfKinRelationship !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    <option value="Parent">Parent</option>
                                    <option value="Sibling">Sibling</option>
                                    <option value="Guardian">
                                      Guardian/Relative
                                    </option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="nextOfKinRelationship"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                              <div className="form-group">
                                <label className="label">
                                  Next Of Kin Phone No{" "}
                                </label>
                                <div
                                  className={
                                    errors.nextOfKinPhoneNumber &&
                                    touched.nextOfKinPhoneNumber
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.nextOfKinPhoneNumber}
                                    id="nextOfKinPhoneNumber"
                                    name="nextOfKinPhoneNumber"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.nextOfKinPhoneNumber &&
                                      touched.nextOfKinPhoneNumber
                                        ? "is-invalid-input "
                                        : !errors.nextOfKinPhoneNumber &&
                                          values.nextOfKinPhoneNumber !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="nextOfKinPhoneNumber"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="edit_data ">
                              <div className="form-group">
                                <label className="label">
                                  Next Of Kin Address{" "}
                                </label>
                                <div
                                  className={
                                    errors.nextOfKinAddress &&
                                    touched.nextOfKinAddress
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.nextOfKinAddress}
                                    id="nextOfKinAddress"
                                    name="nextOfKinAddress"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.nextOfKinAddress &&
                                      touched.nextOfKinAddress
                                        ? "is-invalid-input "
                                        : !errors.nextOfKinAddress &&
                                          values.nextOfKinAddress !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="nextOfKinAddress"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="edit_data two">
                              <div className="form-group">
                                <label className="label">
                                  Sponsorship Type *{" "}
                                </label>
                                <div
                                  className={
                                    errors.sponsorType && touched.sponsorType
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="sponsorType"
                                    id="sponsorType"
                                    value={values?.sponsorType}
                                    onChange={handleChange}
                                    className={`input-wrap ${
                                      errors.sponsorType && touched.sponsorType
                                        ? "is-invalid-input "
                                        : !errors.sponsorType &&
                                          values.sponsorType !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    <option value="Self">Self</option>
                                    <option value="Sponsored">Sponsored</option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="sponsorType"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                              <div className="form-group">
                                <label className="label">Sponsor Name </label>
                                <div
                                  className={
                                    errors.sponsorName && touched.sponsorName
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.sponsorName}
                                    id="sponsorName"
                                    name="sponsorName"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.sponsorName && touched.sponsorName
                                        ? "is-invalid-input "
                                        : !errors.sponsorName &&
                                          values.sponsorName !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="sponsorName"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="edit_data ">
                              <div className="form-group">
                                <label className="label">
                                  Sponsor Address{" "}
                                </label>
                                <div
                                  className={
                                    errors.sponsorAddress &&
                                    touched.sponsorAddress
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.sponsorAddress}
                                    id="sponsorAddress"
                                    name="sponsorAddress"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.sponsorAddress &&
                                      touched.sponsorAddress
                                        ? "is-invalid-input "
                                        : !errors.sponsorAddress &&
                                          values.sponsorAddress !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="sponsorAddress"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeTab === "academic" && (
                          <>
                            <div className="each_edit_section">
                              <div className="edit_heading">
                                ACADEMIC INFORMATION
                              </div>
                              <div className="edit_data one">
                                <div className="form-group">
                                  <label className="label">
                                    Student UTME Number *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.registrationNumber &&
                                      touched.registrationNumber
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.registrationNumber}
                                      id="registrationNumber"
                                      name="registrationNumber"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap valid-input `}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="registrationNumber"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">
                                    Student Level *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.level && touched.level
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="level"
                                      id="level"
                                      value={values?.level}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.level && touched.level
                                          ? "is-invalid-input "
                                          : !errors.level && values.level !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="100">100 </option>
                                      <option value="200">200 </option>
                                      <option value="300">300 </option>
                                      <option value="400">400 </option>
                                      <option value="500">500 </option>
                                      <option value="600">600 </option>
                                      <option value="700">700 </option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="level"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Matric Number *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.matricNumber &&
                                      touched.matricNumber
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.matricNumber}
                                      id="matricNumber"
                                      name="matricNumber"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.matricNumber &&
                                        touched.matricNumber
                                          ? "is-invalid-input "
                                          : !errors.matricNumber &&
                                            values.matricNumber !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="matricNumber"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Student Status *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.isActive && touched.isActive
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="isActive"
                                      id="isActive"
                                      value={values?.isActive}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.isActive && touched.isActive
                                          ? "is-invalid-input "
                                          : !errors.isActive &&
                                            values.isActive !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      {values?.isActive === "InActive" && (
                                        <option value="Active">Active </option>
                                      )}
                                      {values?.isActive === "Active" && (
                                        <option value="InActive">
                                          InActive{" "}
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="isActive"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">
                                    Program Type *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.programType && touched.programType
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="programType"
                                      id="programType"
                                      value={values?.programType}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.programType &&
                                        touched.programType
                                          ? "is-invalid-input "
                                          : !errors.programType &&
                                            values.programType !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="FIRST DEGREE">
                                        First Degree{" "}
                                      </option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="programType"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">Study Mode * </label>
                                  <div
                                    className={
                                      errors.studyMode && touched.studyMode
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="studyMode"
                                      id="studyMode"
                                      value={values?.studyMode}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.studyMode && touched.studyMode
                                          ? "is-invalid-input "
                                          : !errors.studyMode &&
                                            values.studyMode !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="FULL TIME">
                                        FULL TIME{" "}
                                      </option>
                                      <option value="PART TIME">
                                        PART TIME{" "}
                                      </option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="studyMode"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">Entry Mode * </label>
                                  <div
                                    className={
                                      errors.modeOfEntry && touched.modeOfEntry
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="modeOfEntry"
                                      id="modeOfEntry"
                                      value={values?.modeOfEntry}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.modeOfEntry &&
                                        touched.modeOfEntry
                                          ? "is-invalid-input "
                                          : !errors.modeOfEntry &&
                                            values.modeOfEntry !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="UTME">UTME </option>
                                      <option value="DE">DE </option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="modeOfEntry"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">Faculty *</label>
                                  <div className="">
                                    {/* <Field
                                                                    value={values?.faculty}
                                                                    id="faculty"
                                                                    name="faculty"
                                                                    disabled
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    placeholder=""
                                                                    className={`input-wrap valid-input `}
                                                                /> */}
                                    <div className="search_faculty">
                                      <Select
                                        // menuIsOpen={menuToOPen === "faculty" ? true : false}
                                        defaultValue={{
                                          value: values?.facultyUniqueId,
                                          label: values?.faculty,
                                        }}
                                        value={chosenFaculty}
                                        id="chosenFaculty"
                                        openMenuOnClick={true}
                                        onChange={(option: any) => {
                                          setFieldValue(
                                            "faculty",
                                            option.label
                                          );
                                          setFieldValue(
                                            "facultyUniqueId",
                                            option.value
                                          );
                                          setFieldTouched("faculty", true);

                                          setFieldValue("department", "");
                                          setFieldValue(
                                            "departmentUniqueId",
                                            ""
                                          );
                                          saveFaculty(option);
                                        }}
                                        options={allFaculties}
                                        placeholder="Select faculty"
                                        className="select-wrap"
                                        classNamePrefix="faculty-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">Department *</label>
                                  {/* <div className="">
                                                                <Field
                                                                    value={values?.department}
                                                                    id="department"
                                                                    name="department"
                                                                    disabled
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    placeholder=""
                                                                    className={`input-wrap valid-input `}
                                                                />
                                                            </div> */}
                                  <div className="search_dept">
                                    <Select
                                      // menuIsOpen={menuToOPen === "dept" ? true : false}
                                      defaultValue={{
                                        label: values.department,
                                        value: values.departmentUniqueId,
                                      }}
                                      value={chosenDept}
                                      // onChange={saveDept}
                                      onChange={(option: any) => {
                                        setFieldValue(
                                          "department",
                                          option.label
                                        );
                                        setFieldValue(
                                          "departmentUniqueId",
                                          option.value
                                        );
                                        setFieldTouched("department", true);

                                        setFieldTouched("programme", true);
                                        setFieldValue("programme", "");
                                        setFieldValue("programmeUniqueId", "");
                                        saveDept(option);
                                      }}
                                      options={allDeptsInFaculty}
                                      isDisabled={
                                        !chosenFaculty?.value &&
                                        !values.facultyUniqueId
                                      }
                                      placeholder="Select department"
                                      className="select-wrap"
                                      classNamePrefix="dept-select"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">Programme *</label>
                                  {/* <div className="">
                                                                <Field
                                                                    value={values?.programme}
                                                                    id="programme"
                                                                    name="programme"
                                                                    disabled
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    placeholder=""
                                                                    className={`input-wrap valid-input `}
                                                                />
                                                            </div> */}
                                  <div className="search_course_of_study">
                                    <Select
                                      defaultValue={{
                                        value: values?.programme,
                                        label: values?.programme,
                                      }}
                                      value={chosenProgramme}
                                      // onChange={saveProgramme}
                                      onChange={(option: any) => {
                                        setFieldValue(
                                          "programme",
                                          option.label
                                        );
                                        setFieldValue(
                                          "programmeUniqueId",
                                          option.value
                                        );
                                        setFieldTouched("programme", true);
                                        saveProgramme(option);
                                      }}
                                      options={allProgInDept}
                                      isDisabled={
                                        !chosenDept?.value &&
                                        !values.departmentUniqueId
                                      }
                                      placeholder="Select course of study"
                                      className="select-wrap"
                                      classNamePrefix="course-select"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="edit_data three">
                                <div className="form-group">
                                  <label className="label">
                                    Award in View *{" "}
                                  </label>

                                  <div
                                    className={
                                      errors.awardInView && touched.awardInView
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="awardInView"
                                      id="awardInView"
                                      value={values?.awardInView}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.awardInView &&
                                        touched.awardInView
                                          ? "is-invalid-input "
                                          : !errors.awardInView &&
                                            values.awardInView !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      {allAwards.map(
                                        (eachAward: any, index: any) => {
                                          return (
                                            <option
                                              key={index}
                                              value={eachAward?.uniqueId}
                                            >
                                              {eachAward?.name}{" "}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="awardInView"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Course Duration *{" "}
                                  </label>

                                  <div
                                    className={
                                      errors.programDuration &&
                                      touched.programDuration
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="programDuration"
                                      id="programDuration"
                                      value={values?.programDuration}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.programDuration &&
                                        touched.programDuration
                                          ? "is-invalid-input "
                                          : !errors.programDuration &&
                                            values.programDuration !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="1">One year </option>
                                      <option value="2">Two years </option>
                                      <option value="3">Three years </option>
                                      <option value="4">Four years </option>
                                      <option value="5">Five years </option>
                                      <option value="6">Six years </option>
                                      <option value="7">Seven years </option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="programDuration"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Entry Year (e.g 2013) *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.entryYear && touched.entryYear
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.entryYear}
                                      id="entryYear"
                                      name="entryYear"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap valid-input `}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="entryYear"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data one">
                                <div className="form-group">
                                  <label className="label">
                                    Student Current Session *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.currentSession &&
                                      touched.currentSession
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.currentSession}
                                      id="currentSession"
                                      name="currentSession"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap valid-input `}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="currentSession"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="edit_data ">
                                <div className="form-group">
                                  <label className="label">
                                    Extra Curricular Activities
                                  </label>
                                  <div
                                    className={
                                      errors.extraActivities &&
                                      touched.extraActivities
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.extraActivities}
                                      id="extraActivities"
                                      name="extraActivities"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.extraActivities &&
                                        touched.extraActivities
                                          ? "is-invalid-input "
                                          : !errors.extraActivities &&
                                            values.extraActivities !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="extraActivities"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="each_edit_section">
                              <div className="edit_heading">
                                PREVIOUS ACADEMIC HISTORY
                              </div>
                              <div className="edit_data two">
                                <div className="form-group">
                                  <label className="label">
                                    Highest Qualification *{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.highestQualification &&
                                      touched.highestQualification
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <select
                                      name="highestQualification"
                                      id="highestQualification"
                                      value={values.highestQualification}
                                      onChange={handleChange}
                                      className={`input-wrap ${
                                        errors.highestQualification &&
                                        touched.highestQualification
                                          ? "is-invalid-input "
                                          : !errors.highestQualification &&
                                            values.highestQualification !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    >
                                      <option value="">Select </option>
                                      <option value="Bachelors">
                                        Bachelors
                                      </option>
                                      <option value="Master">Master</option>
                                      <option value="Phd">Phd</option>
                                    </select>
                                  </div>
                                  <ErrorMessage
                                    name="highestQualification"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    Institution Obtained & Date{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.institutionObtained &&
                                      touched.institutionObtained
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.institutionObtained}
                                      id="institutionObtained"
                                      name="institutionObtained"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.institutionObtained &&
                                        touched.institutionObtained
                                          ? "is-invalid-input "
                                          : !errors.institutionObtained &&
                                            values.institutionObtained !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="institutionObtained"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="edit_data two">
                                <div className="form-group">
                                  <label className="label">
                                    Previous University
                                  </label>
                                  <div
                                    className={
                                      errors.previousUniversity &&
                                      touched.previousUniversity
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.previousUniversity}
                                      id="previousUniversity"
                                      name="previousUniversity"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.previousUniversity &&
                                        touched.previousUniversity
                                          ? "is-invalid-input "
                                          : !errors.previousUniversity &&
                                            values.previousUniversity !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="previousUniversity"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="label">
                                    First Degree Course
                                  </label>
                                  <div
                                    className={
                                      errors.subjectOfFirstDegree &&
                                      touched.subjectOfFirstDegree
                                        ? "invalid-input-wrap"
                                        : ""
                                    }
                                  >
                                    <Field
                                      value={values?.subjectOfFirstDegree}
                                      id="subjectOfFirstDegree"
                                      name="subjectOfFirstDegree"
                                      type="text"
                                      onChange={handleChange}
                                      placeholder=""
                                      className={`input-wrap ${
                                        errors.subjectOfFirstDegree &&
                                        touched.subjectOfFirstDegree
                                          ? "is-invalid-input "
                                          : !errors.subjectOfFirstDegree &&
                                            values.subjectOfFirstDegree !== ""
                                          ? "valid-input"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="subjectOfFirstDegree"
                                      className="form-input-error"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {activeTab === "others" && (
                          <div className="each_edit_section">
                            <div className="edit_heading">
                              HEALTH INFORMATION
                            </div>
                            <div className="edit_data three">
                              <div className="form-group">
                                <label className="label">Status *</label>
                                <div
                                  className={
                                    errors.healthStatus && touched.healthStatus
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="healthStatus"
                                    id="healthStatus"
                                    value={values?.healthStatus}
                                    onChange={handleChange}
                                    className={`input-wrap ${
                                      errors.healthStatus &&
                                      touched.healthStatus
                                        ? "is-invalid-input "
                                        : !errors.healthStatus &&
                                          values.healthStatus !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    <option value="healthy">Healthy</option>
                                    <option value="under observation">
                                      Under observation
                                    </option>
                                    <option value="recovering">
                                      Recovering
                                    </option>
                                    <option value="chronic condition">
                                      Chronic condition
                                    </option>
                                    <option value="acute illness">
                                      Acute illness
                                    </option>
                                    <option value="in remission">
                                      In remission
                                    </option>
                                    <option value="hospitalized">
                                      Hospitalized
                                    </option>
                                    <option value="critical condition">
                                      Critical condition
                                    </option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="healthStatus"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                              <div className="form-group">
                                <label className="label">Disability * </label>
                                <div
                                  className={
                                    errors.disability && touched.disability
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.disability}
                                    id="disability"
                                    name="disability"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.disability && touched.disability
                                        ? "is-invalid-input "
                                        : !errors.disability &&
                                          values.disability !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="disability"
                                    className="form-input-error"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="label">Blood Group * </label>
                                <div
                                  className={
                                    errors.bloodGroup && touched.bloodGroup
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="bloodGroup"
                                    id="bloodGroup"
                                    value={values?.bloodGroup}
                                    onChange={handleChange}
                                    className={`input-wrap ${
                                      errors.bloodGroup && touched.bloodGroup
                                        ? "is-invalid-input "
                                        : !errors.bloodGroup &&
                                          values.bloodGroup !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    <option value="A+"> A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="bloodGroup"
                                  className="form-input-error"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="edit_data">
                              <div className="form-group">
                                <label className="label">Medication</label>
                                <div
                                  className={
                                    errors.medication && touched.medication
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <Field
                                    value={values?.medication}
                                    id="medication"
                                    name="medication"
                                    as="textarea"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder=""
                                    className={`input-wrap ${
                                      errors.medication && touched.medication
                                        ? "is-invalid-input "
                                        : !errors.medication &&
                                          values.medication !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {isImagePending && (
                          <div className="justify-center">
                            <AlertMsg
                              type="error"
                              message="Please upload your passport"
                            />
                          </div>
                        )}
                        {updateBiorequest?.request_status ===
                          appConstantsPortal?.UPDATE_A_STUDENT_FAILURE && (
                          <div className="justify-center">
                            <AlertMsg
                              type="error"
                              message={updateBiorequest?.request_data.error}
                            />
                          </div>
                        )}

                        {Object.keys(errors).length >= 1 && (
                          <div className="justify-center">
                            <AlertMsg
                              type="error"
                              message={Object.values(errors).map(
                                (msg: any, index: any) => (
                                  <span key={index}>
                                    {msg}
                                    <br />
                                  </span>
                                )
                              )}
                            />
                          </div>
                        )}
                        <div className="form_cta">
                          <button
                            className="btn back_cta"
                            onClick={() => setShowEditBioData(false)}
                            disabled={updateBiorequest?.is_request_processing}
                          >
                            Close
                          </button>
                          <button
                            className="btn shaded"
                            type="submit"
                            disabled={updateBiorequest?.is_request_processing}
                            onClick={() => {}}
                          >
                            {updateBiorequest?.is_request_processing
                              ? "Updating Bio Data..."
                              : "Update Bio Data"}{" "}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </div>
          )}
          {updateBiorequest.request_status ===
            appConstantsPortal.UPDATE_A_STUDENT_SUCCESS && (
            <div className="update_success">
              <div className="success-message-wrap">
                <div className="success-animation">
                  <svg
                    className="success_icon_tick"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
                <div className="success-message-txt">
                  {" "}
                  You have succesfully updated student biodata{" "}
                </div>
                {/* <div className="product-cta">
                                    <button className="btn back_cta" to="/undergraduate/personal-data">See updated Bio Data</Link>

                                </div> */}
              </div>
            </div>
          )}
        </>
      )}

      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_PENDING && <LoadingItem />}

      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_FAILURE && (
        <AlertMsg
          type="error"
          message={pageProps.adminGetBioDataMetaRequest?.request_data.error}
        />
      )}
    </div>
  );
};

const StudentViewBiodataInfo = ({ pageProps, setViewBioData }: any) => {
  const [activeTab, setActiveTab] = useState<any>("bio");

  let requestInfo =
    pageProps.adminGetAStudentRequest?.request_data?.response?.data;

  const selectTab = (selectedTab: string) => {
    setActiveTab(selectedTab);
  };
  let awardsList: any[] =
    pageProps?.adminGetBioDataMetaRequest?.request_data?.response?.allAwards;

  let studentAward = awardsList.filter(
    (each: any) => each?.uniqueId === requestInfo?.awardInView
  )[0];

  let studentData: any = {
    gender: requestInfo?.gender || "",
    fullName: requestInfo?.fullName || "",
    surName: requestInfo?.surName || "",
    firstName: requestInfo?.firstName || "",
    otherNames: requestInfo?.otherNames || "",
    phoneNumber: requestInfo?.phoneNumber || "",
    religion: requestInfo?.religion || "",
    stateOfOrigin: requestInfo?.state || "",
    lga: requestInfo?.lga || "",
    dateOfBirth: requestInfo?.dateOfBirth || "",
    placeOfBirth: requestInfo?.placeOfBirth || "",
    maritalStatus: requestInfo?.maritalStatus || "",
    presentContactAddress: requestInfo?.presentContactAddress || "",
    permanentHomeAddress: requestInfo?.permanentHomeAddress || "",
    nextOfKin: requestInfo?.nextOfKin || "",
    nextOfKinRelationship: requestInfo?.nextOfKinRelationship || "",
    nextOfKinPhoneNumber: requestInfo?.nextOfKinPhoneNumber || "",
    nextOfKinAddress: requestInfo?.nextOfKinAddress || "",
    sponsorType: requestInfo?.sponsorType || "",
    sponsorName: requestInfo?.sponsorName || "",
    sponsorAddress: requestInfo?.sponsorAddress || "",
    institutionObtained: requestInfo?.institutionObtained || "",
    previousUniversity: requestInfo?.previousUniversity || "",
    subjectOfFirstDegree: requestInfo?.subjectOfFirstDegree || "",
    healthStatus: requestInfo?.healthStatus || "",
    disability: requestInfo?.disability || "None",
    bloodGroup: requestInfo?.bloodGroup || "",
    medication: requestInfo?.medication || "",
    extraActivities:
      requestInfo?.extraActivities || requestInfo?.exteraActivities || "",
    programType: requestInfo?.programType || "",
    studyMode: requestInfo?.studyMode || "",
    modeOfEntry:
      String(requestInfo?.modeOfEntry) === "100" ||
      requestInfo?.modeOfEntry === "UTME"
        ? "UTME"
        : requestInfo?.modeOfEntry || "",
    awardInView: studentAward?.name || "",
    programDuration: requestInfo?.programDuration || "",
    level: requestInfo?.level || "",
    matricNumber: requestInfo?.matricNumber || "",
    email: requestInfo?.email || "",
    nationality: requestInfo?.nationality.toLowerCase() || "",
    isActive: requestInfo?.isActive ? "Active" : "InActive",
    entryYear: requestInfo?.entryYear || "",
    department: requestInfo?.department || "",
    departmentUniqueId: requestInfo?.departmentUniqueId || "",
    faculty: requestInfo?.faculty || "",
    facultyUniqueId: requestInfo?.facultyUniqueId || "",
    programme: requestInfo?.programme || "",
    programmeUniqueId: requestInfo?.programmeUniqueId || "",
    highestQualification: requestInfo?.highestQualification || "",
    currentSession: requestInfo?.currentSession,
    registrationNumber: requestInfo?.registrationNumber,
  };

  let timestamp = new Date();

  return (
    <div>
      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_SUCCESS && (
        <>
          <div className=" edit_details">
            <div className="all_tabs">
              <div
                className={activeTab === "bio" ? "each_tab active" : "each_tab"}
                onClick={() => selectTab("bio")}
              >
                Student Details
              </div>
              <div
                className={
                  activeTab === "contact" ? "each_tab active" : "each_tab"
                }
                onClick={() => selectTab("contact")}
              >
                Contact Information
              </div>
              <div
                className={
                  activeTab === "academic" ? "each_tab active" : "each_tab"
                }
                onClick={() => selectTab("academic")}
              >
                Academic Information
              </div>
              <div
                className={
                  activeTab === "others" ? "each_tab active" : "each_tab"
                }
                onClick={() => selectTab("others")}
              >
                Other Information
              </div>
            </div>

            <div className="student_data_wrap">
              {activeTab === "bio" && (
                <>
                  <div className="each_edit_section">
                    <div className="edit_heading">PASSPORT PHOTOGRAPH</div>

                    <div className="passport_info_wrap">
                      <div className="passport_upload">
                        <div className="photo_wrap">
                          <img
                            src={
                              requestInfo?.photoId?.length
                                ? `${
                                    requestInfo.photoId
                                  }?v=${timestamp.getTime()}`
                                : DummyImage
                            }
                            alt=""
                          />
                        </div>
                      </div>

                      <StatusIndicator
                        isActive={requestInfo.isActive}
                        isDeferred={requestInfo.isDeferred}
                      />

                    </div>
                  </div>
                  <div className="each_edit_section">
                    <div className="edit_heading">STUDENT BIODATA</div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Surname </label>
                        <div className="data-value">
                          {studentData?.surName}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">First Name </label>
                        <div className="data-value">
                          {studentData?.firstName}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Middle Name </label>
                        <div className="data-value">
                          {studentData?.otherNames}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Full Name </label>
                        <div className="data-value">
                          {studentData?.fullName}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Gender </label>
                        <div className="data-value">{studentData?.gender} </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Marital Status </label>
                        <div className="data-value">
                          {studentData?.maritalStatus}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">State of Origin </label>
                        <div className="data-value">
                          {studentData?.stateOfOrigin}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Local Govt. Area </label>
                        <div className="data-value">{studentData?.lga} </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Date of Birth </label>
                        <div className="data-value">
                          {studentData?.dateOfBirth}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Country of Origin </label>
                        <div className="data-value">
                          {studentData?.nationality}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Place of Birth </label>
                        <div className="data-value">
                          {studentData?.placeOfBirth}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Religion </label>
                        <div className="data-value">
                          {studentData?.religion}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {activeTab === "contact" && (
                <div className="each_edit_section">
                  <div className="edit_heading">CONTACT INFORMATION</div>
                  <div className="edit_data two">
                    <div className="form-group">
                      <label className="label">Student Phone No </label>
                      <div className="data-value">
                        {studentData?.phoneNumber}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Student Email </label>
                      <div className="data-value">{studentData?.email} </div>
                    </div>
                  </div>
                  <div className="edit_data ">
                    <div className="form-group">
                      <label className="label">Present Contact Address </label>
                      <div className="data-value">
                        {studentData?.presentContactAddress}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data ">
                    <div className="form-group">
                      <label className="label">Permanent Address </label>
                      <div className="data-value">
                        {studentData?.permanentHomeAddress}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data three">
                    <div className="form-group">
                      <label className="label">Next Of Kin Name</label>
                      <div className="data-value">
                        {studentData?.nextOfKin}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Relationship</label>
                      <div className="data-value">
                        {studentData?.nextOfKinRelationship}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Next Of Kin Phone No </label>
                      <div className="data-value">
                        {studentData?.nextOfKinPhoneNumber}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data ">
                    <div className="form-group">
                      <label className="label">Next Of Kin Address </label>
                      <div className="data-value">
                        {studentData?.nextOfKinAddress}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data two">
                    <div className="form-group">
                      <label className="label">Sponsorship Type </label>
                      <div className="data-value">
                        {studentData?.sponsorType}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Sponsor Name </label>
                      <div className="data-value">
                        {studentData?.sponsorName}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data ">
                    <div className="form-group">
                      <label className="label">Sponsor Address </label>
                      <div className="data-value">
                        {studentData?.sponsorAddress}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "academic" && (
                <>
                  <div className="each_edit_section">
                    <div className="edit_heading">ACADEMIC INFORMATION</div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Student UTME Number </label>
                        <div className="data-value">
                          {studentData?.registrationNumber}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Student Level </label>
                        <div className="data-value">{studentData?.level} </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Matric Number </label>
                        <div className="data-value">
                          {studentData?.matricNumber}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Student Status </label>
                        <div className="data-value">
                          {studentData?.isActive ? "Active" : "In-Active"}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Program Type </label>
                        <div className="data-value">
                          {studentData?.programType}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Study Mode </label>
                        <div className="data-value">
                          {studentData?.studyMode}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Entry Mode </label>
                        <div className="data-value">
                          {studentData?.modeOfEntry}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Faculty </label>
                        <div className="data-value">
                          {studentData?.faculty}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Department </label>
                        <div className="data-value">
                          {studentData?.department}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Programme </label>
                        <div className="data-value">
                          {studentData?.programme}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data three">
                      <div className="form-group">
                        <label className="label">Award in View </label>
                        <div className="data-value">
                          {studentData?.awardInView}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Course Duration * </label>
                        <div className="data-value">
                          {studentData?.programDuration}years{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">
                          Entry Year (e.g 2013) *{" "}
                        </label>
                        <div className="data-value">
                          Year {studentData?.entryYear}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data one">
                      <div className="form-group">
                        <label className="label">
                          Student Current Session *{" "}
                        </label>
                        <div className="data-value">
                          {studentData?.currentSession}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data ">
                      <div className="form-group">
                        <label className="label">
                          Extra Curricular Activities
                        </label>
                        <div className="data-value">
                          {studentData?.extraActivities}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each_edit_section">
                    <div className="edit_heading">
                      PREVIOUS ACADEMIC HISTORY
                    </div>
                    <div className="edit_data two">
                      <div className="form-group">
                        <label className="label">
                          Highest Qualification *{" "}
                        </label>
                        <div className="data-value">
                          {studentData?.highestQualification}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">
                          Institution Obtained & Date{" "}
                        </label>
                        <div className="data-value">
                          {studentData?.institutionObtained}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="edit_data two">
                      <div className="form-group">
                        <label className="label">Previous University</label>
                        <div className="data-value">
                          {studentData?.previousUniversity}{" "}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">First Degree Course</label>
                        <div className="data-value">
                          {studentData?.subjectOfFirstDegree}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeTab === "others" && (
                <div className="each_edit_section">
                  <div className="edit_heading">HEALTH INFORMATION</div>
                  <div className="edit_data three">
                    <div className="form-group">
                      <label className="label">Status *</label>
                      <div className="data-value">
                        {studentData?.healthStatus}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Disability * </label>
                      <div className="data-value">
                        {studentData?.disability}{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Blood Group * </label>
                      <div className="data-value">
                        {studentData?.bloodGroup}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="edit_data">
                    <div className="form-group">
                      <label className="label">Medication</label>
                      <div className="data-value">
                        {studentData?.medication}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="form_cta">
                <button
                  className="btn back_cta"
                  onClick={() => setViewBioData(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_PENDING && <LoadingItem />}

      {pageProps.adminGetBioDataMetaRequest?.request_status ===
        appConstantsPortal.FETCH_BIODATA_META_FAILURE && (
        <AlertMsg
          type="error"
          message={pageProps.adminGetBioDataMetaRequest?.request_data.error}
        />
      )}
    </div>
  );
};

const DisplayStudentBioData = ({
  pageProps,
  selectedStudent,
  showEditBioData,
  setShowEditBioData,
  viewBioData,
  setViewBioData,
}: any) => {
  let loadAStudentsRequest = pageProps?.adminGetAStudentRequest;
  let updateRequest = pageProps?.adminUpdateAStudentRequest;

  useEffect(() => {
    let payload = {
      matricNumber: selectedStudent?.matricNumber,
    };
    adminGetAStudentRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className="biodata_modal"
      show={showEditBioData || viewBioData}
      onHide={() => {
        if (!updateRequest?.is_request_processing) {
          adminUpdateStudentsBioDataRequest({
            pageProps,
            payload: "CLEAR",
          });
          if (showEditBioData) {
            setShowEditBioData(false);
          }
          if (viewBioData) {
            setViewBioData(false);
          }
        }
      }}
    >
      <Modal.Body>
        {loadAStudentsRequest?.request_status ===
          appConstantsPortal?.GET_A_STUDENT_PENDING && (
          <div className="select_all_msm">
            <LoadingItem />
          </div>
        )}
        {loadAStudentsRequest?.request_status ===
          appConstantsPortal?.GET_A_STUDENT_FAILURE && (
          <div className="w-50-a">
            <AlertMsg
              type="error"
              message={loadAStudentsRequest?.request_data.error}
            />
          </div>
        )}

        {loadAStudentsRequest?.request_status ===
          appConstantsPortal?.GET_A_STUDENT_SUCCESS && (
          <>
            {showEditBioData && (
              <StudentEditBiodataInfo
                setShowEditBioData={setShowEditBioData}
                pageProps={pageProps}
              />
            )}

            {viewBioData && (
              <StudentViewBiodataInfo
                setViewBioData={setViewBioData}
                pageProps={pageProps}
              />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const RenderEachCourse = ({
  studentData,
  pageProps,
  setSelectedStudent,
  setShowEditBioData,
  setViewBioData,
}: any) => {
  const [showDownload, setShowDownload] = useState<boolean>(false);
  let downloadBiodataRequest = pageProps?.printBioDataReducer;

  const handlePrint = () => {
    let payload = `?matricNumber=${studentData.matricNumber}`;
    setShowDownload(true);
    printBiodataRequest({
      pageProps,
      payload,
    });
  };

  return (
    <>
      {downloadBiodataRequest.request_status ===
        appConstantsPortal.PRINT_BIODATA_FAILURE && (
        <AlertMsg
          type="error"
          message={downloadBiodataRequest?.request_data.error}
        />
      )}

      {downloadBiodataRequest.request_status ===
        appConstantsPortal.PRINT_BIODATA_SUCCESS && showDownload ? (
        <Modal
          contentClassName="common-report-modal"
          className="filter_modal"
          show={showDownload}
          onHide={() => {
            setShowDownload(!showDownload);
          }}
        >
          <Modal.Body>
            <div className="alert-msg success">
              <div className="alert-txt">
                You can now{" "}
                <a
                  className="dl_link"
                  target="_blank"
                  rel="noreferrer"
                  href={downloadBiodataRequest?.request_data?.response?.data}
                  onClick={() => {
                    setShowDownload(!showDownload);
                  }}
                >
                  print biodata
                </a>{" "}
                for {studentData.matricNumber}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      <tr>
        <td>{studentData?.fullName}</td>
        <td>{studentData?.matricNumber}</td>
        <td>{studentData?.faculty}</td>
        <td>{studentData?.programme}</td>
        <td>{studentData?.level}</td>
        <td>{studentData?.entryYear}</td>
        <td>{studentData?.state}</td>
        <td>
          <div className="actions_list">
            <span
              onClick={() => {
                setSelectedStudent(studentData);
                setShowEditBioData(false);
                setViewBioData(true);
              }}
            >
              <img src={ViewIco} alt="" />
            </span>
            <span
              onClick={() => {
                setSelectedStudent(studentData);
                adminUpdateStudentsBioDataRequest({
                  pageProps,
                  payload: "CLEAR",
                });
                setViewBioData(false);
                setShowEditBioData(true);
              }}
            >
              <img src={EditIco} alt="" />
            </span>
            <span onClick={handlePrint}>
              <img src={PrintIco} alt="" />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const DisplayPaginatedStudents = ({ pageProps }: any) => {
  const [showEditBioData, setShowEditBioData] = useState<boolean>(false);
  const [viewBioData, setViewBioData] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<any>();

  let studentsList =
    pageProps?.adminGetAllStudentsRequest?.request_data?.response?.data?.items;

  return (
    <>
      {(showEditBioData || viewBioData) && (
        <DisplayStudentBioData
          selectedStudent={selectedStudent}
          showEditBioData={showEditBioData}
          viewBioData={viewBioData}
          setShowEditBioData={setShowEditBioData}
          setViewBioData={setViewBioData}
          pageProps={pageProps}
        />
      )}

      <Table hover>
        <thead>
          <tr>
            <th>FULL NAME</th>
            <th>MATRIC NO</th>
            <th>FACULTY</th>
            <th>COURSE</th>
            <th>LEVEL</th>
            <th>ADM. YEAR</th>
            <th>STATE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {studentsList.map((eachData: any, index: any) => {
            return (
              <RenderEachCourse
                key={`${index}`}
                setSelectedStudent={setSelectedStudent}
                showEditBioData={showEditBioData}
                setShowEditBioData={setShowEditBioData}
                setViewBioData={setViewBioData}
                viewBioData={viewBioData}
                studentData={eachData}
                pageProps={pageProps}
                selectedStudent={selectedStudent}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const PaginateAllStudentList = ({
  itemsPerPage = 20,
  setPageNumber,
  pageNumber,
  pageProps,
}: any) => {
  const allData =
    pageProps.adminGetAllStudentsRequest.request_data.response.data.totalCount;
  let studentsList =
    pageProps?.adminGetAllStudentsRequest?.request_data?.response?.data?.items;

  //   const currentItems = allData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allData / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    setPageNumber(newOffset);
  };

  return (
    <>
      <DisplayPaginatedStudents
        // currentItems={currentItems}
        pageProps={pageProps}
      />
      {studentsList?.length ? (
        <div className="result-count">
          <p>
            Showing {studentsList?.length} of {allData} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
        forcePage={pageNumber - 1}
      />
    </>
  );
};

const LoadCourseWrap = ({ pageProps, searchParameter }: any) => {
  let loadAllStudentsRequest = pageProps?.adminGetAllStudentsRequest;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPageNumber(1);
  }, [searchParameter]);

  useEffect(() => {
    let payload = `?PageNumber=${pageNumber}&PageSize=${20}`;
    const searchPayload = `?PageNumber=${pageNumber}&PageSize=${20}&searchQuery=${searchParameter}`;
    let searchDebounce: any;
    if (searchParameter?.length) {
      searchDebounce = setTimeout(
        () =>
          adminSearchStudentsRequest({
            pageProps,
            payload: searchPayload,
          }),
        1000
      );
    } else {
      adminGetStudentsListRequest({
        pageProps,
        payload,
      });
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, searchParameter]);

  useEffect(() => {
    if (
      loadAllStudentsRequest?.request_status ===
      appConstantsPortal?.GET_STUDENTS_LIST_SUCCESS
    ) {
      adminGetBiodataMetaRequest({
        pageProps,
        payload: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAllStudentsRequest?.request_status]);

  return (
    <div className="students_list_wrap">
      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.GET_STUDENTS_LIST_PENDING && (
        <div className="select_all_msm">
          <LoadingItem />
        </div>
      )}
      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.GET_STUDENTS_LIST_FAILURE && (
        <div className="w-50-a">
          <AlertMsg
            type="error"
            message={loadAllStudentsRequest?.request_data.error}
          />
        </div>
      )}
      {loadAllStudentsRequest?.request_status ===
        appConstantsPortal?.GET_STUDENTS_LIST_SUCCESS && (
        <div className="all_students_list">
          <PaginateAllStudentList
            pageProps={pageProps}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </div>
      )}
    </div>
  );
};

const StudentsWrap = ({ pageProps }: any) => {
  const [searchParameter, setSearchParameter] = useState("");

  // useEffect(() => {
  //     adminGetStudentsListRequest({
  //         pageProps,
  //         payload: "CLEAR"
  //     })
  // }, [])

  return (
    <div className="courses_wrap">
      <div className="search-student-input">
        <SearchBox
          placeholder="Search students..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />
      </div>

      {/* <div className="page_heading_txt">Student Management Module</div> */}
      {/* <div className="main_content">
                {requestInfo?.request_status === appConstantsPortal?.GET_STUDENTS_LIST_PENDING &&
                    <LoadingItem />
                }
                {requestInfo?.request_status === appConstantsPortal?.GET_STUDENTS_LIST_FAILURE &&
                    <div className="w-50-a">
                        <AlertMsg type="error" message={requestInfo?.request_data.error} />
                    </div>

                }
                {requestInfo?.request_status === appConstantsPortal?.GET_STUDENTS_LIST_SUCCESS &&
                    <LoadCourseWrap pageProps={pageProps} requestInfo={requestInfo} />
                }
            </div> */}
      <LoadCourseWrap pageProps={pageProps} searchParameter={searchParameter} />
    </div>
  );
};

const UGradLoadAllStudentsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle="STUDENT MANAGEMENT MODULE"
      childComponent={<StudentsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetStudentsList: adminInAppActions.AdminGetStudentsList,
  adminSearchStudents: adminInAppActions.adminSearchStudents,
  adminGetAStudent: adminInAppActions.AdminGetAStudent,
  adminUpdateAStudent: adminInAppActions.AdminUpdateAStudent,
  adminLoadBiodataMeta: adminInAppActions.AdminLoadBiodataMeta,
  printBiodata: adminInAppActions.PrintBioData,
};
const mapStateToProps = (state: any) => ({
  adminGetAllStudentsRequest:
    state.allUGradPortalReducers.adminGetAllStudentsReducer,
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminUpdateAStudentRequest:
    state.allUGradPortalReducers.adminUpdateAStudentReducer,
  adminGetBioDataMetaRequest:
    state.allUGradPortalReducers.adminGetBioDataMetaReducer,
  printBioDataReducer: state.allUGradPortalReducers.printBioDataReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UGradLoadAllStudentsWrap);
