import React from "react";
import "./index.scss";

interface StatusIndicatorProps {
  isActive: boolean;
  isDeferred?: boolean;
  className?: string;
  className1?: any;
  className2?: any;
  className3?: any;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  isActive,
  isDeferred,
  className,
  className1,
  className2,
  className3,
}) => {
  const getStatus = () => {
    if (isActive && !isDeferred) {
      return {
        label: "Active",
        color: "#4caf50",
        icon: "✔",
        backgroundColor: "#4caf50",
      };
    } else if (!isActive) {
      return {
        label: "Deactivated",
        color: "red",
        icon: "X",
        backgroundColor: "rgb(241, 50, 50)",
      };
    } else if (isActive && isDeferred) {
      return {
        label: "Deferred",
        color: "rgb(64, 64, 186)",
        icon: "||",
        backgroundColor: "rgb(64, 64, 186)",
      };
    }
    return null;
  };

  const status = getStatus();

  if (!status) return null;

  return (
    <div className="active-button">
      {/* {isActive && !isDeferred && (
        <span
          className={`active-label ${className1}`}
          style={{ color: status.color, fontWeight: "bold" }}
        >
          {status.label}
        </span>
      )} */}

      <div
        className={`toggle ${className}`}
        style={{ backgroundColor: status.backgroundColor }}
      >
        <div
          className={`${
            isDeferred && isActive ? "pause-icon" : `icon-wrap ${className1}`
          }`}
        >
          {isDeferred && isActive && (
            <span
              className={`${
                isDeferred && isActive
                  ? "pause-background"
                  : `icon-background ${className2}`
              }`}
            ></span>
          )}{" "}
          <span
            className={`${
              isDeferred && isActive
                ? "pause-check"
                : `icon-check ${className3}`
            }`}
          >
            {status.icon}
          </span>
        </div>
      </div>
      {/* {(isDeferred || !isActive) && ( */}
        <span
          className={`active-label ${className1}`}
          style={{ color: status.color, fontWeight: "bold" }}
        >
          {status.label}
        </span>
      {/* )} */}
    </div>
  );
};

export default StatusIndicator;