import React from "react";
import EDocumentationDashboardData from "./EDocumentation";
import { InAppTemplate } from "../../../shared/templates/portal";
const DashboardData = () => {
  return (
    <InAppTemplate
      childComponent={<EDocumentationDashboardData />}
      pageTitle="E-DOCUMENTATION






"
    />
  );
};

export default DashboardData;
