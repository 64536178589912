import "./index.scss";
import Card from "../postutme/Card";
import { HOSTEL_ACCOMMODATION_LIST } from "../../shared/_helpers/constants";
import HostelDashboard from "./hostel-dashboard";


const HostelMenu = () => {
  
  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";
  let user: any = JSON.parse(loggedInUser) || {};
  const userRole = user?.profile?.userRoles;

const filteredAccommodationList =
userRole[0] === "Hostel_Porter"
  ? HOSTEL_ACCOMMODATION_LIST.filter(
      (item) => item.title === "REPORTS"
    ) 
  : HOSTEL_ACCOMMODATION_LIST; 

return (
<>
  <div className="menu-container">
    {filteredAccommodationList.map((item: any, index: number) => (
      <Card
        key={index}
        title={item.title}
        buttonText={item.buttonText}
        icon={item.icon}
        backgroundColor={item.backgroundColor}
        routeTo={item.routeTo}
      />
    ))}
  </div>
  <div>
    <HostelDashboard />
  </div>
</>
);
};

export default HostelMenu;