import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";

import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import PencilIco from "../../assets/pencil.png";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminLoadSettingsRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetCurrentSessionAction(payload);
};

const adminSetCurrentSessionSettingsRequest = async ({
  pageProps,
  payload,
  isAdd,
}: any) => {
  await pageProps.adminSetCurrentSessionAction(payload, isAdd);
};

const AddToItemsModal = ({
  pageProps,
  showAddSettings,
  setShowAddSettings,
  pageSize,
  pageNumber,
  searchText,
  itemToEdit,
}: any) => {
  let addItemRequest = pageProps?.adminUpdateSessionRequest;
  let currentYear = itemToEdit?.session.split("/");

  let initialValues = {
    currentSession: itemToEdit?.session,
    from: currentYear[0],
    to: currentYear[1],
    isCourseRegistrationOpen: itemToEdit?.isCourseRegistrationOpen
      ? "Ongoing"
      : "Closed",
    isHostelAccommodationOpen: itemToEdit?.isHostelAccommodationOpen
      ? "Ongoing"
      : "Closed",
    isPaymentOpen: itemToEdit?.isPaymentOpen ? "Ongoing" : "Closed",
    isActive: itemToEdit?.isActive ? "Active" : "Ended",
    semester: itemToEdit?.semester || "",
    type: itemToEdit?.type || "",
  };

  let checkValidationSchema = Yup.object().shape({
    currentSession: Yup.string().required("Required"),
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
    isCourseRegistrationOpen: Yup.string().required("Required"),
    isHostelAccommodationOpen: Yup.string().required("Required"),
    isPaymentOpen: Yup.string().required("Required"),
    isActive: Yup.string().required("Required"),
    semester: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  });

  return (
    <>
      <Modal
        className="settings_modal add_item_modal"
        show={showAddSettings}
        onHide={() => {
          if (!addItemRequest?.is_request_processing) {
            if (
              addItemRequest?.request_status ===
              appConstantsPortal?.SET_CURRENT_SESSION_SUCCESS
            ) {
              let payload = "";

              adminLoadSettingsRequest({
                pageProps,
                payload,
              });
            }
            setShowAddSettings(false);
          }
        }}
      >
        {addItemRequest?.request_status !==
          appConstantsPortal?.SET_CURRENT_SESSION_SUCCESS && (
          <div className="add_item_wrap">
            <div className="heading_text">Update Session</div>
            <Formik
              initialValues={initialValues}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                let {
                  currentSession,
                  isCourseRegistrationOpen,
                  isActive,
                  isPaymentOpen,
                  isHostelAccommodationOpen,
                  type,
                } = values;
                let payload: any = {
                  session: currentSession,
                  isCourseRegistrationOpen:
                    type !== "POST-UTME"
                      ? isCourseRegistrationOpen === "Ongoing"
                        ? true
                        : false
                      : false,
                  isHostelAccommodationOpen:
                    type !== "POST-UTME"
                      ? isHostelAccommodationOpen === "Ongoing"
                        ? true
                        : false
                      : false,
                  isPaymentOpen:
                    type !== "POST-UTME"
                      ? isPaymentOpen === "Ongoing"
                        ? true
                        : false
                      : false,
                  isActive: isActive === "Active" ? true : false,
                  semester: values?.semester,
                  createdBy:
                    pageProps?.adminLoginRequest?.request_data?.username,
                  type,
                };

                await adminSetCurrentSessionSettingsRequest({
                  pageProps,
                  payload,
                  isAdd: true,
                });
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldTouched,
                  setFieldValue,
                } = props;
                return (
                  <Form>
                    <div className="form-group">
                      <label className="label">Session </label>
                      <div
                        className={
                          errors.currentSession && touched.currentSession
                            ? "invalid-input-wrap"
                            : ""
                        }
                      >
                        <Field
                          value={values.currentSession}
                          id="currentSession"
                          name="currentSession"
                          type="text"
                          // onChange={handleChange('currentSession')}
                          onChange={() => {}}
                          placeholder="Enter Session"
                          className={`input-wrap ${
                            errors.currentSession && touched.currentSession
                              ? "is-invalid-input "
                              : !errors.currentSession &&
                                values.currentSession !== ""
                              ? "valid-input"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="currentSession"
                        className="form-input-error"
                        component="div"
                      />
                    </div>

                    <div className="full-items">
                      <div className="form-group">
                        <label className="label">Session Type </label>
                        <div
                          className={
                            errors.type && touched.type
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          {itemToEdit?.type == "POST-UTME" && (
                            <select
                              disabled={true}
                              name="type"
                              id="type"
                              value="POST-UTME"
                              onChange={() => {}}
                              className={`input-wrap valid-input`}
                            >
                              <option disabled={true} value="POST-UTME">
                                POST-UTME
                              </option>
                            </select>
                          )}
                          {itemToEdit?.type !== "POST-UTME" && (
                            <select
                              // disabled={true}
                              name="type"
                              id="type"
                              value={values?.type}
                              onChange={handleChange}
                              className={`input-wrap ${
                                errors.type && touched.type
                                  ? "is-invalid-input "
                                  : !errors.type && values.type !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            >
                              <option
                                // disabled={values?.type === "Ongoing"}
                                value="SCHOOL"
                              >
                                SCHOOL
                              </option>
                              <option
                                // disabled={values?.type === "Closed"}
                                value="POST-UTME"
                              >
                                POST-UTME
                              </option>
                            </select>
                          )}
                        </div>
                        <ErrorMessage
                          name="type"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      {values.type !== "POST-UTME" && (
                        <div className="form-group">
                          <label className="label"> Hostel Reg </label>
                          <div
                            className={
                              errors.isHostelAccommodationOpen &&
                              touched.isHostelAccommodationOpen
                                ? "invalid-input-wrap"
                                : ""
                            }
                          >
                            <select
                              name="isHostelAccommodationOpen"
                              id="isHostelAccommodationOpen"
                              value={
                                values.type !== "POST-UTME"
                                  ? values?.isHostelAccommodationOpen
                                  : "Closed"
                              }
                              onChange={
                                itemToEdit?.type !== "POST-UTME"
                                  ? handleChange
                                  : () => {}
                              }
                              disabled={
                                itemToEdit?.type !== "POST-UTME" ? false : true
                              }
                              className={`input-wrap ${
                                errors.isHostelAccommodationOpen &&
                                touched.isHostelAccommodationOpen
                                  ? "is-invalid-input "
                                  : !errors.isHostelAccommodationOpen &&
                                    values.isHostelAccommodationOpen !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            >
                              {/* {(itemToEdit?.type !== "POST-UTME" && values.type !== "POST-UTME") && */}
                              {values.type !== "POST-UTME" && (
                                <option
                                  disabled={
                                    itemToEdit?.type !== "POST-UTME" &&
                                    values.type !== "POST-UTME"
                                      ? false
                                      : true
                                  }
                                  value="Ongoing"
                                >
                                  Ongoing
                                </option>
                              )}
                              <option value="Closed">Closed</option>
                            </select>
                          </div>
                          <ErrorMessage
                            name="isHostelAccommodationOpen"
                            className="form-input-error"
                            component="div"
                          />
                        </div>
                      )}
                      {values.type == "POST-UTME" && (
                        <div className="form-group">
                          <label className="label">Session Status </label>
                          <div
                            className={
                              errors.isActive && touched.isActive
                                ? "invalid-input-wrap"
                                : ""
                            }
                          >
                            <select
                              name="isActive"
                              id="isActive"
                              value={values?.isActive}
                              onChange={handleChange}
                              className={`input-wrap ${
                                errors.isActive && touched.isActive
                                  ? "is-invalid-input "
                                  : !errors.isActive && values.isActive !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            >
                              <option
                                disabled={values?.isActive === "Active"}
                                value="Active"
                              >
                                Active
                              </option>
                              <option
                                disabled={values?.isActive === "Ended"}
                                value="Ended"
                              >
                                Ended
                              </option>
                            </select>
                          </div>
                          <ErrorMessage
                            name="isActive"
                            className="form-input-error"
                            component="div"
                          />
                        </div>
                      )}
                    </div>

                    <div className="two-items">
                      <div className="form-group">
                        <label className="label">From </label>
                        <div
                          className={
                            errors.from && touched.from
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <select
                            name="from"
                            id="from"
                            value={values?.from}
                            onChange={(e: any) => {
                              setFieldValue("to", "");
                              setFieldValue("from", e.target.value);
                              setFieldTouched("from", true);
                            }}
                            className={`input-wrap ${
                              errors.from && touched.from
                                ? "is-invalid-input "
                                : !errors.from && values.from !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          >
                            <option value={currentYear[0]}>
                              {currentYear[0]}
                            </option>
                            <option value={currentYear[1]}>
                              {currentYear[1]}
                            </option>
                          </select>
                        </div>
                        <ErrorMessage
                          name="from"
                          className="form-input-error"
                          component="div"
                        />
                      </div>

                      <div className="form-group">
                        <label className="label">To </label>
                        <div
                          className={
                            errors.to && touched.to ? "invalid-input-wrap" : ""
                          }
                        >
                          <select
                            name="to"
                            id="to"
                            value={values?.to}
                            onChange={(e: any) => {
                              setFieldValue("to", e.target.value);
                              // setFieldTouched("to", true)
                              setFieldValue(
                                "currentSession",
                                `${values.from}/${e.target.value}`
                              );
                              setFieldTouched("currentSession", true);
                              // setFieldError("to", "")
                            }}
                            className={`input-wrap ${
                              errors.to && touched.to
                                ? "is-invalid-input "
                                : !errors.to && values.to !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          >
                            <option value="">Select</option>
                            <option value={parseFloat(values?.from) + 1}>
                              {parseFloat(values?.from) + 1}
                            </option>
                          </select>
                        </div>
                        <ErrorMessage
                          name="to"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                    </div>
                    {values.type !== "POST-UTME" && (
                      <>
                        <div className="two-items">
                          <div className="form-group">
                            <label className="label">Session Status </label>
                            <div
                              className={
                                errors.isActive && touched.isActive
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="isActive"
                                id="isActive"
                                value={values?.isActive}
                                onChange={handleChange}
                                className={`input-wrap ${
                                  errors.isActive && touched.isActive
                                    ? "is-invalid-input "
                                    : !errors.isActive && values.isActive !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option
                                  disabled={values?.isActive === "Active"}
                                  value="Active"
                                >
                                  Active
                                </option>
                                <option
                                  disabled={values?.isActive === "Ended"}
                                  value="Ended"
                                >
                                  Ended
                                </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="isActive"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Course Reg Status </label>
                            <div
                              className={
                                errors.isCourseRegistrationOpen &&
                                touched.isCourseRegistrationOpen
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="isCourseRegistrationOpen"
                                id="isCourseRegistrationOpen"
                                value={
                                  values.type !== "POST-UTME"
                                    ? values?.isCourseRegistrationOpen
                                    : "Closed"
                                }
                                // value={values?.isCourseRegistrationOpen}
                                onChange={handleChange}
                                className={`input-wrap ${
                                  errors.isCourseRegistrationOpen &&
                                  touched.isCourseRegistrationOpen
                                    ? "is-invalid-input "
                                    : !errors.isCourseRegistrationOpen &&
                                      values.isCourseRegistrationOpen !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option
                                  disabled={
                                    values?.isCourseRegistrationOpen ===
                                    "Ongoing"
                                  }
                                  value="Ongoing"
                                >
                                  Ongoing
                                </option>
                                <option
                                  disabled={
                                    values?.isCourseRegistrationOpen ===
                                    "Closed"
                                  }
                                  value="Closed"
                                >
                                  Closed
                                </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="isCourseRegistrationOpen"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="two-items">
                          <div className="form-group">
                            <label className="label">
                              Registration Status{" "}
                            </label>
                            <div
                              className={
                                errors.isPaymentOpen && touched.isPaymentOpen
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="isPaymentOpen"
                                id="isPaymentOpen"
                                value={values?.isPaymentOpen}
                                onChange={handleChange}
                                className={`input-wrap ${
                                  errors.isPaymentOpen && touched.isPaymentOpen
                                    ? "is-invalid-input "
                                    : !errors.isPaymentOpen &&
                                      values.isPaymentOpen !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option
                                  disabled={values?.isPaymentOpen === "Ongoing"}
                                  value="Ongoing"
                                >
                                  Ongoing
                                </option>
                                <option
                                  disabled={values?.isPaymentOpen === "Closed"}
                                  value="Closed"
                                >
                                  Closed
                                </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="isPaymentOpen"
                              className="form-input-error"
                              component="div"
                            />
                          </div>

                          <div className="form-group">
                            <label className="label"> Semester </label>
                            <div
                              className={
                                errors.semester && touched.semester
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="semester"
                                id="semester"
                                value={values?.semester}
                                onChange={(e) => {
                                  setFieldValue("semester", e.target.value);
                                }}
                                className={`input-wrap ${
                                  errors.semester && touched.semester
                                    ? "is-invalid-input "
                                    : !errors.semester && values.semester !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option
                                  disabled={
                                    values?.semester === "FIRST SEMESTER"
                                  }
                                  value="FIRST SEMESTER"
                                >
                                  First Semester
                                </option>
                                <option
                                  disabled={
                                    values?.semester === "SECOND SEMESTER"
                                  }
                                  value="SECOND SEMESTER"
                                >
                                  Second Semester
                                </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="semester"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {addItemRequest.request_status ===
                      appConstantsPortal?.SET_CURRENT_SESSION_FAILURE && (
                      <AlertMsg
                        type="error"
                        message={addItemRequest?.request_data.error}
                      />
                    )}

                    <div className="key_msg">
                      Please note that updating the session will create a new
                      session.
                    </div>

                    <div className="submit_cta">
                      <button
                        disabled={addItemRequest?.is_request_processing}
                        className="btn shaded"
                        type="submit"
                      >
                        {addItemRequest?.is_request_processing
                          ? "Please wait..."
                          : "Submit"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {addItemRequest?.request_status ===
          appConstantsPortal?.SET_CURRENT_SESSION_SUCCESS && (
          <div className="w-50-a">
            <AlertMsg type="success" message="Successfully added" />
          </div>
        )}
      </Modal>
    </>
  );
};

const SettingsContent = ({
  pageProps,
  pageSize,
  pageNumber,
  allData,
  searchText,
}: any) => {
  const [showAddSettings, setShowAddSettings] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<any>();

  return (
    <div>
      <div className="back-to-portal">
        <Navigation to="/app/portal-settings" text="Back to Portal Settings" />
      </div>
      <div className="settings_header">
        {showAddSettings && (
          <AddToItemsModal
            pageProps={pageProps}
            showAddSettings={showAddSettings}
            setShowAddSettings={setShowAddSettings}
            pageSize={pageSize}
            itemToEdit={itemToEdit}
            searchText={searchText}
            pageNumber={pageNumber}
          />
        )}
      </div>

      <div className="table_head for_sessions">
        <div className="settings_name">CURRENT SESSION </div>
        <div className="settings_name">TYPE </div>
        <div className="settings_name">REGISTRATION</div>
        <div className="settings_name">HOSTEL REG</div>
        <div className="settings_name">SEMESTER</div>
        <div className="settings_name">COURSE REG</div>
        <div className="settings_action">ACTIONS</div>
      </div>
      <div className="all_settings_items ">
        {allData?.items &&
          allData?.items.map((eachSession: any, index: any) => {
            return (
              <div className="each-settings sessions" key={index}>
                <div className="setting-option">{eachSession?.session}</div>
                <div className="setting-option">{eachSession?.type}</div>
                <div className="setting-option">
                  {eachSession?.type !== "POST-UTME"
                    ? `${eachSession?.isPaymentOpen ? "Open" : "Closed"}`
                    : `${eachSession?.isActive ? "Open" : "Closed"}`}
                </div>
                <div className="setting-option">
                  {eachSession?.type !== "POST-UTME"
                    ? `${
                        eachSession?.isHostelAccommodationOpen
                          ? "Open"
                          : "Closed"
                      }`
                    : "-"}
                </div>
                <div className="setting-option">
                  {eachSession?.type !== "POST-UTME"
                    ? eachSession?.semester
                    : "-"}
                </div>
                <div className="setting-option">
                  {eachSession?.type !== "POST-UTME"
                    ? `${
                        eachSession?.isCourseRegistrationOpen
                          ? "Open"
                          : "Closed"
                      }`
                    : "-"}
                </div>
                <div className="setting-action">
                  {" "}
                  <span
                    onClick={() => {
                      adminSetCurrentSessionSettingsRequest({
                        pageProps,
                        payload: "CLEAR",
                      });
                      setItemToEdit(eachSession);
                      setShowAddSettings(true);
                    }}
                  >
                    <img src={PencilIco} alt="" />
                  </span>{" "}
                </div>
              </div>
            );
          })}
        {/* <div className="each-settings">
          <div className="setting-option">{allData?.session}</div>
          <div className="setting-option">
            {allData?.isPaymentOpen ? "Open" : "Closed"}
          </div>
          <div className="setting-option">{allData?.semester}</div>
          <div className="setting-option">
            {allData?.isCourseRegistrationOpen ? "Open" : "Closed"}
          </div>
          <div className="setting-action">
            {" "}
            <span
              onClick={() => {
                adminSetCurrentSessionSettingsRequest({
                  pageProps,
                  payload: "CLEAR",
                });
                setItemToEdit(allData);
                setShowAddSettings(true);
              }}
            >
              <img src={PencilIco} alt="" />
            </span>{" "}
          </div>
        </div> */}
      </div>
    </div>
  );
};

const CurrentSessionSettings = ({ pageProps }: any) => {
  let requestInfo = pageProps?.adminGetCurrentSessionRequest;
  useEffect(() => {
    let payload = "";

    adminLoadSettingsRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="settings_wrap_content">
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_CURRENT_SESSION_PENDING && <LoadingItem />}
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_CURRENT_SESSION_FAILURE && (
        <div className="w-50-a">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_CURRENT_SESSION_SUCCESS && (
        <SettingsContent
          allData={requestInfo?.request_data?.response?.data}
          pageProps={pageProps}
        />
      )}
    </div>
  );
};

 const AdminCurrentSessionSettingsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`CURRENT SESSION SETTINGS`}
      childComponent={<CurrentSessionSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetCurrentSessionAction: adminInAppActions.AdminGetCurrentSession,
  adminSetCurrentSessionAction: adminInAppActions.AdminSetCurrentSession,
};
const mapStateToProps = (state: any) => ({
  adminGetCurrentSessionRequest:
    state.allUGradPortalReducers.adminGetCurrentSessionReducer,
  adminUpdateSessionRequest:
    state.allUGradPortalReducers.adminSetCurrentSessionReducer,
  adminLoginRequest: state.allOnboardingReducers.adminLogin,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCurrentSessionSettingsWrap);
