import { useEffect, useState } from "react";
import "./index.scss";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import ReportTable from "./ReportTable";
import Select from "react-select";
import { LoadingItem } from "../../../shared/components/loading";
import { handleRequestErrors } from "../../../shared/utils";
import {
  courseRegistrationSearch,
  downloadEOReport,
  fetchCourseReport,
} from "../../../redux/action/EOPreport";
import ReactPaginate from "react-paginate";
import { DotLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import Navigation from "../../../shared/components/navigation/Navigation";
import { useSelector } from "react-redux";
import { getSchoolSessionHistory } from "../../../redux/action/onboarding";
import { useDispatch } from "react-redux";

export interface Course {
  courseTitle: string;
  courseCode: string;
  courseUnit: number | null;
}

const EOReport = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [fileType, setFileType] = useState<string>("CSV");
  const [showCourseResultsList, setShowCourseResultsList] =
    useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<Course>({
    courseTitle: "",
    courseCode: "",
    courseUnit: null,
  });
  const [sessionSelected, setSessionSelected] = useState({
    label: "",
    value: "",
  });
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [reportList, setReportList] = useState([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [fetchingReport, setfetchingReport] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const pageCount: number = Math.ceil(totalCount / 20);

  const dispatch = useDispatch();

  const state = useSelector((state: any) => state);
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];
  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));

  // const fetchSessionHistory = async () => {
  //   await getSessionHistory()
  //     .then(({ data }) => {
  //       if (data?.length) {
  //         const formattedSessionList = data.map((singleSession: string) => ({
  //           label: singleSession,
  //           value: singleSession,
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       handleRequestErrors(err);
  //     });
  // };

  const handleCourseSearch = async () => {
    if (!sessionSelected?.value?.length) {
      return toast.error("Select session value.");
    }
    if (!searchParameter?.length) {
      return toast.error("Please enter course to search.");
    }
    setShowCourseResultsList(true);
    setSearching(true);
    await courseRegistrationSearch(searchParameter)
      .then(({ data }) => {
        if (data?.length) {
          setCourseList(data);
        } else {
          toast.error("No course found");
        }
        setSearching(false);
      })
      .catch((err) => {
        setSearching(false);
        handleRequestErrors(err);
        toast.error("An error occurred, please try again.");
      });
  };

  const handleReportFetch = async (
    pageNumber: number = 1,
    course = selectedCourse
  ) => {
    setfetchingReport(true);
    await fetchCourseReport(course, pageNumber, sessionSelected.value)
      .then(({ data }) => {
        setShowCourseResultsList(false);
        setfetchingReport(false);
        setReportList(data?.items);
        setTotalCount(data?.totalCount);
      })
      .catch((err) => {
        toast.error("An error occured, please try again.");
        setfetchingReport(false);
        handleRequestErrors(err);
      });
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    handleReportFetch(newOffset);
  };

  const handleReportDownload = async () => {
    if (!sessionSelected?.value || !selectedCourse?.courseTitle) {
      return toast.error("Please select session and course");
    }
    setDownloading(true);
    await downloadEOReport(selectedCourse, 0, sessionSelected.value, fileType)
      .then(({ data }) => {
        setDownloading(false);
        const downloadLink = data;
        if (downloadLink?.length) {
          const anchor = document.createElement("a");
          anchor.href = downloadLink;
          anchor.download = `${selectedCourse?.courseCode}-Report`;
          anchor.click();
        }
      })
      .catch((err) => {
        toast.error("An error occured, please try again.");
        setDownloading(false);
        handleRequestErrors(err);
      });
  };

  const handleCourseSelection = async (course: Course) => {
    setSelectedCourse(course);
    handleReportFetch(1, course);
  };

  useEffect(() => {
    if (!sessionHistory?.length) {
      dispatch(getSchoolSessionHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="back-to-eo-report">
        <Navigation to="/app/eo-reports" text="Back to EO-Reports" />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <div className="eo-report-container">
        <div className="filter-container">
          <Select
            options={sessionOptions}
            onChange={(option: any) => {
              setSessionSelected({
                label: option?.value,
                value: option?.value,
              });
            }}
            placeholder="Session"
            className="filter-select-wrap"
            classNamePrefix="session-select"
          />
          <div className="search-container">
            <div className="input-search">
              <input
                name="searchParameter"
                value={searchParameter}
                onChange={(e) => {
                  setCourseList([]);
                  let searchValue: string = e.target.value;
                  setSearchParameter(searchValue);
                }}
                onFocus={() => {
                  courseList?.length && setShowCourseResultsList(true);
                }}
              />
              <Button className="submit-btn" onClick={handleCourseSearch}>
                {searching ? (
                  <DotLoader
                    color="white"
                    loading={searching}
                    size={30}
                    aria-label="Submitting"
                  />
                ) : (
                  "Search"
                )}
              </Button>
            </div>
            {showCourseResultsList && courseList?.length ? (
              <div className="search-results">
                {courseList?.map((course: Course, index: number) => (
                  <p
                    key={index}
                    onClick={() => handleCourseSelection(course)}
                    className={
                      course?.courseTitle === selectedCourse?.courseTitle
                        ? "course-title selected"
                        : "course-title"
                    }
                  >
                    {course?.courseTitle}{" "}
                    {`(${course?.courseUnit} Unit${
                      (course?.courseUnit as number) > 1 ? "s" : ""
                    })`}
                  </p>
                ))}
              </div>
            ) : null}
          </div>

          <div className="fetch-container">
            <Dropdown as={ButtonGroup}>
              <Button
                variant="success"
                className="download_cta"
                onClick={handleReportDownload}
              >
                {downloading ? (
                  <DotLoader
                    color="white"
                    loading={downloading}
                    size={30}
                    aria-label="Submitting"
                  />
                ) : (
                  `Download as ${fileType}`
                )}
              </Button>

              <Dropdown.Toggle
                //   disabled={downloadReportRequest?.is_request_processing}
                split
                variant="success"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFileType("CSV")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("EXCEL")}>
                  Excel
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("PDF")}>
                  Pdf
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("CBT CSV")}>
                  CBT CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {fetchingReport && !reportList?.length ? <LoadingItem /> : null}

        <ReportTable reportList={reportList} loading={fetchingReport} />
        {reportList?.length ? (
          <>
            <p className="result-count">
              Showing {reportList?.length} of {totalCount} results.
            </p>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination_items"
              pageClassName="page_num"
              pageLinkClassName="page_link"
              activeClassName="active_page_link"
              previousClassName="previous_page_link"
              nextClassName="next_page_link"
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default EOReport;
