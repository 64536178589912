import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { adminInAppActions } from "../../../redux/action/portal";
import { appConstantsPortal } from "../../../redux/action-constants/portal";
import { InAppTemplate } from "../../../shared/templates/portal";
import Navigation from "../../../shared/components/navigation/Navigation";

const GetAUserAction = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAUserAction(payload, true);
};
const UpdateAUserStatusAction = async ({ pageProps, payload }: any) => {
  await pageProps.adminUpdateAUserStatusAction(payload);
};

const UpdateUserStatus = ({ pageProps }: any) => {
  const [actionText, setActionText] = useState<any>();
  let fetchRequestInfo = pageProps.adminGetAUserRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;

  let requestInfo = pageProps.adminUpdateAUserStatusRequest;


  const handleUpdate = (action: any) => {
    let payload: any = {
      name: fetchRequestInfo?.email,
      status: action === "activate",
    };
    setActionText(action);
    UpdateAUserStatusAction({ pageProps, payload });
  };

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS && (
        <div className="confirm_reset">
          <span>{fetchRequestInfo?.fullName}</span> is presently{" "}
          {fetchRequestInfo.isActive && <span> an Active user</span>}{" "}
          {!fetchRequestInfo.isActive && <span> a Deactivated user</span>}
          <br /> <br />
          {!fetchRequestInfo.isActive && (
            <div className="note">
              Do you want to <span>Activate</span>{" "}
              <span>{fetchRequestInfo?.fullName}</span> ?
            </div>
          )}
          {fetchRequestInfo.isActive && (
            <div className="note">
              Do you want to <span>Deactivate</span>{" "}
              <span>{fetchRequestInfo?.fullName}</span> ?
            </div>
          )}
          <div className="note">
            Please note that this user
            {fetchRequestInfo.isActive && (
              <span> will not have access to the user portal.</span>
            )}{" "}
            {!fetchRequestInfo.isActive && (
              <span> will now have access to the user portal.</span>
            )}{" "}
          </div>
          {fetchRequestInfo.isActive && (
            <button
              className="btn"
              disabled={requestInfo?.is_request_processing}
              onClick={() => handleUpdate("deactivate")}
            >
              {" "}
              {requestInfo?.is_request_processing
                ? "Please wait..."
                : "Deactivate user"}
            </button>
          )}
          {!fetchRequestInfo.isActive && (
            <button
              className="btn"
              disabled={requestInfo?.is_request_processing}
              onClick={() => handleUpdate("activate")}
            >
              {" "}
              {requestInfo?.is_request_processing
                ? "Please wait..."
                : "Activate user"}
            </button>
          )}
        </div>
      )}
      <div className="student_form">
        {requestInfo.request_status ===
         appConstantsPortal?.ACTIVATE_OR_DEACTIVATE_USER_FAILURE && (
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        )}

        {requestInfo.request_status ===
          appConstantsPortal?.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS && (
          <AlertMsg
            type="success"
            message={`${fetchRequestInfo?.fullName} has been successfully ${
              actionText === "activate" ? "Activated" : "Deactivated"
            }`}
          />
        )}
      </div>
    </>
  );
};

const UserForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAUserRequest;
  let updateStatusRequestInfo = pageProps.adminUpdateAUserStatusRequest;

  let checkValidationSchema = Yup.object().shape({
    searchTerm: Yup.string().required("Required"),
  });
  let initialValues: any = { searchTerm: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { searchTerm } = values;
          let payload: any = {
            searchTerm,
          };
          UpdateAUserStatusAction({
            pageProps,
            payload: "CLEAR",
          });
          await GetAUserAction({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            // handleBlur,
            handleChange,
            // isSubmitting,
          } = props;
          return (
            <Form>
              <div className="form-group">
                <label className="label">Username/email </label>
                <div
                  className={
                    errors.searchTerm && touched.searchTerm ? "invalid-input-wrap" : ""
                  }
                >
                  <Field
                    value={values.searchTerm}
                    id="searchTerm"
                    name="searchTerm"
                    type="text"
                    disabled={updateStatusRequestInfo?.is_request_processing}
                    onChange={handleChange("searchTerm")}
                    placeholder="Enter username/email"
                    className={`input-wrap ${
                      errors.searchTerm && touched.searchTerm
                        ? "is-invalid-input "
                        : !errors.searchTerm && values.searchTerm !== ""
                        ? "valid-input"
                        : ""
                    }`}
                  />
                </div>
                <ErrorMessage
                  name="searchTerm"
                  className="form-input-error"
                  component="div"
                />
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_A_USER_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    updateStatusRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search user"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const UpdateUserSettings = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAUserRequest;

  useEffect(() => {
    GetAUserAction({
      pageProps,
      payload: "CLEAR",
    });
    UpdateAUserStatusAction({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
      <div className="back-to-portal">
        <Navigation to="/app/portal-settings" text="Back to Portal Settings" />
      </div>
      <UserForm pageProps={pageProps} />

      {requestInfo.request_status ===
        appConstantsPortal?.GET_A_USER_SUCCESS && (
        <UpdateUserStatus pageProps={pageProps} />
      )}
    </div>
  );
};

const UpdateUserStatusWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle="ACTIVATE/DEACTIVATE USERS"
      childComponent={<UpdateUserSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAUserAction: adminInAppActions.AdminGetAUser,
  adminUpdateAUserStatusAction: adminInAppActions.AdminUpdateAUserStatus,
};
const mapStateToProps = (state: any) => ({
  adminGetAUserRequest: state.allUGradPortalReducers.adminGetAUserReducer,
  adminUpdateAUserStatusRequest:
    state.allUGradPortalReducers.adminUpdateAUserStatusReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateUserStatusWrap);
