import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { DotLoader } from "react-spinners";
import {
  addOrUpdateExtendStudentUnits,
  getStudentProfile,
} from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
import { adminInAppActions } from "../../../redux/action/portal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
interface ExtendStudentModalProps {
  show: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  getExtendStudentUnitDetails: any;
  studentData: any;
  setStudentData: any;
}

export interface ExtendStudent {
  studentId: string;
  level: string;
  matricNumber: string;
  semester: string;
  session: string;
  additionalUnit: number;
  extendedCourseUniqueId: string;
}

const ExtendStudentModal: React.FC<ExtendStudentModalProps> = ({
  show,
  setShow,
  getExtendStudentUnitDetails,
  studentData,
  setStudentData,
}) => {
  const formData: ExtendStudent = {
    studentId: "",
    matricNumber: "",
    level: "",
    semester: "",
    session: "",
    additionalUnit: 1,
    extendedCourseUniqueId: "",
  };
  const [initialValues, setInitialValues] = useState(formData);

  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

  useEffect(() => {
    fetchCurrentSession();
  }, []);

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];
  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;

  useEffect(() => {
    if (studentData) {
      setInitialValues({
        ...studentData,
        session,
      });
    } else {
      setInitialValues(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setStudentData(formData);
      }}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Extend student unit form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values) => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <ExtendStudentUnitForm
              formikProps={formikProps}
              setShow={setShow}
              getExtendStudentUnitDetails={getExtendStudentUnitDetails}
              session={session}
              setStudentData={setStudentData}
              initialValues={initialValues}
              formData={formData}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const ExtendStudentUnitForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  getExtendStudentUnitDetails: any;
  session: string;
  setStudentData: any;
  initialValues: any;
  formData: any;
}> = ({
  formikProps,
  setShow,
  getExtendStudentUnitDetails,
  session,
  setStudentData,
  initialValues,
  formData,
}) => {
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<ExtendStudent>({
    ...initialValues,
    studentId: values.studentId,
    semester: values.semester,
    additionalUnit: values.additionalUnit,
    extendedCourseUniqueId: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [studentName, setStudentName] = useState("");

  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialValues);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId: studentId, fullName, level } = data?.data;
          setStudentName(fullName);
          setStudent((prevState) => ({
            ...prevState,
            studentId,
            level,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudent(formData);
        }
      });
  };
  useEffect(() => {
    setStudent({
      ...student,
      matricNumber: values.matricNumber,
      semester: values?.semester,
      session,
      additionalUnit: values?.additionalUnit,
      extendedCourseUniqueId: values?.extendedCourseUniqueId,
    });
  }, [values]);
  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
  }, [matricNumber]);

  const handleSubmit = async () => {
    if (!studentName || !student?.level || !student?.additionalUnit) {
      return setError("All fields must be filled correctly.");
    }
    setSubmitting(true);
    await addOrUpdateExtendStudentUnits(student)
      .then(() => {
        setSubmitting(false);
        if (student?.extendedCourseUniqueId) {
          toast.success("Extend unit updated successfully");
        } else {
          toast.success("Extend unit added successfully");
        }
        setShow(false);
        getExtendStudentUnitDetails(1);
        setStudent(formData);
        setStudentData(formData);
      })
      .catch((err) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occured, please try again.");
      });
  };

  const units = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && studentName && matricNumber?.length ? (
        <>
          {" "}
          <p className="student-name">Name: {studentName}</p>
          <p className="student-name">Level: {student?.level}</p>
        </>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        name="matricNumber"
        value={values.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />

      <DropDown
        width="100%"
        name="semester"
        field="semester"
        label="Semester"
        setFieldValue={setFieldValue}
        options={[
          { label: "FIRST SEMESTER", value: "FIRST SEMESTER" },
          { label: "SECOND SEMESTER", value: "SECOND SEMESTER" },
        ]}
        value={{
          value: values.semester,
          label: values.semester,
        }}
        touched={touched}
        errors={errors}
      />
      <DropDown
        width="100%"
        name="session"
        field="session"
        label="Session"
        setFieldValue={setFieldValue}
        options={[
          {
            value: session,
            label: session,
          },
        ]}
        value={{
          value: session,
          label: session,
        }}
        touched={touched}
        errors={errors}
      />
      <DropDown
        width="100%"
        name="additionalUnit"
        field="additionalUnit"
        label="Unit"
        setFieldValue={setFieldValue}
        options={units}
        value={{
          value: values.additionalUnit,
          label: values.additionalUnit,
        }}
        touched={touched}
        errors={errors}
      />

      <div className="button-container">
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
      </div>
    </Form>
  );
};

export default ExtendStudentModal;
