import React, { useEffect } from "react";
import { InAppTemplate } from "../../shared/templates/portal";
import PostUtme from "./PostUtme";
import "./index.scss";
import { getPutmeStudentsLoading } from "../../redux/action/putme";
import { useDispatch } from "react-redux";

const PostUTMEManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPutmeStudentsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <InAppTemplate
      childComponent={<PostUtme />}
      pageTitle="POST UTME DASHBOARD"
    />
  );
};

export default PostUTMEManagement;
