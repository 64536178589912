import { connect } from "react-redux";
import { useEffect } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminResetPassswordActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminResetPassswordAction(payload);
};

const ResetPassawordForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminResetPasswordRequest;

  let checkValidationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Required")
      .when("newPassword", {
        is: (val: string | any[]) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
  });
  let initialValues: any = {
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <div className="student_form">
      {requestInfo.request_status !==
        appConstantsPortal?.RESET_ADMIN_PASSWORD_SUCCESS && (
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            let { confirmPassword, newPassword } = values;
            let payload: any = {
              password: newPassword,
              confirmPassword,
            };

            await adminResetPassswordActionRequest({ pageProps, payload });
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <div
                    className={
                      errors.newPassword && touched.newPassword
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <Field
                      value={values.newPassword}
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      onChange={handleChange("newPassword")}
                      className={` ${
                        errors.newPassword && touched.newPassword
                          ? "is-invalid-input "
                          : !errors.newPassword && values.newPassword !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="newPassword"
                    className="form-input-error"
                    component="div"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <Field
                      value={values.confirmPassword}
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange("confirmPassword")}
                      className={` ${
                        errors.confirmPassword && touched.confirmPassword
                          ? "is-invalid-input "
                          : !errors.confirmPassword &&
                            values.confirmPassword !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    className="form-input-error"
                    component="div"
                  />
                </div>

                {requestInfo.request_status ===
                  appConstantsPortal?.RESET_ADMIN_PASSWORD_FAILURE && (
                  <AlertMsg
                    type="error"
                    message={requestInfo?.request_data.error}
                  />
                )}
                <div className="submit_formcta">
                  <button
                    disabled={requestInfo?.is_request_processing}
                    className="btn shaded"
                    type="submit"
                  >
                    {requestInfo?.is_request_processing
                      ? "Please wait..."
                      : "Update Password"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}

      {requestInfo.request_status ===
        appConstantsPortal?.RESET_ADMIN_PASSWORD_SUCCESS && (
        <AlertMsg type="success" message="Password successfully updated" />
      )}
    </div>
  );
};

const StudentPasswordSettings = ({ pageProps }: any) => {
  useEffect(() => {
    adminResetPassswordActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
      <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <ResetPassawordForm pageProps={pageProps} />
    </div>
  );
};

const AdminPasswordSettingsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`RESET YOUR PASSWORD`}
      childComponent={<StudentPasswordSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminResetPassswordAction: adminInAppActions.AdminResetPasssword,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminResetPasswordRequest:
    state.allUGradPortalReducers.adminResetAdminReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordSettingsWrap);
