import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Excel from "../../../assets/xlsx_icon.png";

const DownloadPassfeedModal = ({
  show,
  setShow,
  downloadUrl,
  programmeType
}: any) => {

  return (
    <Modal
      show={show}
      contentClassName="putme-reports-modal"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="report-main-body">
        <Container>
          <img
            src={Excel}
            alt="pdf"
            className="report-pdf"
          />
          <div className="downloaded-file">
            <img
              src={Excel}
              alt="Downloaded file"
            />
            {
            programmeType === "UTME" ? "Passfeed-Report_.Utme-OLevel_xl" : programmeType === "DE - ALEVEL" ? "Passfeed-Report_.DE-ALevel_xl" : programmeType === "DE - OLEVEL" ? "Passfeed-Report_.DE-0Level_xl" : ""
            }
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
          <a
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
            className="download-btn"
          >
            Download
          </a>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadPassfeedModal;
