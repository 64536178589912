import React from 'react'
import { InAppTemplate } from '../../../shared/templates/portal';
import ReportsCards from './report-cards';

const PutmeReports = () => {
    return (
      <InAppTemplate
        childComponent={<ReportsCards />}
        pageTitle="POST UTME REPORT"
      />
    );
  };

export default PutmeReports
