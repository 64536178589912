import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./index.scss";
import { ChangeEvent, useState } from "react";
import { bulkUploadPostUtmeList } from "../../../redux/action/putme";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropDown from "../../../shared/components/dropdown";

const BulkUploadModal = ({ show, setShow, postUtmesession }: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const { bulkUploadPutmeListLoading } = useSelector(
    (state: any) => state.putmeReducer
  );

  const dispatch = useDispatch();
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const session = postUtmesession;
  // const session = loggedAccountInfo?.currentSession?.session;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      if (selectedFile.size > 1048576) {
        return;
      }
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("session", session);
      formData.append("category", selectedCategory);
      await dispatch(bulkUploadPostUtmeList(formData, setShow));
    }
  };
  return (
    <Modal
      show={show}
      contentClassName="putme-bulk-upload-modal"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h5>BULK UPLOAD</h5>
      </Modal.Header>
      <Modal.Body className="report-main-body">
        <Container>
          <div className="upload-wrap">

      <div className="upload-input">
        <label>SESSION</label>
        <input type="text" value={session} disabled />
      </div>

      <div className="upload-input">
      <label>Category</label>
      <select
            name="Category"
            id="category"
            value={selectedCategory}
            onChange={(e)=> setSelectedCategory(e.target.value)}
          
            >
            <option value="" disabled>Select </option>
            <option value="DE"> DE</option>
            <option value="UTME">UTME</option>
      </select>
      </div>

      </div>

          <input
            type="file"
            className="file-input"
            style={{ cursor: "pointer" }}
            accept=".xls,.xlsx"
            size={1048576}
            onChange={handleFileChange}
          />
          {selectedFile && selectedFile.size > 1048576 ? (
            <p className="input-error">Max file size is 1MB</p>
          ) : null}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="upload-btn"
          onClick={handleUpload}
          disabled={!selectedFile || bulkUploadPutmeListLoading}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkUploadModal;
