import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import { getStates, getStateLgas } from "../../shared/states";

import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAStudentAction(payload);
};
const adminStudentStudentOriginActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminStudentStudentOriginAction(payload);
};

const UpdateStatus = ({ pageProps }: any) => {
  const [stateOfOrigin, setStateOfOrigin] = useState<any>();
  const [stateLgas, setStateLgas] = useState<any[]>([]);
  const [defaultLga, setDefaultLga] = useState<any>();
  let fetchRequestInfo = pageProps.adminGetAStudentRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;
  let requestInfo = pageProps.adminUpdateStudentOriginRequest;
  useEffect(() => {
    if (fetchRequestInfo?.state) {
      let lga = getStateLgas(fetchRequestInfo?.state);
      setStateLgas(lga);
      if (fetchRequestInfo?.lga) {
        setDefaultLga(fetchRequestInfo?.lga.toLowerCase());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (stateOfOrigin) {
      let lga = getStateLgas(stateOfOrigin);
      setStateLgas(lga);
    }
  }, [stateOfOrigin]);

  let allStates = getStates();
  const sortAlphaNum = (a: any, b: any) =>
    a?.localeCompare(b, "en", { numeric: true });
  allStates = allStates.sort(sortAlphaNum);

  // const handleUpdate = (action: any) => {
  //     let payload: any = {
  //         matricNumber: fetchRequestInfo?.matricNumber,
  //         isActive: action == "activate"
  //     }
  //     setActionText(action)
  //     adminStudentStudentOriginActionRequest({ pageProps, payload })
  // }
  let checkValidationSchema = Yup.object().shape({
    stateOfOrigin: Yup.string().required("State of origin is required."),
    lga: Yup.string().required("LGA is required."),
  });
  let initialValues: any = {
    stateOfOrigin: fetchRequestInfo?.state.toLowerCase() || "",
    lga: fetchRequestInfo?.lga.toLowerCase() || defaultLga.toLowerCase(),
  };

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.UPDATE_A_STUDENT_ORIGIN_SUCCESS && (
        <div className="settingform_wrap">
          <span>{fetchRequestInfo?.fullName}</span>

          {/* {!fetchRequestInfo?.isActive && <div className="note">Do you want to <span>Activate</span> <span>{fetchRequestInfo?.fullName}</span> ?</div>}
                    {fetchRequestInfo?.isActive && <div className="note">Do you want to <span>De-Activate</span> <span>{fetchRequestInfo?.fullName}</span> ?</div>}
                    <div className="note">Please note that this student will  {!fetchRequestInfo?.isActive && <span> now have access</span>} {fetchRequestInfo?.isActive && <span> not have access</span>} to the student portal.</div>

                    {fetchRequestInfo?.isActive &&
                        <button className="btn"
                            disabled={requestInfo?.is_request_processing}
                            onClick={() => handleUpdate("deactivate")}> {requestInfo?.is_request_processing ? "Please wait..." : "De-Activate Student"}</button>
                    }
                    {!fetchRequestInfo?.isActive &&
                        <button className="btn"
                            disabled={requestInfo?.is_request_processing}
                            onClick={() => handleUpdate("activate")}> {requestInfo?.is_request_processing ? "Please wait..." : "Activate Student"}</button>
                    } */}

          <div className="student_form regular">
            <Formik
              initialValues={initialValues}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                let { stateOfOrigin, lga } = values;
                let payload: any = {
                  matricNumber: fetchRequestInfo?.matricNumber,
                  state: stateOfOrigin,
                  lga,
                };

                await adminStudentStudentOriginActionRequest({
                  pageProps,
                  payload,
                });
                // await adminGetAStudentRequest({ pageProps, payload })
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  setFieldTouched,
                  setFieldValue,
                  handleChange,
                  // isSubmitting,
                } = props;
                return (
                  <Form>
                    <div className="input_wraps two">
                      <div className="form-group">
                        <label className="label">State of Origin * </label>
                        <div
                          className={
                            errors.stateOfOrigin && touched.stateOfOrigin
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <select
                            name="stateOfOrigin"
                            id="stateOfOrigin"
                            value={values?.stateOfOrigin}
                            onChange={(e: any) => {
                              if (e.target.value) {
                                setFieldTouched("stateOfOrigin", true, true);
                                setFieldValue("stateOfOrigin", e.target.value);
                                setFieldTouched("lga", true, true);
                                setFieldValue("lga", "");
                                setStateOfOrigin(e.target.value);
                              }
                            }}
                            className={`input-wrap ${
                              errors.stateOfOrigin && touched.stateOfOrigin
                                ? "is-invalid-input "
                                : !errors.stateOfOrigin &&
                                  values.stateOfOrigin !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          >
                            <option value="">Select </option>
                            {allStates.map((eachState: any, index: any) => {
                              return (
                                <option
                                  value={eachState.toLowerCase()}
                                  key={index}
                                >
                                  {eachState}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <ErrorMessage
                          name="stateOfOrigin"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Local Govt. Area * </label>
                        <div
                          className={
                            errors.lga && touched.lga
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <select
                            name="lga"
                            id="lga"
                            disabled={!values?.stateOfOrigin}
                            value={values?.lga}
                            onChange={handleChange}
                            className={`input-wrap ${
                              errors.lga && touched.lga
                                ? "is-invalid-input "
                                : !errors.lga && values.lga !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          >
                            <option value="">Select </option>
                            {stateLgas &&
                              stateLgas.map((eachlga: any, index: any) => {
                                return (
                                  <option
                                    value={eachlga.toLowerCase()}
                                    key={index}
                                  >
                                    {eachlga}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <ErrorMessage
                          name="lga"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                    </div>

                    {requestInfo.request_status ===
                      appConstantsPortal?.GET_A_STUDENT_FAILURE && (
                      <AlertMsg
                        type="error"
                        message={requestInfo?.request_data.error}
                      />
                    )}
                    <div className="submit_cta">
                      <button
                        disabled={requestInfo?.is_request_processing}
                        className="btn shaded"
                        type="submit"
                      >
                        {requestInfo?.is_request_processing
                          ? "Please wait..."
                          : "Update Student State of Origin"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
      <div className="student_form">
        {requestInfo.request_status ===
          appConstantsPortal?.UPDATE_A_STUDENT_ORIGIN_FAILURE && (
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        )}

        {requestInfo.request_status ===
          appConstantsPortal?.UPDATE_A_STUDENT_ORIGIN_SUCCESS && (
          <AlertMsg
            type="success"
            message={`State of Origin has been successfully updated for ${fetchRequestInfo?.fullName}`}
          />
        )}
      </div>
    </>
  );
};

const StudentForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;
  let updateStatusRequestInfo = pageProps.adminUpdateStudentOriginRequest;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { matricNumber } = values;
          let payload: any = {
            matricNumber,
          };
          adminStudentStudentOriginActionRequest({
            pageProps,
            payload: "CLEAR",
          });
          await adminGetAStudentRequest({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            // handleBlur,
            handleChange,
            // isSubmitting,
          } = props;
          return (
            <Form>
              <div className="form-group">
                <label className="label">Matric Number </label>
                <div
                  className={
                    errors.matricNumber && touched.matricNumber
                      ? "invalid-input-wrap"
                      : ""
                  }
                >
                  <Field
                    value={values.matricNumber}
                    id="matricNumber"
                    name="matricNumber"
                    type="text"
                    disabled={updateStatusRequestInfo?.is_request_processing}
                    onChange={handleChange("matricNumber")}
                    placeholder="Enter matric number"
                    className={`input-wrap ${
                      errors.matricNumber && touched.matricNumber
                        ? "is-invalid-input "
                        : !errors.matricNumber && values.matricNumber !== ""
                        ? "valid-input"
                        : ""
                    }`}
                  />
                </div>
                <ErrorMessage
                  name="matricNumber"
                  className="form-input-error"
                  component="div"
                />
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_A_STUDENT_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    updateStatusRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search Student"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const UpdateSettingsWrap = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;

  useEffect(() => {
    adminGetAStudentRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminStudentStudentOriginActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
   <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>

      <StudentForm pageProps={pageProps} />

      {requestInfo.request_status ===
        appConstantsPortal?.GET_A_STUDENT_SUCCESS && (
        <UpdateStatus pageProps={pageProps} />
      )}
    </div>
  );
};

const AdminUpdateStudentOriginWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`UPDATE STATE OF ORIGIN`}
      childComponent={<UpdateSettingsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentAction: adminInAppActions.AdminGetAStudent,
  adminStudentStudentOriginAction: adminInAppActions.AdminUpdateStudentOrigin,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminUpdateStudentOriginRequest:
    state.allUGradPortalReducers.adminUpdateStudentOriginReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUpdateStudentOriginWrap);
