import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ReportTable = ({ reportList, loading }: any) => {
  return (
    <Table bordered hover className="eo-report-table">
      <thead>
        <tr>
          <th>MATRIC NUMBER</th>
          <th>FULL NAME</th>
          <th>COURSE CODE</th>
          <th>COURSE TITLE</th>
          <th>UNIT</th>
          <th>SESSION</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(10)].map((_, index) => (
            <tr key={index}>
              {[...Array(6)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : reportList?.length ? (
          reportList?.map((student: any, index: any) => {
            return (
              <tr key={index}>
                <td>{student?.matricNumber}</td>
                <td>{student?.fullName}</td>
                <td>{student?.courseCode}</td>
                <td>{student?.courseTitle}</td>
                <td>{student?.courseUnit}</td>
                <td>{student?.session}</td>
              </tr>
            );
          })
        ) : (
          <p>No results.</p>
        )}
      </tbody>
    </Table>
  );
};

export default ReportTable;
