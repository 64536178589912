import Dash4 from "../../../assets/dash-4.png";


const HostelStatCard = ({ itemName, color, count, title, description }: any) => {
  
    return (
    <div className="putme_stat" style={{ backgroundColor: color }}>
    <div className="stat_icon">
      <img src={Dash4} alt="" />
    </div>
    <div className="stat_values">
      <div className="stat">{title}</div>
      <div className="stat">{description}</div>
      <div className="stat">{itemName}</div>
      <div className="vaalue">
        <h2> {count} </h2>
      </div>
    </div>
  </div>
  )
}

export default HostelStatCard
