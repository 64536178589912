import { useMemo } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { DotLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
import NaijaStates from "naija-state-local-government";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import "./index.scss";
import DropDown from "../../../shared/components/dropdown";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../shared/components/InputField";
import { subjects } from "../../../shared/_helpers/constants";
import { updatePutmeStudent } from "../../../redux/action/putme";

const AddStudentModal = ({
  show,
  setShow,
  initialValues,
  studentInfo,
  setStudentInfo,
  session,
  selectedDepartment,
  updatePutmeLoading,
}: any) => {
  const dispatch = useDispatch();

  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);

  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      }) || [];

  const statesInNigeria = useMemo(
    () =>
      NaijaStates.states().map((state: string) => {
        return { value: state, label: state };
      }),
    []
  );

  let checkValidationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    fullName: Yup.string().required("Required"),
    lga: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    session: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    subject1: Yup.string().required("Required"),
    subject2: Yup.string().required("Required"),
    subject3: Yup.string().required("Required"),
    subject4: Yup.string().required("Required"),
    subjectScore1: Yup.string().required("Required"),
    subjectScore2: Yup.string().required("Required"),
    subjectScore3: Yup.string().required("Required"),
    subjectScore4: Yup.string().required("Required"),
    totalScore: Yup.string().required("Required"),
  });
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setStudentInfo(initialValues);
      }}
      size="xl"
      contentClassName="student-info-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {studentInfo?.registrationNumber && "EDIT STUDENT POST-UTME DATA"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form post-utme-modal-form">
        <Container>
          <Formik
            initialValues={studentInfo}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              await dispatch(
                updatePutmeStudent(
                  values,
                  setShow,
                  setStudentInfo,
                  initialValues
                )
              );
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="registrationNumber"
                        label="Reg. Number"
                        value={values.registrationNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        disabled={!!studentInfo.registrationNumber}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="State"
                        name="state"
                        touched={touched}
                        errors={errors}
                        options={statesInNigeria}
                        field="state"
                        handleChange={handleChange}
                        value={{ value: values.state, label: values.state }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.state,
                          label: values.state,
                        }}
                        width="98%"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="fullName"
                        label="Full Name"
                        value={values.fullName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="L.G.A"
                        name="lga"
                        touched={touched}
                        errors={errors}
                        options={NaijaStates.lgas(
                          values?.state || "Abia"
                        )?.lgas?.map((state: string) => {
                          return { value: state, label: state };
                        })}
                        field="lga"
                        value={{ value: values.lga, label: values.lga }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.lga,
                          label: values.lga,
                        }}
                        width="98%"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="phoneNumber"
                        label="Phone Number"
                        value={values.phoneNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="Category"
                        name="category"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "DE", label: "DE" },
                          { value: "UTME", label: "UTME" },
                        ]}
                        value={{
                          value: values.category,
                          label:
                            values.category === "DE"
                              ? "DE"
                              : values.category === "UTME"
                              ? "UTME"
                              : "",
                        }}
                        defaultValue={{
                          value: values.category,
                          label: values.category,
                        }}
                        field="category"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width="98%"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Course"
                        name="programme"
                        touched={touched}
                        errors={errors}
                        disabled={!values.department}
                        options={programmesList}
                        field="programme"
                        handleChange={handleChange}
                        value={{
                          value: values.programme,
                          label: values.programme,
                        }}
                        defaultValue={{
                          value: values.programme,
                          label: values.programme,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width="98%"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="Session"
                        name="session"
                        touched={touched}
                        errors={errors}
                        options={[{ value: session, label: session }]}
                        field="session"
                        value={{
                          value: values.session,
                          label: values.session,
                        }}
                        defaultValue={{
                          value: values.session,
                          label: values.session,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width="98%"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Sex"
                        name="gender"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "M", label: "Male" },
                          { value: "F", label: "Female" },
                        ]}
                        value={{
                          value: values.gender,
                          label:
                            values.gender === "M"
                              ? "Male"
                              : values.gender === "F"
                              ? "Female"
                              : "",
                        }}
                        defaultValue={{
                          value: values.gender,
                          label: values.gender,
                        }}
                        field="gender"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width="98%"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        label="Subject 1"
                        name="subject1"
                        touched={touched}
                        errors={errors}
                        options={subjects}
                        field="subject1"
                        value={{
                          value: values.subject1,
                          label: values.subject1,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.subject1,
                          label: values.subject1,
                        }}
                        width="98%"
                      />
                    </Col>

                    <Col xs={12} md={2}>
                      <InputField
                        field="subjectScore1"
                        label="Score"
                        value={values.subjectScore1}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="scores"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        label="Subject 2"
                        name="subject2"
                        touched={touched}
                        errors={errors}
                        options={subjects}
                        field="subject2"
                        value={{
                          value: values.subject2,
                          label: values.subject2,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.subject2,
                          label: values.subject2,
                        }}
                        width="98%"
                      />
                    </Col>

                    <Col xs={12} md={2}>
                      <InputField
                        field="subjectScore2"
                        label="Score"
                        value={values.subjectScore2}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="scores"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        label="Subject 3"
                        name="subject3"
                        touched={touched}
                        errors={errors}
                        options={subjects}
                        field="subject3"
                        value={{
                          value: values.subject3,
                          label: values.subject3,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.subject3,
                          label: values.subject3,
                        }}
                        width="98%"
                      />
                    </Col>
                    <Col xs={12} md={2}>
                      <InputField
                        field="subjectScore3"
                        label="Score"
                        value={values.subjectScore3}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="scores"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        label="Subject 4"
                        name="subject"
                        touched={touched}
                        errors={errors}
                        options={subjects}
                        field="subject4"
                        value={{
                          value: values.subject4,
                          label: values.subject4,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.subject4,
                          label: values.subject4,
                        }}
                        width="98%"
                      />
                    </Col>

                    <Col xs={12} md={2}>
                      <InputField
                        field="subjectScore4"
                        label="Score"
                        value={values.subjectScore4}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="scores"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <InputField
                        field="totalScore"
                        label="Total Score"
                        value={values.totalScore}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="scores"
                      />
                    </Col>
                  </Row>

                  <div id="modal-footer" className="modal-footer">
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        setShow(false);
                        setStudentInfo(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="submit-btn"
                      type="submit"
                      //   disabled={addNewStudentLoading}
                    >
                      {updatePutmeLoading ? (
                        <DotLoader
                          color="white"
                          loading={updatePutmeLoading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddStudentModal;
