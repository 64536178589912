import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "react-loading-skeleton/dist/skeleton.css";
import Navigation from "../../../shared/components/navigation/Navigation";
import { getStudentsInRoom } from "../../../redux/action/hostels";
import { history } from "../../../shared/_helpers/history";
import RoomView from "./RoomView";
import "./index.scss";
import AddStudentToRoomModal from "./AddStudent";

const Room = () => {
  const [show, setShow] = useState(false);
  const [studentsInRoom, setStudentsInRoom] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const { roomId, site, block, roomName, blockUniqueId } = location.state || {};
  const roomInfo = {
    roomUniqueId: roomId,
    hostelSite: site,
    blockName: block,
    roomName,
    blockUniqueId,
  };

  const pageCount = Math.ceil(totalCount / 20);

  const roomDetails = async (
    id: any,
    pageNumber: number,
    refetch: boolean = true
  ) => {
    if (refetch) {
      setLoading(true);
    }
    const response = await getStudentsInRoom(id, pageNumber);
    if (response?.status === 200) {
      setStudentsInRoom(response?.data?.items);
      setTotalCount(response?.data.totalCount);
    }
    setLoading(false);
  };

  const handlePageClick = async (event: any) => {
    const newOffset = event.selected + 1;
    await roomDetails(roomId, newOffset);
  };
  useEffect(() => {
    if (!roomId) {
      return history.push("/app/hostels");
    }
    roomDetails(roomId, 1);
  }, [roomId]);
  return (
    <div className="room">
      <AddStudentToRoomModal
        show={show}
        setShow={setShow}
        room={roomInfo}
        fetchStudentsInRoom={roomDetails}
      />
      <div className="back">
        <Navigation to="/app/hostels" text="Back to rooms" />
      </div>
      <div className="room-info">
        <p>
          <strong>Hostel Site</strong>: {site}
        </p>
        <p>
          <strong>Block Name</strong>: {block}
        </p>
        <p>
          <strong>Room Name</strong>: {roomName}
        </p>
      </div>
      <div className="add-section">
        <button onClick={() => setShow(true)}>Add Student</button>
        {/* <h6>VIEW</h6> */}
      </div>

      <RoomView
        studentsInRoom={studentsInRoom}
        loading={loading}
        fetchStudentsInRoom={roomDetails}
      />

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default Room;
