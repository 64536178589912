import {
    adminGetDashboardReducer,
    adminLoadRegMetaReducer,
    adminLoadPresetCourseRegReducer,
    adminSavePresetCourseRegReducer,
    adminDeletePresetCourseRegReducer,
    adminUpdateAStudentCourseOfStudyReducer,
    adminGetAStudentProgramChangeHistoryReducer,
    
    adminGetAllStudentsReducer,
    adminGetAStudentReducer,
    adminUpdateAStudentReducer,
    adminGetBioDataMetaReducer,

    adminGetAStudentInvoiceReducer,
    adminUpdateAStudentInvoiceReducer,

    adminGetAwardsInViewReducer,
    adminAddDeleteAwardInViewReducer,
    adminGetCountriesReducer,
    adminAddDeleteCountryReducer,
    adminGetQualificationsReducer,
    adminAddDeleteQualificationsReducer,
    adminGetSpecialitiesReducer,
    adminAddDeleteSpecialitiesReducer,

    adminGetCurrentSessionReducer,
    adminSetCurrentSessionReducer,

    adminResetPasswordReducer,
    adminResetAdminReducer,
    adminUpdateStatusReducer,
    adminUpdateStudentOriginReducer,

    adminAssignCourseAdvToDeptReducer,
    adminCreateAUserReducer,
    adminUpdateAUserStatusReducer,
    adminCreateARoleReducer,
    adminAddUserToARoleReducer,
    adminGetAllApisReducer,
    adminGetAllUserRolesReducer,
    adminGetAllUsersReducer,
    adminGetAUserReducer,
    adminLoadNewUserMetaReducer,

    adminGetSchoolFeesReportReducer,
    adminDownloadSchoolFeesReportReducer,

    adminGetAcceptanceFeesReportReducer,
    adminDownloadAcceptanceFeesReportReducer,

    adminGetAdditionalFeesReportReducer,
    adminDownloadAdditionalFeesReportReducer,

    adminGetMatriculationReportReducer,
    adminDownloadMatriculationReportsReducer,
    adminGetNewStudentsPaymentsReportReducer,
    adminDownloadNewStudentsPaymentsReducer,
    adminGetStudentsRegistrationReportReducer,
    adminDownloadStudentsRegistrationReportReducer,
    adminGetNelFundReducer,
    adminGetNelFundDownloadReducer,
    printBioDataReducer,
    adminGetEORegistrationReportReducer,
    adminDownloadEORegistrationReportReducer,
    adminUpdateDeferredStatusReducer,
 

    


} from "./inapp-admin"



export const inAppOneReducers = {
    adminGetDashboardReducer,
    adminLoadRegMetaReducer,
    adminLoadPresetCourseRegReducer,
    adminSavePresetCourseRegReducer,
    adminDeletePresetCourseRegReducer,
    adminUpdateAStudentCourseOfStudyReducer,
    adminGetAStudentProgramChangeHistoryReducer,

    adminGetAllStudentsReducer,
    adminGetAStudentReducer,
    adminUpdateAStudentReducer,
    adminGetBioDataMetaReducer,

    adminGetAStudentInvoiceReducer,
    adminUpdateAStudentInvoiceReducer,

    adminGetAwardsInViewReducer,
    adminAddDeleteAwardInViewReducer,
    adminGetCountriesReducer,
    adminAddDeleteCountryReducer,
    adminGetQualificationsReducer,
    adminAddDeleteQualificationsReducer,
    adminGetSpecialitiesReducer,
    adminAddDeleteSpecialitiesReducer,

    adminGetCurrentSessionReducer,
    adminSetCurrentSessionReducer,

    adminResetPasswordReducer,
    adminResetAdminReducer,
    adminUpdateStatusReducer,
    adminUpdateStudentOriginReducer,

    adminAssignCourseAdvToDeptReducer,
    adminCreateAUserReducer,
    adminUpdateAUserStatusReducer,
    adminCreateARoleReducer,
    adminAddUserToARoleReducer,
    adminGetAllApisReducer,
    adminGetAllUserRolesReducer,
    adminGetAllUsersReducer,
    adminGetAUserReducer,
    adminLoadNewUserMetaReducer,

    adminGetSchoolFeesReportReducer,
    adminDownloadSchoolFeesReportReducer,

    adminGetAcceptanceFeesReportReducer,
    adminDownloadAcceptanceFeesReportReducer,

    adminGetAdditionalFeesReportReducer,
    adminDownloadAdditionalFeesReportReducer,

    adminGetMatriculationReportReducer,
    adminDownloadMatriculationReportsReducer,
    adminGetNewStudentsPaymentsReportReducer,
    adminDownloadNewStudentsPaymentsReducer,
    adminGetStudentsRegistrationReportReducer,
    adminDownloadStudentsRegistrationReportReducer,
    adminGetNelFundReducer,
    adminGetNelFundDownloadReducer,
    printBioDataReducer,
    adminGetEORegistrationReportReducer,
    adminDownloadEORegistrationReportReducer,
    adminUpdateDeferredStatusReducer

}