import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { DotLoader } from "react-spinners";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { handleRequestErrors } from "../../../shared/utils";
import { toast } from "react-toastify";
import { getStudentProfile } from "../../../redux/action/admission";
import { addHostelToRoom } from "../../../redux/action/hostels";
const AddStudentForm = ({
  room,
  setShow,
  formikProps,
  initialValues,
  fetchStudentsInRoom,
}: any) => {
  const { values, touched, errors, handleChange } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState(initialValues);

  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleGetStudentProfile = async (matricNo: string) => {
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId: studentId, fullName, level } = data?.data;
          setStudent((prevState: any) => ({
            ...prevState,
            studentId,
            fullName,
            level,
            matricNumber,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        setStudent((prevSate: any) => ({
          ...prevSate,
          fullName: "",
          level: "",
          studentId: "",
        }));
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
        }
      });
  };
  useEffect(() => {
    setStudent({
      ...student,
      blockName: room?.blockName,
      rooomName: room?.roomName,
      roomUniqueId: room?.roomUniqueId,
      blockUniqueId: room?.blockUniqueId,
      hostelSite: room?.hostelSite,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);
  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const handleSubmit = async () => {
    if (!student?.matricNumber || !student?.fullName) {
      return setError("Enter valid matric number");
    }
    setSubmitting(true);
    await addHostelToRoom(student)
      .then((data) => {
        setSubmitting(false);
        toast.success("Student added successfully");
        setShow(false);
        if (fetchStudentsInRoom) {
          fetchStudentsInRoom(room?.roomUniqueId, 1, false);
        }
      })
      .catch((err: any) => {
        setSubmitting(false);
        handleRequestErrors(err);
        if (err?.response.status === 400) {
          toast.error(err?.response.data);
        } else {
          toast.error("An error occured, please try again.");
        }
      });
  };
  return (
    <Form>
      <h6 style={{ textAlign: "center" }}>
        {room?.blockName} {room?.roomName}
      </h6>
      {loading ? <p className="student-name">Searching student...</p> : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        value={values?.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />
      <InputField
        field="name"
        label="Name"
        value={student?.fullName}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        disabled
      />

      <InputField
        field="level"
        label="Level"
        value={student?.level}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        disabled
      />

      <div
        className="add-student-modal-footer"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
      </div>
    </Form>
  );
};

const AddStudentToRoomModal = ({
  show,
  setShow,
  room,
  fetchStudentsInRoom,
}: any) => {
  const initialValues = {
    studentId: "",
    matricNumber: "",
    fullName: "",
    blockName: "",
    rooomName: "",
    roomUniqueId: "",
    blockUniqueId: "",
    level: "",
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add student to room
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={initialValues}
          //   validationSchema={}
          onSubmit={async (values) => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <AddStudentForm
              formikProps={formikProps}
              room={room}
              setShow={setShow}
              fetchStudentsInRoom={fetchStudentsInRoom}
              initialValues={initialValues}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddStudentToRoomModal;
