import { useState } from "react";
import Pencil from "../../assets/pencil.png";
import Delete from "../../assets/delete.png";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import "./index.scss";
import "react-tooltip/dist/react-tooltip.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { deleteCourseSubject } from "../../redux/action/course-subject";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const CourseTable = ({
  courseList,
  setCourseInfo,
  setShow,
  setSelectedDepartment,
}: any) => {
  const { allCoursesLoading } = useSelector(
    (state: any) => state.courseSubjectReducer
  );
  const [showDialog, setShowDialog] = useState(false);
  const [dialogueId, setDialogueId] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async (uniqueId: string) => {
    setLoading(true);
    const res = await dispatch(deleteCourseSubject(uniqueId));
    if (res.status === 200) {
      toast.success("Course deleted successfully");
    }
    setLoading(false);
    setDialogueId("");
  };

  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this item?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogueId)}
          disabled={loading}
        >
          Delete {loading ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Course Title</th>
          <th>Course Code</th>
          <th>Course Unit</th>
          <th>Course Semester</th>
          <th>Course Level</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {allCoursesLoading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(10)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : courseList.length ? (
          courseList?.map((course: any, index: any) => {
            return (
              <tr key={index}>
                <td>{course.courseName}</td>
                <td>{course.courseCode}</td>
                <td>{course.courseUnit}</td>
                <td>{course.semester}</td>
                <td>{course.level}</td>

                <td>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        await setCourseInfo(course);
                        await setSelectedDepartment(course.departmentUniqueId);
                        setShow(true);
                      }}
                    />
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={course?.courseUniqueId === dialogueId && showDialog}
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        onClick={() => {
                          setDialogueId(course?.courseUniqueId);
                          setShowDialog(!showDialog);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "28px",
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );
};

export default CourseTable;
