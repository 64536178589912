import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { InAppTemplate } from "../../shared/templates/portal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Filter from "../../assets/filter.png";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import "./index.scss";
import { levelOptions } from "../../shared/_helpers/constants";
import { getStartAndEndDate } from "../../shared/_helpers/formatDates";
import { getDateFromISO } from "../../shared/utils";
import Navigation from "../../shared/components/navigation/Navigation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSchoolSessionHistory } from "../../redux/action/onboarding";

const adminGetReportRequest = async ({ pageProps, payload, isSearch }: any) => {
  await pageProps.adminGetReportAction(payload, isSearch);
};

const adminLoadSubjectsRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadCourseRegMeta(payload, true);
};

const adminDownloadReportRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminDownloadStudentsRegistrationReportAction(payload);
};

const ReportTable = ({
  reportData,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  setShowFilter,
}: any) => {
  return (
    <>
      <div className="inputWrap">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchQuery}
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
            setShowFilter(false);
          }}
        />
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>
              <span>Student Name</span>
            </th>
            <th>
              <span>Matric Number</span>
            </th>
            <th>
              <span>Gender</span>
            </th>
            <th>Faculty</th>
            <th>Department</th>
            <th>Course of Study</th>
            <th>Level</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {reportData &&
            reportData.map((eachData: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{eachData?.fullName} </td>
                  <td>{eachData?.matricNumber} </td>
                  <td>{eachData?.gender} </td>
                  <td>{eachData?.faculty} </td>
                  <td>{eachData?.department} </td>
                  <td>{eachData?.program} </td>
                  <td>{eachData?.level} </td>
                  <td>{eachData?.state} </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

const LoadRecordsWrap = ({
  pageProps,
  pageSize,
  pageNumber,
  setPageNumber,
  setShouldFetch,
  searchQuery,
  setSearchQuery,
  setShowFilter,
}: any) => {
  let requestData = pageProps?.adminReportRequest?.request_data?.response?.data;
  const handlePageClick = (event: any) => {
    setPageNumber(event?.selected + 1);
    setShouldFetch(true);
  };

  return (
    <div className="records_wrap">
      {requestData?.items && (
        <>
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <ReportTable
              reportData={requestData?.items}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setShouldFetch={setShouldFetch}
              setShowFilter={setShowFilter}
            />
          )}
          {(requestData?.totalCount === 0 ||
            requestData?.items.length === 0) && (
            <div className="empty_record"> No Records found</div>
          )}
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <div className="records_count">
              Showing{" "}
              {requestData?.totalCount > requestData?.items.length
                ? `${requestData?.items.length} of ${requestData?.totalCount}`
                : `${requestData?.items.length}`}{" "}
              result{requestData?.items.length > 1 ? "s" : ""}{" "}
            </div>
          )}
          {requestData?.totalCount > pageSize &&
            requestData.items.length >= 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                // initialPage={1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                forcePage={pageNumber - 1}
                // onClick={handleItemsClick}
                className="pagination_items"
                pageClassName="page_num"
                pageLinkClassName="page_link"
                activeClassName="active_page_link"
                previousClassName="previous_page_link"
                nextClassName="next_page_link"
                pageCount={requestData?.totalPages}
                previousLabel="<"
                // @ts-ignore
                renderOnZeroPageCount={null}
              />
            )}
        </>
      )}
    </div>
  );
};

const RecordFiltersWrap = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userData,
  setSessionFilter,
  sessionFilter,
  isFilterShown,
  setShowFilter,
  setLevelFilter,
  levelFilter,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  shouldFetch,
  pageProps,
  handleDownload,
  programFilter,
  setProgramFilter,
  allPrograms,
  fileType,
  setFileType,
  programName,
  setProgramName,
  requestData,
}: any) => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];
  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));
  let downloadReportRequest = pageProps?.adminDownloadReportRequest;

  let programsList = allPrograms?.response?.allProgrammes || [];
  let list = programsList.map((lists: any) => lists.programmeName);
  const sortList = list.sort();

  if (programsList.length >= 1) {
    programsList = sortList.map((eachProgram: any) => {
      return {
        label: eachProgram,
        value: eachProgram,
      };
    });
  }
  const updatedList = [{ label: "ALL COURSES", value: "All" }, ...programsList];

  const newUpdatedLists = updatedList.filter(
    (lists: any) => !(lists.value === "GENERAL" || lists.value === "-")
  );

  const selectProgramme = (chosenProgramme: any) => {
    setProgramName(chosenProgramme);
    setProgramFilter(chosenProgramme?.value);
  };
  const handleFetch = () => {
    setSearchQuery("");
    setShouldFetch(!shouldFetch);
    setShowFilter(false);
    setProgramName(programName);
  };

  useEffect(() => {
    if (!sessionHistory?.length) {
      dispatch(getSchoolSessionHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFilterShown && (
        <Modal
          contentClassName="common-report-modal"
          className="filter_modal"
          show={isFilterShown}
          onHide={() => {
            setShowFilter(false);
          }}
        >
          <Modal.Body>
            <div className="records_filter_wrap">
              <div className="search_course_of_study">
                <label htmlFor="">Course</label>
                <Select
                  value={programName}
                  onChange={selectProgramme}
                  options={newUpdatedLists}
                  // placeholder="Select course of study"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>

              <div className="search_course_of_study">
                <label htmlFor="">Level</label>
                <Select
                  value={{ label: levelFilter, value: levelFilter }}
                  options={levelOptions}
                  onChange={(option: any) => {
                    setLevelFilter(option.value);
                  }}
                  placeholder="Select level..."
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>
            </div>

            <div className="dates_filters">
              <div className="date_wrap">
                <label htmlFor="">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select start date"
                />
              </div>
              <div className="date_wrap">
                <label htmlFor="">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select end date"
                />
              </div>
            </div>
            <div className="records_filter_wrap">
              <div className="search_course_of_study">
                <label htmlFor="">Session</label>
                <Select
                  options={sessionOptions}
                  value={{ label: sessionFilter, value: sessionFilter }}
                  onChange={(option: any) => {
                    setSessionFilter(option?.value);
                  }}
                  placeholder="Select session..."
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>
            </div>
            <div className="filter_proceed">
              <button className="btn" onClick={handleFetch}>
                Fetch Report
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="fetch_ctas">
        {programName?.value && (
          <div className="filter_summary">
            {sessionFilter} session <span>{programName?.value}</span>{" "}
            {levelFilter}
            level <span>registration report from</span>{" "}
            <span>{getDateFromISO({ date: startDate.toISOString() })}</span> to{" "}
            <span>{getDateFromISO({ date: endDate.toISOString() })}</span>
          </div>
        )}

        <div className="report_filters">
          <div className="ctas">
            <button
              className="btn filter"
              onClick={() => {
                setShowFilter(true);
              }}
            >
              <img src={Filter} alt="" />
              Filter
            </button>

            <Dropdown as={ButtonGroup}>
              <Button
                disabled={
                  downloadReportRequest?.is_request_processing ||
                  !requestData?.items?.length
                }
                onClick={handleDownload}
                variant="success"
                className="download_cta"
              >
                {downloadReportRequest?.is_request_processing
                  ? `Downloading as ${fileType}...`
                  : ` Download as ${fileType} `}
              </Button>

              <Dropdown.Toggle
                disabled={downloadReportRequest?.is_request_processing}
                split
                variant="success"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFileType("CSV")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("EXCEL")}>
                  Excel
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("PDF")}>
                  Pdf
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

const AllRecordsWrap = ({
  pageProps,
  userData,
  defaultStartDate,
  defaultEndDate,
}: any) => {
  const [pageNumber, setPageNumber] = useState(1);

  const [startDate, setStartDate] = useState<any>(defaultStartDate);
  const [endDate, setEndDate] = useState<any>(defaultEndDate);
  const [levelFilter, setLevelFilter] = useState<any>("100");
  const [sessionFilter, setSessionFilter] = useState<any>(
    userData?.currentSession?.session || ""
  );
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [programFilter, setProgramFilter] = useState<any>("");
  const [fileType, setFileType] = useState<any>("CSV");
  const [isFilterShown, setShowFilter] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [downloadPayload, setDownloadPayload] = useState<any>("");
  const [programName, setProgramName] = useState<any>({
    label: "ALL COURSES",
    value: "All",
  });
  const pageSize = 20;
  let requestInfo = pageProps?.adminLoadRegMetaRequest;
  let fetchReportRequest = pageProps?.adminReportRequest;
  let downloadReportRequest = pageProps?.adminDownloadReportRequest;
  let requestData = pageProps?.adminReportRequest?.request_data?.response?.data;

  const generatePayload = (startDate: any, endDate: any) => {
    const { formattedStartDate, formattedEndDate } = getStartAndEndDate(
      startDate,
      endDate
    );
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&CourseOfStudy=${programName?.value}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;

    return payload;
  };

  useEffect(() => {
    adminLoadSubjectsRequest({
      pageProps,
    });
    adminGetReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let payload = generatePayload(startDate, endDate);
    setDownloadPayload(payload);

    if (searchQuery) {
      payload = `${payload}&SearchQuery=${searchQuery}`;
    }
    adminGetReportRequest({
      pageProps,
      payload,
      isSearch: searchQuery.length >= 1,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useEffect(() => {
    let searchDebounce: any;
    let payload = generatePayload(startDate, endDate);
    payload = `${payload}&SearchQuery=${searchQuery}`;
    searchDebounce = setTimeout(() => {
      adminDownloadReportRequest({
        pageProps,
        payload: "CLEAR",
      });
      adminGetReportRequest({
        pageProps,
        payload,
        isSearch: searchQuery.length >= 1,
      });
    }, 1000);
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleDownload = () => {
    let payload = `${downloadPayload}&FileType=${fileType}`;
    adminDownloadReportRequest({
      pageProps,
      payload,
    });
  };

  return (
    <div className="all_reports_wrap">
      <div className="back-to-report">
        <Navigation to="/app/reports" text="Back to Report Dashboard" />
      </div>
      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_PENDING && <LoadingItem />}

      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_FAILURE && (
        <div className="w-50-a">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}

      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_SUCCESS && (
        <>
          <RecordFiltersWrap
            pageProps={pageProps}
            handleDownload={handleDownload}
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
            setLevelFilter={setLevelFilter}
            levelFilter={levelFilter}
            setSessionFilter={setSessionFilter}
            sessionFilter={sessionFilter}
            setFileType={setFileType}
            fileType={fileType}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            userData={userData}
            isFilterShown={isFilterShown}
            setShowFilter={setShowFilter}
            setShouldFetch={setShouldFetch}
            shouldFetch={shouldFetch}
            programFilter={programFilter}
            setProgramFilter={setProgramFilter}
            programName={programName}
            setProgramName={setProgramName}
            allPrograms={requestInfo?.request_data}
            requestData={requestData}
          />

          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_PENDING && (
            <LoadingItem />
          )}
          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_FAILURE && (
            <AlertMsg
              type="error"
              message={fetchReportRequest?.request_data.error}
            />
          )}
          {downloadReportRequest?.request_status ===
            appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE && (
            <AlertMsg
              type="error"
              message={downloadReportRequest?.request_data.error}
            />
          )}

          {downloadReportRequest?.request_status ===
            appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS && (
            <div className="alert-msg success">
              <div className="alert-txt">
                You can now{" "}
                <a
                  className="dl_link"
                  target="_blank"
                  rel="noreferrer"
                  href={downloadReportRequest?.request_data?.response?.data}
                >
                  download the report
                </a>{" "}
              </div>
            </div>
          )}
          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS && (
            <LoadRecordsWrap
              pageProps={pageProps}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setShouldFetch={setShouldFetch}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setShowFilter={setShowFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

const AdminRegistrationReportWrap = (pageProps: any) => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  let defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 7));
  let defaultEndDate = new Date();

  return (
    <InAppTemplate
      pageTitle="GENERATE STUDENTS REGISTRATION REPORT"
      childComponent={
        <AllRecordsWrap
          pageProps={pageProps}
          userData={loggedAccountInfo}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      }
    />
  );
};

const mapDispatchToProps = {
  adminGetReportAction: adminInAppActions.AdminGetStudentsRegistrationReport,
  adminLoadCourseRegMeta: adminInAppActions.AdminLoadCourseRegMeta,
  adminDownloadStudentsRegistrationReportAction:
    adminInAppActions.AdminDownloadStudentsRegistrationReport,
};
const mapStateToProps = (state: any) => ({
  adminReportRequest:
    state.allUGradPortalReducers.adminGetStudentsRegistrationReportReducer,
  adminLoadRegMetaRequest: state.allUGradPortalReducers.adminLoadRegMetaReducer,
  adminDownloadReportRequest:
    state.allUGradPortalReducers.adminDownloadStudentsRegistrationReportReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRegistrationReportWrap);
