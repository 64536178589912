import { InAppTemplate } from "../../../shared/templates/portal";
import StudentUnitExtension from "./StudentUnitExtension";


const CourseUnitExtensionPage = () => {
  return (
    <InAppTemplate
      pageTitle="EXTEND STUDENTS COURSE UNIT"
      childComponent={<StudentUnitExtension />}
    />
  );
};

export default CourseUnitExtensionPage;
