import { useEffect, useState } from "react";
import { getHostelDashboardStat } from "../../../redux/action/hostels";
import "./index.scss";
import { handleRequestErrors } from "../../../shared/utils";

const HostelCard = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHostelDashboardStat();
        if (response.status === 200) {
          setData(response?.data);
        }
      } catch (error) {
        handleRequestErrors(error);
      }
    };
    fetchData();
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="hstl-wrapper">
      {data.map((item: any, index: number) => {
        const randomColor = getRandomColor();
        return (
          <div
            key={index}
            className="tag_values"
            style={{
              backgroundColor: randomColor,
              padding: "20px",
              margin: "10px",
            }}
          >
            <div className="siteName">{item.siteName}</div>
            <div className="items">
              <p>TOTAL-BLOCKS : {item.totalBlocks} </p>
              <p>TOTAL-BEDSPACES : {item.totalBedSpaces} </p>
              <p>ALLOCATED : {item.allocatedBedSpaces} </p>
              <p>UNALLOCATED : {item.unallocatedBedSpaces} </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HostelCard;
