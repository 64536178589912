import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../../../shared/components/searchbox";
import StudentsTable from "./StudentsTable";
import {
  getPostUmeStudents,
  getPostUtmeSession,
  searchPutmeStudents,
} from "../../../redux/action/putme";
import fetchAndDownloadExcel from "../../../shared/_helpers/downloadexcel";
import { ReactComponent as Upload } from "../../../assets/upload-icon.svg";
import ReactPaginate from "react-paginate";
import BulkUploadModal from "./BulkUpload";
import { postUtmeUploadSampleData } from "../../../shared/_helpers/constants";
import { postDEUploadSampleData } from "../../../shared/_helpers/constants";
import Navigation from "../../../shared/components/navigation/Navigation";
import { handleRequestErrors } from "../../../shared/utils";

const PutmeStudents = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [putmeSession, setPutmeSession] = useState("");

  const dispatch = useDispatch();
  const { totalCount, items, PutmeStudentsLoading, updatePutmeLoading } =
    useSelector((state: any) => state.putmeReducer);

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const pageCount = Math.ceil(totalCount / 20);

  const handleSearch = useCallback(
    (searchParam: any, pageSize: number) => {
      dispatch(searchPutmeStudents(searchParam, pageSize));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    if (searchParameter.length) {
      dispatch(handleSearch(searchParameter, newOffset));
    } else {
      dispatch(getPostUmeStudents(newOffset));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getPostUmeStudents(1));
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch, dispatch]);

  useEffect(() => {
    const getPutmeSession = async () => {
      try {
        const response = await getPostUtmeSession();
        if (response.status === 200) {
          setPutmeSession(response?.data.session);
        }
      } catch (error) {
        handleRequestErrors(error);
      }
    };
    getPutmeSession();
  }, []);

  return (
    <>
      <div className="putme-header">
        <div className="back">
          <Navigation to="/app/postutme" text="Back to Post-UTME Dasbhoard" />
        </div>
        <p
          className="putme-upload-template"
          onClick={() => fetchAndDownloadExcel(postUtmeUploadSampleData)}
        >
          NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR POST
          UTME LIST BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE
          COLUMNS (UTME).
        </p>

        <p
          className="putme-upload-template"
          onClick={() => fetchAndDownloadExcel(postDEUploadSampleData)}
        >
          NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR DE LIST
          BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE COLUMNS
          (DE).
        </p>
      </div>
      <div className="admission-action-buttons">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />
        <div
          className="admission-action-button"
          onClick={() => setShowUpload(true)}
        >
          <span>Upload Bulk</span>
          <Upload style={{ width: "20px", height: "20px" }} />
        </div>
      </div>

      <StudentsTable
        PutmeStudentsLoading={PutmeStudentsLoading}
        items={items}
        session={session}
        updatePutmeLoading={updatePutmeLoading}
      />

      {items?.length ? (
        <div className="result-count">
          <p>
            Showing {items?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <BulkUploadModal
        postUtmesession={putmeSession}
        show={showUpload}
        setShow={setShowUpload}
      />

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </>
  );
};

export default PutmeStudents;
