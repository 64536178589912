import { E_DOCUMENTATION_MENU_LIST } from "../../shared/_helpers/constants";
import Card from "../postutme/Card";

const EDocumentationDashboard = () => {
  return (
    <div className="menu-container">
      {E_DOCUMENTATION_MENU_LIST.map((item: any, index: number) => (
        <Card
          key={index}
          title={item.title}
          buttonText={item.buttonText}
          icon={item.icon}
          backgroundColor={item.backgroundColor}
          routeTo={item.routeTo}
        />
      ))}
    </div>
  );
};

export default EDocumentationDashboard;
