import * as XLSX from 'xlsx';

const generateAndDownloadExcel = (data:any) => {
  // Sample data for the Excel sheet
  

  // Create a worksheet
  const ws = XLSX.utils.aoa_to_sheet(data);

  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

  // Convert the workbook to binary Excel file (xlsx)
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Create a Blob from the buffer
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a download link and trigger a click event to download the file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'sample.xlsx';
  link.click();
};


export default generateAndDownloadExcel;
