import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Modal from "react-bootstrap/Modal";
import { parseJwt } from "../../../shared/utils";
import { InAppTemplate } from "../../../shared/templates/portal/";

import UGradCourseAdvisorNewSessionCoursesWrap from "./course-adviser-preset";
import UGradAdminNewSessionCoursesWrap from "./admin-preset";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { adminInAppActions } from "../../../redux/action/portal";
import { appConstantsPortal } from "../../../redux/action-constants/portal";

import DeleteIco from "../../../assets/ic_delete.svg";
import "../index.scss";

const adminLoadSubjectsRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadCourseRegMeta(payload, false);
};

const adminLoadPresetCourseRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadPresetCourseReg(payload);
};

const adminSavePresetCourseRequest = async ({
  pageProps,
  payload,
  isUpdate,
}: any) => {
  await pageProps.adminSavePresetCourseReg(payload, isUpdate);
};

const adminDeletePresetCourseRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminDeletePresetCourseReg(payload);
};

const ConfirmDeleteCourseModal = ({
  courseToDelete,
  setShowDeleteModal,
  isDeleteModalShown,
  allChosenCoursesSubjects,
  setAllChosenCoursesSubjects,
  allData,
  loadedData,
  setAllLoadedSchoolSubjects,
  setAllShownSchoolSubjects,
  pageProps,
  allFectchPresetSubjects,
}: any) => {
  const [isConfirmDelete, setConfirmDelete] = useState<boolean>(false);
  let deletePresetCourseRequest = pageProps?.adminDeletePresetCourseRequest;
  useEffect(() => {
    if (isConfirmDelete) {
      if (courseToDelete?.uniqueId) {
        let payload = `?courseUniqueId=${courseToDelete?.uniqueId}`;
        adminDeletePresetCourseRequest({
          pageProps,
          payload,
        });
      } else {
        let selectedSubjects: any[] = [...allChosenCoursesSubjects];

        let allLoadedSubjects: any[] = loadedData.map((eachItem: any) => {
          let existingData = {
            ...eachItem,
          };
          if (eachItem?.courseCode === courseToDelete?.courseCode) {
            existingData.isSelected = false;
          }

          return {
            ...existingData,
          };
        });
        let displayedDataTemp: any[] = allData.map((eachItem: any) => {
          let existingData = {
            ...eachItem,
          };
          if (eachItem?.courseCode === courseToDelete?.courseCode) {
            existingData.isSelected = false;
          }

          return {
            ...existingData,
          };
        });

        selectedSubjects = selectedSubjects.filter(
          (eachSubject) =>
            eachSubject?.courseCode !== courseToDelete?.courseCode
        );

        setAllShownSchoolSubjects(displayedDataTemp);
        setAllLoadedSchoolSubjects(allLoadedSubjects);
        setAllChosenCoursesSubjects(selectedSubjects);
        setShowDeleteModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmDelete]);

  useEffect(() => {
    if (
      deletePresetCourseRequest?.request_status ===
      appConstantsPortal.DELETE_PRESET_COURSE_REG_SUCCESS
    ) {
      let selectedSubjects: any[] = [...allChosenCoursesSubjects];

      let allLoadedSubjects: any[] = loadedData.map((eachItem: any) => {
        let existingData = {
          ...eachItem,
        };
        if (eachItem?.courseCode === courseToDelete?.courseCode) {
          existingData.isSelected = false;
        }

        return {
          ...existingData,
        };
      });
      let displayedDataTemp: any[] = allData.map((eachItem: any) => {
        let existingData = {
          ...eachItem,
        };
        if (eachItem?.courseCode === courseToDelete?.courseCode) {
          existingData.isSelected = false;
        }

        return {
          ...existingData,
        };
      });

      selectedSubjects = selectedSubjects.filter(
        (eachSubject) => eachSubject?.courseCode !== courseToDelete?.courseCode
      );

      setAllShownSchoolSubjects(displayedDataTemp);
      setAllLoadedSchoolSubjects(allLoadedSubjects);
      setAllChosenCoursesSubjects(selectedSubjects);
      // setShowDeleteModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePresetCourseRequest?.request_status]);

  // setAllChosenCoursesSubjects()
  return (
    <div className="">
      <Modal
        className="delete_modal"
        show={isDeleteModalShown}
        onHide={() => {
          if (!deletePresetCourseRequest?.is_request_processing) {
            adminDeletePresetCourseRequest({
              pageProps,
              payload: "CLEAR",
            });
            setShowDeleteModal(false);
          }
        }}
      >
        <Modal.Body>
          {deletePresetCourseRequest?.request_status !==
            appConstantsPortal?.DELETE_PRESET_COURSE_REG_SUCCESS && (
            <>
              <div className="delete_confirmation_message">
                Please confirm you want to remove{" "}
                <span>
                  ({courseToDelete?.courseCode}) {courseToDelete?.courseTitle}{" "}
                </span>{" "}
                from <span>{courseToDelete?.programme} </span>{" "}
                <span>{courseToDelete?.studentsLevel} level</span> courses
              </div>

              {deletePresetCourseRequest?.request_status ===
                appConstantsPortal?.DELETE_PRESET_COURSE_REG_FAILURE && (
                <div className="w-50-a">
                  <AlertMsg
                    type="error"
                    message={deletePresetCourseRequest?.request_data.error}
                  />
                </div>
              )}
              <div className="cta_actions">
                <button
                  disabled={deletePresetCourseRequest?.is_request_processing}
                  className="btn cancel"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={deletePresetCourseRequest?.is_request_processing}
                  className="btn confirm"
                  onClick={() => setConfirmDelete(true)}
                >
                  {deletePresetCourseRequest?.is_request_processing
                    ? "Deleting Course..."
                    : "Confirm"}
                </button>
              </div>
            </>
          )}
          {deletePresetCourseRequest?.request_status ===
            appConstantsPortal?.DELETE_PRESET_COURSE_REG_SUCCESS && (
            <div className="">
              <AlertMsg
                type="success"
                message={`${courseToDelete?.courseTitle} (${courseToDelete?.courseCode}) has been removed from saved courses for ${courseToDelete?.programme} ${courseToDelete?.studentsLevel} level.`}
              />
              <div className="other_msg">
                Please inform {courseToDelete?.programme}{" "}
                {courseToDelete?.studentsLevel} level students to remove (
                {courseToDelete?.courseCode}) {courseToDelete?.courseTitle} from
                their registered courses{" "}
              </div>
              <div className="cta_actions">
                <button
                  className="btn confirm"
                  onClick={() => {
                    adminDeletePresetCourseRequest({
                      pageProps,
                      payload: "CLEAR",
                    });
                    setShowDeleteModal(false);
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const updateSelectedCourses = (
  loadedData: any[],
  allShownSchoolSubjects: any[],
  setAllShownSchoolSubjects: any,
  setAllLoadedSchoolSubjects: any,
  selectedCourse: any,
  action: string,
  setCoursesChosen: any,
  chosenCourses: any[],
  modeSelected: string,
  chosenDept: any,
  chosenFaculty: any,
  chosenProgramme: any,
  chosenLevel: string,
  setAllChosenCoursesSubjects: any,
  allChosenCoursesSubjects: any[],
  pageProps: any
) => {
  let allLoadedSubjects = loadedData;
  let updatedLoadedSubjects: any[] = [...allChosenCoursesSubjects];

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  let displayedDataTemp: any[] = allShownSchoolSubjects.map((eachItem) => {
    let existingData = {
      ...eachItem,
    };
    if (eachItem?.courseUniqueId === selectedCourse?.courseUniqueId) {
      if (action) {
        existingData.isSelected = true;
      } else {
        existingData.isSelected = false;
      }
      existingData.modeSelected = modeSelected;
    }

    return {
      ...existingData,
    };

    // return {
    //     ...eachItem,
    //     isSelected: eachItem?.courseUniqueId == selectedCourse?.courseUniqueId && action ? true : false
    // }
  });

  let loadedDataTemp = allLoadedSubjects.map((eachItem) => {
    // return {
    //     ...eachItem,
    //     isSelected: eachItem?.courseUniqueId == selectedCourse?.courseUniqueId && action ? true : false
    // }
    let existingData = {
      ...eachItem,
    };
    if (eachItem?.courseUniqueId === selectedCourse?.courseUniqueId) {
      if (action) {
        existingData.isSelected = true;
      } else {
        existingData.isSelected = false;
      }
      existingData.modeSelected = modeSelected;
    }

    return {
      ...existingData,
    };
  });

  let newSelected = {
    courseCode: selectedCourse?.courseCode,
    courseUnit: selectedCourse?.courseUnit,
    courseTitle: selectedCourse?.courseName,
    isCore: modeSelected.toLowerCase() === "core" ? true : false,
    isElective: modeSelected.toLowerCase() === "elective" ? true : false,
    semester: selectedCourse?.semester,
    level: selectedCourse?.level.toString(),
    session: loggedAccountInfo?.currentSession?.session,
    programme: chosenProgramme?.label,
    // createdBy: "string",
    studentsLevel: chosenLevel,
    department: chosenDept?.label,
    faculty: chosenFaculty?.label,
    createdBy: pageProps?.adminLoginRequest?.request_data?.username,
    uniqueId: "",
  };
  if (action) {
    if (
      updatedLoadedSubjects.findIndex(
        (eachSubject: any) =>
          eachSubject?.studentsLevel === newSelected?.studentsLevel &&
          eachSubject?.courseTitle === newSelected?.courseTitle &&
          eachSubject?.programme === newSelected?.programme &&
          eachSubject?.courseCode === newSelected?.courseCode
      ) === -1
    ) {
      updatedLoadedSubjects.push(newSelected);
    }
  } else {
    updatedLoadedSubjects = updatedLoadedSubjects.filter(
      (eachSubject) => eachSubject?.courseCode !== newSelected?.courseCode
    );
  }

  setAllChosenCoursesSubjects(updatedLoadedSubjects);
  setAllLoadedSchoolSubjects(loadedDataTemp);
  setAllShownSchoolSubjects(displayedDataTemp);
};

const RenderEachCourse = ({
  courseData,
  index,
  loadedData,
  allShownSchoolSubjects,
  setAllShownSchoolSubjects,
  setAllLoadedSchoolSubjects,
  setCoursesChosen,
  chosenCourses,
  chosenDept,
  chosenFaculty,
  chosenProgramme,
  chosenLevel,
  setAllChosenCoursesSubjects,
  allChosenCoursesSubjects,
  pageProps,
}: any) => {
  const [modeSelected, setModeSelected] = useState<any>(
    courseData?.modeSelected || ""
  );
  const isCourseAdded = "no";

  return (
    <tr>
      <td>
        {!modeSelected && !courseData?.modeSelected && (
          <Tooltip id="mode-tooltip" />
        )}
        {/* <div data-tooltip-id="mode-tooltip" data-tooltip-content="select if coourse is core/elective!"> </div> */}
        <div
          data-tooltip-id={
            !modeSelected && !courseData?.modeSelected ? "mode-tooltip" : ""
          }
          data-tooltip-content={
            !modeSelected && !courseData?.modeSelected
              ? "Select if course is core/elective!"
              : ""
          }
          className={
            modeSelected ||
            courseData?.modeSelected === "Core" ||
            courseData?.modeSelected === "Elective"
              ? "code_tick"
              : "code_tick choose_mode"
          }
          // title={modeSelected ? "" : "select if coourse is core/elective"}
        >
          <input
            disabled={modeSelected || courseData?.modeSelected ? false : true}
            value={courseData?.isSelected ? isCourseAdded : ""}
            checked={courseData?.isSelected}
            type="checkbox"
            name={courseData?.courseUniqueId}
            id={courseData?.courseUniqueId}
            onChange={(e: any) => {
              updateSelectedCourses(
                loadedData,
                allShownSchoolSubjects,
                setAllShownSchoolSubjects,
                setAllLoadedSchoolSubjects,
                courseData,
                e.target.checked,
                setCoursesChosen,
                chosenCourses,
                modeSelected,
                chosenDept,
                chosenFaculty,
                chosenProgramme,
                chosenLevel,
                setAllChosenCoursesSubjects,
                allChosenCoursesSubjects,
                pageProps
              );
            }}
          />
          <label htmlFor={courseData?.courseUniqueId}>
            {courseData?.courseCode}
          </label>
        </div>
      </td>
      <td>
        <div className="course_title">{courseData?.courseName}</div>
      </td>
      <td>
        <div className="course_title">{courseData?.courseUnit}</div>
      </td>
      <td>
        <div className="core_elective">
          <select
            key={`mode-${courseData?.courseUniqueId}`}
            value={modeSelected || courseData?.modeSelected}
            name={`mode-${courseData?.courseUniqueId}`}
            id={`mode-${courseData?.courseUniqueId}`}
            onChange={(e: any) => {
              if (e.target.value) {
                setModeSelected(e.target.value);
              }
            }}
          >
            <option value="">Choose</option>
            <option value="Core">Core</option>
            <option value="Elective">Elective</option>
          </select>
        </div>
      </td>
      <td>
        <div className="course_semester">
          {courseData?.semester.split(" ")[0]}
        </div>
      </td>
    </tr>
  );
};

const RenderAllChosenCourses = ({
  pageProps,
  setAllChosenCoursesSubjects,
  allFectchPresetSubjects,
  allChosenCoursesSubjects,
  allData,
  loadedData,
  setAllLoadedSchoolSubjects,
  setAllShownSchoolSubjects,
}: any) => {
  const [isDeleteModalShown, setShowDeleteModal] = useState<boolean>(false);
  const [courseToDelete, setCourseToDelete] = useState<any>();
  let coursesSorted: any[] = [...allChosenCoursesSubjects];
  const sortAlphaNum = (a: any, b: any) =>
    a?.courseCode.localeCompare(b?.courseCode, "en", { numeric: true });
  coursesSorted = coursesSorted.sort(sortAlphaNum);

  // useEffect(()=>{
  //     if(isDeleteModalShown)

  // },[isDeleteModalShown])

  return (
    <>
      {isDeleteModalShown && (
        <ConfirmDeleteCourseModal
          courseToDelete={courseToDelete}
          isDeleteModalShown={isDeleteModalShown}
          setAllChosenCoursesSubjects={setAllChosenCoursesSubjects}
          allChosenCoursesSubjects={allChosenCoursesSubjects}
          setShowDeleteModal={setShowDeleteModal}
          loadedData={loadedData}
          setAllLoadedSchoolSubjects={setAllLoadedSchoolSubjects}
          allData={allData}
          setAllShownSchoolSubjects={setAllShownSchoolSubjects}
          allFectchPresetSubjects={allFectchPresetSubjects}
          pageProps={pageProps}
        />
      )}

      <Table hover>
        <thead>
          <tr>
            <th>Course Code</th>
            <th> Title</th>
            <th>Unit</th>
            <th>Core/Elective</th>
            <th>Semester</th>
          </tr>
        </thead>
        <tbody>
          {coursesSorted.map((courseData: any, index: any) => {
            return (
              <tr key={index}>
                <td>{courseData?.courseCode}</td>
                <td>{courseData?.courseTitle}</td>
                <td>{courseData?.courseUnit}</td>
                <td>
                  {courseData?.isCore ? "Core" : ""}
                  {courseData?.isElective ? "Elective" : ""}
                </td>
                <td>
                  <div className="action_elem">
                    <div>{courseData?.semester.split(" ")[0]}</div>

                    <button
                      className="btn delete_course"
                      onClick={() => {
                        adminDeletePresetCourseRequest({
                          pageProps,
                          payload: "CLEAR",
                        });
                        setCourseToDelete(courseData);
                        setShowDeleteModal(true);
                      }}
                    >
                      <img src={DeleteIco} alt="" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
const DisplayPaginatedCourses = ({
  allFectchPresetSubjects,
  currentItems,
  loadedData,
  setCoursesChosen,
  chosenCourses,
  allShownSchoolSubjects,
  setAllShownSchoolSubjects,
  setAllLoadedSchoolSubjects,
  chosenDept,
  chosenFaculty,
  chosenProgramme,
  chosenLevel,
  setAllChosenCoursesSubjects,
  allChosenCoursesSubjects,
  pageProps,
}: any) => {
  let schoolSubjectsToShow = currentItems;

  return (
    <>
      <Table hover>
        {/* </Table> */}
        {/* <table> */}
        <thead>
          <tr>
            <th>Course Code</th>
            <th> Title</th>
            <th>Unit</th>
            <th>Core/Elective</th>
            <th>Semester</th>
          </tr>
        </thead>
        <tbody>
          {schoolSubjectsToShow.map((eachData: any, index: any) => {
            return (
              // <div key={index}>

              <RenderEachCourse
                key={`${index}-${eachData?.courseUniqueId}`}
                courseData={eachData}
                loadedData={loadedData}
                allShownSchoolSubjects={allShownSchoolSubjects}
                setAllShownSchoolSubjects={setAllShownSchoolSubjects}
                setAllLoadedSchoolSubjects={setAllLoadedSchoolSubjects}
                setCoursesChosen={setCoursesChosen}
                chosenCourses={chosenCourses}
                index={index}
                chosenDept={chosenDept}
                chosenFaculty={chosenFaculty}
                chosenProgramme={chosenProgramme}
                chosenLevel={chosenLevel}
                setAllChosenCoursesSubjects={setAllChosenCoursesSubjects}
                allChosenCoursesSubjects={allChosenCoursesSubjects}
                pageProps={pageProps}
              />
              // </div>
              // <tr key={index}>
              //     <td>
              //         <div className="code_tick">
              //             <input checked={eachData?.isSelected} type="checkbox" name={eachData?.courseUniqueId} id={eachData?.courseUniqueId}
              //                 onChange={(e: any) => {
              //                     updateSelectedCourses(loadedData?.allSubjects, allShownSchoolSubjects, setAllShownSchoolSubjects, setAllLoadedSchoolSubjects, eachData, e.target.checked, setCoursesChosen, chosenCourses)
              //                 }}

              //             />
              //             <label htmlFor={eachData?.courseUniqueId}>{eachData?.courseCode}</label>
              //         </div>
              //     </td>
              //     <td>
              //         <div className="course_title">{eachData?.courseName}</div>
              //     </td>
              //     <td>
              //         <div className="course_title">{eachData?.courseUnit}</div>
              //     </td>
              //     <td>
              //         <div className="core_elective">
              //             <select name={`mode-${eachData?.courseUniqueId}`} id={`mode-${eachData?.courseUniqueId}`}>
              //                 <option value="Core">Core</option>
              //                 <option value="Elective">Elective</option>
              //             </select>
              //         </div>
              //     </td>
              //     <td>
              //         <div className="course_semester">
              //             {eachData?.semester.split(" ")[0]}
              //         </div>
              //     </td>
              // </tr>
            );
          })}
        </tbody>
        {/* </table> */}
      </Table>
    </>
  );
};

const PaginateAllCoursesList = ({
  itemsPerPage,
  allData,
  allFectchPresetSubjects,
  loadedData,
  setCoursesChosen,
  chosenCourses,
  setAllShownSchoolSubjects,
  setAllLoadedSchoolSubjects,
  chosenDept,
  chosenFaculty,
  chosenProgramme,
  chosenLevel,
  setAllChosenCoursesSubjects,
  allChosenCoursesSubjects,
  pageProps,
}: any) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allData.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <DisplayPaginatedCourses
        allFectchPresetSubjects={allFectchPresetSubjects}
        currentItems={currentItems}
        allShownSchoolSubjects={allData}
        loadedData={loadedData}
        setAllLoadedSchoolSubjects={setAllLoadedSchoolSubjects}
        setCoursesChosen={setCoursesChosen}
        chosenCourses={chosenCourses}
        setAllShownSchoolSubjects={setAllShownSchoolSubjects}
        chosenDept={chosenDept}
        chosenFaculty={chosenFaculty}
        chosenProgramme={chosenProgramme}
        chosenLevel={chosenLevel}
        setAllChosenCoursesSubjects={setAllChosenCoursesSubjects}
        allChosenCoursesSubjects={allChosenCoursesSubjects}
        pageProps={pageProps}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </>
  );
};

// eslint-disable-next-line
const LoadCourseWrap = ({ pageProps, requestInfo, session }: any) => {
  let allData = requestInfo?.request_data?.response;
  let presetCourseRequest = pageProps?.adminLoadPresetCourseRegRequest;
  let savePresetCourseRequest = pageProps?.adminSavePresetCourseRegRequest;

  const [allLoadedSchoolSubjects, setAllLoadedSchoolSubjects] = useState<any[]>(
    allData?.allSubjects
  );
  const [allShownSchoolSubjects, setAllShownSchoolSubjects] = useState<any[]>(
    allData?.allSubjects
  );
  const [chosenCourses, setCoursesChosen] = useState<any[]>([]);
  const [allDeptsInFaculty, setAllDeptsInFaculty] = useState<any[]>([]);
  const [chosenLevel, setChosenLevel] = useState<any>();
  const [chosenDept, setChosenDept] = useState<any>();
  const [searchedSubject, setSearchedSubject] = useState<string>("");
  const [chosenFaculty, setChosenFaculty] = useState<any>();
  const [chosenProgramme, setChosenProgramme] = useState<any>();
  // const [menuToOPen, setMenuToOPen] = useState<any>("faculty");
  const [allProgInDept, setAllProgInDept] = useState<any[]>([]);
  const [allFectchPresetSubjects, setAllFectchPresetSubjects] = useState<any[]>(
    []
  );
  const [allChosenCoursesSubjects, setAllChosenCoursesSubjects] = useState<
    any[]
  >([]);
  const [isProceedWithSavedCourses, setProceedWithSavedCourses] =
    useState<boolean>(false);
  const [isConfirmedSavedCourses, setConfirmSavedCourses] =
    useState<boolean>(false);

  const [isLoadUploadedCourse, setLoadUploadedCourse] =
    useState<boolean>(false);

  useEffect(() => {
    adminLoadPresetCourseRequest({
      pageProps,
      payload: "CLEAR",
    });

    adminSavePresetCourseRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminDeletePresetCourseRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProceedWithSavedCourses) {
      adminSavePresetCourseRequest({
        pageProps,
        payload: "CLEAR",
      });
      setConfirmSavedCourses(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProceedWithSavedCourses]);

  useEffect(() => {
    if (
      presetCourseRequest?.request_status ===
      appConstantsPortal.LOAD_PRESET_COURSE_REG_SUCCESS
    ) {
      let allPresetCourses: any[] =
        presetCourseRequest?.request_data?.response?.data;
      setAllFectchPresetSubjects(allPresetCourses);
      allPresetCourses = allPresetCourses.map((eachCourse) => {
        let eachData = {
          courseCode: eachCourse?.courseCode,
          courseUnit: eachCourse?.courseUnit,
          courseTitle: eachCourse?.courseTitle,
          isCore: eachCourse?.isCore,
          isElective: eachCourse?.isElective,
          semester: eachCourse?.semester,
          level: eachCourse?.level,
          session: eachCourse?.session,
          programme: eachCourse?.programme,
          studentsLevel: eachCourse?.studentsLevel,
          department: eachCourse?.department,
          faculty: eachCourse?.faculty,
          createdBy: eachCourse?.createdBy,
          uniqueId: eachCourse?.uniqueId,
        };

        return eachData;
      });

      let allSubjectsFetched: any[] = allLoadedSchoolSubjects.map(
        (eachData) => {
          let existingData = {
            ...eachData,
          };
          if (
            allPresetCourses.findIndex(
              (eachSubject: any) =>
                eachSubject?.courseTitle === eachData?.courseName &&
                eachSubject?.courseCode === eachData?.courseCode &&
                eachSubject?.level === eachData?.level
            ) > -1
          ) {
            existingData.isSelected = true;

            allPresetCourses.forEach((eachPreset) => {
              if (
                eachPreset?.courseTitle === eachData?.courseName &&
                eachPreset?.courseCode === eachData?.courseCode &&
                eachPreset?.level === eachData?.level
              ) {
                if (eachPreset?.isCore) {
                  existingData.modeSelected = "Core";
                }
                if (eachPreset?.isElective) {
                  existingData.modeSelected = "Elective";
                }
              }
            });
          }

          return existingData;
        }
      );

      setAllLoadedSchoolSubjects(allSubjectsFetched);

      setAllChosenCoursesSubjects(allPresetCourses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetCourseRequest?.request_status]);

  useEffect(() => {
    if (
      savePresetCourseRequest?.request_status ===
      appConstantsPortal.SAVE_PRESET_COURSE_REG_FAILURE
    ) {
      setConfirmSavedCourses(false);
    }

    if (
      savePresetCourseRequest?.request_status ===
      appConstantsPortal.SAVE_PRESET_COURSE_REG_SUCCESS
    ) {
      setLoadUploadedCourse(false);
    }
  }, [savePresetCourseRequest?.request_status]);

  useEffect(() => {
    if (isConfirmedSavedCourses) {
      // let allPresetCourses: any[] = presetCourseRequest?.request_data?.response?.data;
      let payload: any = allChosenCoursesSubjects;

      if (presetCourseRequest?.request_data?.response?.data?.length >= 1) {
        let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        payload = {
          level: chosenLevel,
          session: loggedAccountInfo?.currentSession?.session,
          programme: chosenProgramme?.label,
          coursePreSelectViewModels: allChosenCoursesSubjects,
        };
      }

      adminSavePresetCourseRequest({
        pageProps,
        payload,
        isUpdate:
          presetCourseRequest?.request_data?.response?.data?.length >= 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmedSavedCourses]);

  useEffect(() => {
    if (searchedSubject.trim().length >= 3) {
      setProceedWithSavedCourses(false);
      let subjectsList = allLoadedSchoolSubjects;
      let searchResults: any[] = [];

      subjectsList.forEach((eachSubject: any) => {
        if (
          eachSubject?.courseName
            .toLowerCase()
            .includes(searchedSubject.toLowerCase())
        ) {
          searchResults.push(eachSubject);
        }
      });
      setAllShownSchoolSubjects(searchResults);
    }
    if (searchedSubject.trim().length < 3) {
      setProceedWithSavedCourses(false);
      setAllShownSchoolSubjects(allLoadedSchoolSubjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedSubject]);

  useEffect(() => {
    if (chosenFaculty?.value) {
      let allDept: any[] = [];
      setChosenLevel("");
      setChosenProgramme("");
      setChosenDept("");
      allData.allDeptAndFaculty.forEach((eachData: any) => {
        if (
          allDept.findIndex(
            (dept: any) => dept?.value === eachData?.departmentUniqueId
          ) === -1 &&
          eachData?.faculty?.facultyUniqueId === chosenFaculty?.value
        ) {
          allDept.push({
            value: eachData?.departmentUniqueId,
            label: eachData?.departmentName,
          });
        }
      });
      setAllDeptsInFaculty(allDept);
      // setMenuToOPen("dept");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFaculty]);

  useEffect(() => {
    if (chosenDept?.value) {
      setChosenLevel("");
      setChosenProgramme("");
      let allCourseOfStudy: any = [];
      allData.allProgrammes.forEach((eachData: any) => {
        if (
          allCourseOfStudy.findIndex(
            (course: any) => course?.value === eachData?.programmeUniqueId
          ) === -1 &&
          eachData?.departmentUniqueId === chosenDept?.value
        ) {
          allCourseOfStudy.push({
            value: eachData?.programmeUniqueId,
            label: eachData?.programmeName,
          });
        }
      });
      setAllProgInDept(allCourseOfStudy);
      // setMenuToOPen("program");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDept]);

  useEffect(() => {
    if (chosenDept?.value) {
      setChosenLevel("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProgramme]);

  useEffect(() => {
    setSearchedSubject("");
    if (chosenProgramme?.label && chosenLevel) {
      let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
      loggedAccountInfo = JSON.parse(loggedAccountInfo);
      let payload = {
        level: chosenLevel,
        session: loggedAccountInfo?.currentSession?.session,
        programme: chosenProgramme?.label,
      };

      if (presetCourseRequest?.request_data?.response?.data?.length >= 1) {
        let loadedDataTemp =
          requestInfo?.request_data?.response?.allSubjects.map(
            (eachItem: any) => {
              let existingData = {
                ...eachItem,
                isSelected: false,
                modeSelected: "",
              };

              return {
                ...existingData,
              };
            }
          );

        let allSubjectsList = allData?.allSubjects.map((eachItem: any) => {
          let existingData = {
            ...eachItem,
            isSelected: false,
            modeSelected: "",
          };

          return {
            ...existingData,
          };
        });
        setAllLoadedSchoolSubjects(loadedDataTemp);
        setAllShownSchoolSubjects(allSubjectsList);
      }

      adminLoadPresetCourseRequest({
        pageProps,
        payload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenLevel]);

  useEffect(() => {
    if (isLoadUploadedCourse) {
      let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
      loggedAccountInfo = JSON.parse(loggedAccountInfo);
      let payload = {
        level: chosenLevel,
        session: loggedAccountInfo?.currentSession?.session,
        programme: chosenProgramme?.label,
      };
      adminLoadPresetCourseRequest({
        pageProps,
        payload,
      });
      adminSavePresetCourseRequest({
        pageProps,
        payload: "CLEAR",
      });
      setProceedWithSavedCourses(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadUploadedCourse]);

  let allFaculties: any = [];

  allData.allDeptAndFaculty.forEach((eachData: any) => {
    if (
      allFaculties.findIndex(
        (faculty: any) => faculty?.value === eachData?.faculty?.facultyUniqueId
      ) === -1
    ) {
      allFaculties.push({
        value: eachData?.faculty?.facultyUniqueId,
        label: eachData?.faculty?.facultyName,
      });
    }
  });

  const saveDept = (selectedDept: any) => {
    setChosenDept(selectedDept);
    // setChosenProgramme({})
  };
  const saveFaculty = (selectedFaculty: any) => {
    setChosenFaculty(selectedFaculty);
    // setChosenDept({})
  };

  const saveProgramme = (chosenProgramme: any) => {
    setChosenProgramme(chosenProgramme);
    // setMenuToOPen("");
  };

  return (
    <div className="course_preload_wrap">
      {savePresetCourseRequest?.request_status !==
        appConstantsPortal?.SAVE_PRESET_COURSE_REG_SUCCESS && (
        <>
          <div className="heading_items">
            <div className="search_courses">
              <input
                type="text"
                placeholder="Search course"
                value={searchedSubject}
                onChange={(e: any) => {
                  setSearchedSubject(e.target.value);
                }}
              />
            </div>
            <div className="search_faculty">
              <Select
                // menuIsOpen={menuToOPen === "faculty" ? true : false}
                value={chosenFaculty}
                id="chosenFaculty"
                openMenuOnClick={true}
                onChange={saveFaculty}
                options={allFaculties}
                placeholder="Select faculty"
                className="select-wrap"
                classNamePrefix="faculty-select"
              />
            </div>
            <div className="search_dept">
              <Select
                // menuIsOpen={menuToOPen === "dept" ? true : false}
                value={chosenDept}
                onChange={saveDept}
                options={allDeptsInFaculty}
                isDisabled={!chosenFaculty?.value}
                placeholder="Select department"
                className="select-wrap"
                classNamePrefix="dept-select"
              />
            </div>
            <div className="search_course_of_study">
              <Select
                // menuIsOpen={menuToOPen === "program" ? true : false}
                value={chosenProgramme}
                onChange={saveProgramme}
                options={allProgInDept}
                isDisabled={!chosenDept?.value}
                placeholder="Select course of study"
                className="select-wrap"
                classNamePrefix="course-select"
              />
            </div>
            <div className="all_levels">
              <label htmlFor="">Level</label>
              <select
                name="level"
                id=""
                value={chosenLevel}
                disabled={!chosenProgramme?.value}
                onChange={(e: any) => {
                  if (e.target.value) {
                    setChosenLevel(e.target.value);
                  }
                }}
              >
                <option value=""></option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
              </select>
            </div>
          </div>
          {presetCourseRequest?.request_status ===
            appConstantsPortal?.LOAD_PRESET_COURSE_REG_PENDING && (
            <div className="select_all_msm">
              <LoadingItem />
            </div>
          )}
          {presetCourseRequest?.request_status ===
            appConstantsPortal?.LOAD_PRESET_COURSE_REG_FAILURE && (
            <div className="w-50-a">
              <AlertMsg
                type="error"
                message={presetCourseRequest?.request_data.error}
              />
            </div>
          )}
          {chosenFaculty &&
            chosenDept &&
            chosenProgramme &&
            chosenLevel &&
            presetCourseRequest?.request_status ===
              appConstantsPortal?.LOAD_PRESET_COURSE_REG_SUCCESS && (
              <div className="all_courses_wrap">
                {!isProceedWithSavedCourses && (
                  <div className="all_school_subjects">
                    <div className="heading_txt">
                      Select Courses for the Session by ticking the box
                    </div>
                    {allLoadedSchoolSubjects.length >= 1 &&
                      searchedSubject.length < 3 && (
                        <PaginateAllCoursesList
                          setAllShownSchoolSubjects={setAllShownSchoolSubjects}
                          setAllLoadedSchoolSubjects={
                            setAllLoadedSchoolSubjects
                          }
                          // loadedData={requestInfo?.request_data?.response}
                          allFectchPresetSubjects={allFectchPresetSubjects}
                          loadedData={allLoadedSchoolSubjects}
                          itemsPerPage={15}
                          chosenDept={chosenDept}
                          chosenFaculty={chosenFaculty}
                          chosenProgramme={chosenProgramme}
                          chosenLevel={chosenLevel}
                          setCoursesChosen={setCoursesChosen}
                          chosenCourses={chosenCourses}
                          allData={allLoadedSchoolSubjects}
                          setAllChosenCoursesSubjects={
                            setAllChosenCoursesSubjects
                          }
                          allChosenCoursesSubjects={allChosenCoursesSubjects}
                          pageProps={pageProps}
                        />
                      )}

                    {allShownSchoolSubjects.length >= 1 &&
                      searchedSubject.length >= 3 && (
                        <PaginateAllCoursesList
                          setAllShownSchoolSubjects={setAllShownSchoolSubjects}
                          setAllLoadedSchoolSubjects={
                            setAllLoadedSchoolSubjects
                          }
                          // loadedData={pageProps?.request_data?.response}
                          allFectchPresetSubjects={allFectchPresetSubjects}
                          loadedData={allLoadedSchoolSubjects}
                          itemsPerPage={15}
                          chosenDept={chosenDept}
                          chosenFaculty={chosenFaculty}
                          chosenProgramme={chosenProgramme}
                          chosenLevel={chosenLevel}
                          setCoursesChosen={setCoursesChosen}
                          chosenCourses={chosenCourses}
                          allData={allShownSchoolSubjects}
                          setAllChosenCoursesSubjects={
                            setAllChosenCoursesSubjects
                          }
                          allChosenCoursesSubjects={allChosenCoursesSubjects}
                          pageProps={pageProps}
                        />
                      )}
                    {allShownSchoolSubjects.length === 0 &&
                      searchedSubject.length >= 3 && (
                        <div className="empty_data">No Courses found</div>
                      )}
                  </div>
                )}
                {allChosenCoursesSubjects.length >= 1 && (
                  <div
                    className={
                      isProceedWithSavedCourses
                        ? "all_selected_subjects has_preview"
                        : "all_selected_subjects"
                    }
                  >
                    <div className="heading_txt">
                      {isProceedWithSavedCourses
                        ? "Confirm Courses to be registered for the Session"
                        : "Selected Courses for the Session"}
                      {isProceedWithSavedCourses && (
                        <button
                          className="btn back_nav"
                          onClick={() => {
                            setProceedWithSavedCourses(false);
                            setConfirmSavedCourses(false);
                          }}
                        >
                          &lt;&lt; Back
                        </button>
                      )}
                    </div>
                    <RenderAllChosenCourses
                      pageProps={pageProps}
                      loadedData={allLoadedSchoolSubjects}
                      allFectchPresetSubjects={allFectchPresetSubjects}
                      setAllLoadedSchoolSubjects={setAllLoadedSchoolSubjects}
                      allData={allShownSchoolSubjects}
                      setAllShownSchoolSubjects={setAllShownSchoolSubjects}
                      allChosenCoursesSubjects={allChosenCoursesSubjects}
                      setAllChosenCoursesSubjects={setAllChosenCoursesSubjects}
                    />
                    {!isProceedWithSavedCourses && (
                      <div className="proceed_with_cta">
                        <button
                          className="btn"
                          onClick={() => {
                            setProceedWithSavedCourses(true);
                          }}
                        >
                          Proceed
                        </button>
                      </div>
                    )}
                    {isProceedWithSavedCourses && (
                      <>
                        <div className="proceed_with_cta">
                          <button
                            className="btn back_nav"
                            onClick={() => {
                              setProceedWithSavedCourses(false);
                            }}
                          >
                            &lt;&lt; Back
                          </button>
                          <button
                            disabled={
                              savePresetCourseRequest?.is_request_processing
                            }
                            className="btn"
                            onClick={() => setConfirmSavedCourses(true)}
                          >
                            {savePresetCourseRequest?.is_request_processing
                              ? "Uploading Courses..."
                              : "Upload Courses"}
                          </button>
                        </div>
                        {savePresetCourseRequest?.request_status ===
                          appConstantsPortal?.SAVE_PRESET_COURSE_REG_FAILURE && (
                          <div className="w-50-a">
                            <AlertMsg
                              type="error"
                              message={
                                savePresetCourseRequest?.request_data.error
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          {(!chosenFaculty ||
            !chosenDept ||
            !chosenProgramme ||
            !chosenLevel) && (
            <div className="select_all_msg">
              Please select <span>Faculty</span>, <span>Department</span>,{" "}
              <span>Course Of Study</span> and <span>Level </span>to Proceed
            </div>
          )}
        </>
      )}

      {savePresetCourseRequest?.request_status ===
        appConstantsPortal?.SAVE_PRESET_COURSE_REG_SUCCESS && (
        <div className="courses_loaded_success">
          <div className="success-message-wrap">
            <div className="success-animation">
              <svg
                className="success_icon_tick"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <div className="success-message-txt">
              {" "}
              <span>{chosenProgramme?.label}</span> department Courses{" "}
              <span>{chosenLevel} level</span> have been succesfully uploaded
              for <span>{session} session</span>{" "}
            </div>
            <div className="product-cta">
              <button
                onClick={() => setLoadUploadedCourse(true)}
                className="btn"
              >
                See all uploaded courses
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const NoDeptFound = ({ pageProps, loggedAccountInfo }: any) => {
  const Message = ({ pageProps }: any) => {
    return (
      <div className="courses_wrap">
        <div className="main_content">
          <div className="justify-center">
            <AlertMsg type="error" message="No Department Assigned to you" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <InAppTemplate
      childComponent={<Message pageProps={pageProps} />}
      pageTitle={`SET COURSE REGISTRATION FOR THE
    ${loggedAccountInfo?.currentSession?.session} SESSION`}
    />
  );
};

const CoursesDisplay = ({ pageProps }: any) => {
  // let requestInfo = pageProps?.adminLoadRegMetaRequest;
  // let savePresetCourseRequest = pageProps?.adminSavePresetCourseRegRequest;
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  let decodedToken: any;
  let userRoles: any[] = loggedAccountInfo?.profile?.userRoles || [];

  let allowedRoles = ["Admin", "Course_Advisor"] || [];
  userRoles = userRoles.filter((c: any, index: any) => {
    return userRoles.indexOf(c) === index;
  });
  if (loggedAccountInfo?.access_token) {
    decodedToken = parseJwt(loggedAccountInfo?.access_token);

    for (let index = 0; index < userRoles.length; index++) {
      const element = userRoles[index];
      if (allowedRoles.includes(element)) {
        break;
      }
    }
  }

  useEffect(() => {
    // adminLoadSubjectsRequest({
    //   pageProps,
    // });

    adminLoadSubjectsRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(decodedToken?.role === "Course_Advisor" ||
        userRoles.includes("Course_Advisor")) &&
        loggedAccountInfo?.profile?.departmentName && (
          // <div className="courses_wrap">

          //   <div className="main_content">
          //     {requestInfo?.request_status ===
          //       appConstantsPortal?.FETCH_COURSE_REG_META_PENDING && <LoadingItem />}
          //     {requestInfo?.request_status ===
          //       appConstantsPortal?.FETCH_COURSE_REG_META_FAILURE && (
          //         <div className="w-50-a">
          //           <AlertMsg type="error" message={requestInfo?.request_data.error} />
          //         </div>
          //       )}
          //     {requestInfo?.request_status ===
          //       appConstantsPortal?.FETCH_COURSE_REG_META_SUCCESS && (
          //         <LoadCourseWrap
          //           session={loggedAccountInfo?.currentSession?.session}
          //           pageProps={pageProps}
          //           requestInfo={requestInfo}
          //         />
          //       )}
          //   </div>
          // </div>
          <UGradCourseAdvisorNewSessionCoursesWrap />
        )}
      {(decodedToken?.role === "Admin" ||
        decodedToken?.role === "AdminView" ||
        userRoles.includes("Admin") ||
        userRoles.includes("Course_Presetter")) && (
        <UGradAdminNewSessionCoursesWrap />
      )}

      {(decodedToken?.role === "Course_Advisor" ||
        userRoles.includes("Course_Advisor")) &&
        !loggedAccountInfo?.profile?.departmentName && (
          <NoDeptFound
            pageProps={pageProps}
            loggedAccountInfo={loggedAccountInfo}
          />
        )}
    </>
  );
};

const UGradNewSessionCoursesWrap = (pageProps: any) => {
  return <CoursesDisplay pageProps={pageProps} />;
  // return (
  //   <InAppTemplate
  //     childComponent={<CoursesDisplay pageProps={pageProps} />}
  //     pageTitle={`Set Course Registration for the
  //   ${loggedAccountInfo?.currentSession?.session} Session`}
  //   />
  // );
};

const mapDispatchToProps = {
  adminLoadCourseRegMeta: adminInAppActions.AdminLoadCourseRegMeta,
  adminLoadPresetCourseReg: adminInAppActions.AdminLoadPresetCourseReg,
  adminSavePresetCourseReg: adminInAppActions.AdminSavePresetCourseReg,
  adminDeletePresetCourseReg: adminInAppActions.AdminDeletePresetCourseReg,
};
const mapStateToProps = (state: any) => ({
  adminLoadRegMetaRequest: state.allUGradPortalReducers.adminLoadRegMetaReducer,
  adminLoadPresetCourseRegRequest:
    state.allUGradPortalReducers.adminLoadPresetCourseRegReducer,
  adminSavePresetCourseRegRequest:
    state.allUGradPortalReducers.adminSavePresetCourseRegReducer,
  adminDeletePresetCourseRequest:
    state.allUGradPortalReducers.adminDeletePresetCourseRegReducer,

  adminLoginRequest: state.allOnboardingReducers.adminLogin,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UGradNewSessionCoursesWrap);
