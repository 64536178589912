import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import AllAddtionalPayment from "./AllAddtionalPayment";

const AdditionalFeePayment = () => {
  return (
    <InAppTemplate
      pageTitle="ADDITIONAL FEE PAYMENT"
      childComponent={<AllAddtionalPayment />}
    />
  );
};

export default AdditionalFeePayment;
