import { connect } from "react-redux";
import { useEffect } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../shared/components/alert-msg";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAStudentAction(payload, true);
};

const SuccessfulCourseUpdate = ({ request, studentData }: any) => {
  return (
    <div className="update_success">
      <div className="success-message-wrap">
        <div className="success-animation">
          <svg
            className="success_icon_tick"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="success-message-txt">
          {" "}
          The course of study for {studentData?.response?.data?.fullName} has
          been successfully updated. Please see the new matric Number here{" "}
          {request?.request_data?.response?.data}
        </div>
      </div>
    </div>
  );
};

const UpdateStatus = ({ pageProps }: any) => {
  let fetchRequestInfo = pageProps.adminGetAStudentRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;
  let requestInfo = pageProps.adminUpdateStudentOriginRequest;

  let initialValues: any = {};

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS && (
        <div className="settingform_wrap full-width">
          <span>{fetchRequestInfo?.fullName}</span>

          <div className="student_form regular">
            <Formik initialValues={initialValues} onSubmit={() => {}}>
              {(props: FormikProps<any>) => {
                return (
                  <Form>
                    <Table hover className="info_table" bordered={true}>
                      <thead>
                        <tr>
                          <th>Old Matric Number </th>
                          <th>Old Course of Study </th>
                          <th>New Matric Number</th>
                          <th>New Course of Study</th>
                          <th>Date Changed</th>
                          <th>Changed By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetchRequestInfo &&
                          fetchRequestInfo.map((eachData: any, index: any) => {
                            return (
                              <tr>
                                <td>{eachData?.oldMatricNumber}</td>
                                <td>{eachData?.oldProgramme}</td>
                                <td>{eachData?.newMatricNumber}</td>
                                <td>{eachData?.newProgramme}</td>
                                <td>{eachData?.dateChanged}</td>
                                <td>{eachData?.changedBy}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
      <div className="student_form"></div>
    </>
  );
};

const StudentForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;
  let updateStatusRequestInfo = pageProps.adminUpdateStudentOriginRequest;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { matricNumber } = values;
          let payload: any = {
            matricNumber,
          };

          await adminGetAStudentActionRequest({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, touched, errors, handleChange } = props;
          return (
            <Form>
              <div className="form-group">
                <label className="label">Matric Number </label>
                <div
                  className={
                    errors.matricNumber && touched.matricNumber
                      ? "invalid-input-wrap"
                      : ""
                  }
                >
                  <Field
                    value={values.matricNumber}
                    id="matricNumber"
                    name="matricNumber"
                    type="text"
                    disabled={updateStatusRequestInfo?.is_request_processing}
                    onChange={handleChange("matricNumber")}
                    placeholder="Enter matric number"
                    className={`input-wrap ${
                      errors.matricNumber && touched.matricNumber
                        ? "is-invalid-input "
                        : !errors.matricNumber && values.matricNumber !== ""
                        ? "valid-input"
                        : ""
                    }`}
                  />
                </div>
                <ErrorMessage
                  name="matricNumber"
                  className="form-input-error"
                  component="div"
                />
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_A_STUDENT_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    updateStatusRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search Student"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const UpdateSettingsWrap = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;
  let updateRequestInfo = pageProps.adminUpdateStudentOriginRequest;

  useEffect(() => {
    adminGetAStudentActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      {updateRequestInfo.request_status !==
        appConstantsPortal?.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS && (
        <>
          <StudentForm pageProps={pageProps} />

          {requestInfo.request_status ===
            appConstantsPortal?.GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS && (
            <UpdateStatus pageProps={pageProps} />
          )}
        </>
      )}
      {updateRequestInfo.request_status ===
        appConstantsPortal?.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS && (
        <SuccessfulCourseUpdate
          request={updateRequestInfo}
          studentData={requestInfo?.request_data}
        />
      )}
    </div>
  );
};

const AdminStudentCourseOfStudyChangeHistoryWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`CHECK STUDENT MATRIC NO. HISTORY`}
      childComponent={<UpdateSettingsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentAction: adminInAppActions.AdminGetAllMatricNumberHistory,
  adminStudentStudentCouseOfStudyAction:
    adminInAppActions.AdminUpdateStudentCourseOfStudy,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest:
    state.allUGradPortalReducers.adminGetAStudentProgramChangeHistoryReducer,
  adminUpdateStudentOriginRequest:
    state.allUGradPortalReducers.adminUpdateAStudentCourseOfStudyReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminStudentCourseOfStudyChangeHistoryWrap);
