import { Table } from "react-bootstrap";
import "./index.scss";
import { DotLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
import Skeleton from "react-loading-skeleton";

const ReportSearchTable = ({ items, show, handleSubmit, loading }: any) => {
  const fileType = "Excel";

  return (
    <div className="download-wrapper">
      <div className="inputWrap">
        <div className="report-submit-btn">
          <Button
            disabled={loading}
            type="button"
            variant="success"
            className="download_cta"
            onClick={handleSubmit}
          >
            {loading ? (
              <DotLoader
                color="white"
                loading={loading}
                size={30}
                aria-label="Submitting"
              />
            ) : (
              `Download as ${fileType}`
            )}
          </Button>
        </div>
      </div>
      {show && (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Matric Number</th>
              <th>Site Name</th>
              <th>Block Name</th>
              <th>Room No</th>
              <th>Level</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {items.length === 0
              ? [...Array(1)].map((_, index) => (
                  <tr key={index}>
                    {[...Array(6)].map((_, index) => (
                      <td key={index}>
                        <Skeleton height={20} />
                      </td>
                    ))}
                  </tr>
                ))
              : items.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{item.fullName}</td>
                      <td>{item.matricNumber}</td>
                      <td>{item.hostelSite}</td>
                      <td>{item.blockName}</td>
                      <td>{item.roomName}</td>
                      <td>{item.level}</td>
                      <td>{item.hasPaid}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ReportSearchTable;
