import {
    adminLoginReducer,
    adminForgotPwReducer,
    adminVerifyForgotPwCodeReducer,
    adminUpdateNewPasswordeReducer  
} from "./admin-onboarding"



export const onboardingReducers = {
    adminLoginReducer,
    adminForgotPwReducer,
    adminVerifyForgotPwCodeReducer,
    adminUpdateNewPasswordeReducer 
}