import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import "./index.scss";
import { toast } from "react-toastify";
import DropDown from "../../../../shared/components/dropdown";
import Navigation from "../../../../shared/components/navigation/Navigation";
import {
  allHostelRooms,
  getHostelSiteBlock,
  getStudentInRoom,
  getStudentInRoomSearch,
} from "../../../../redux/action/hostels";
import { DotLoader } from "react-spinners";
import SearchRoomTable from "./SearchRoomTable";

const SearchRoom = () => {
  const initialValues = {
    hostelSite: "",
    blockName: "",
    roomName: "",
    blockUniqueId: "",
    roomUniqueId: "",
  };

  const [hostelData, setHostelData] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [allRooms, setAllRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const getHostelSites = async () => {
    try {
      const response = await getHostelSiteBlock();
      if (response?.data) {
        setHostelData(response.data);
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const getAllHostelRooms = async () => {
    try {
      const response = await allHostelRooms();
      if (response?.data) {
        setAllRooms(response.data);
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  useEffect(() => {
    getHostelSites();
    getAllHostelRooms();
  }, []);

  const [searchTerm, setSearchTerm] = useState<any>("");
  const siteList = hostelData
    .map((site: any) => ({
      label: site?.siteName,
      value: site?.siteName,
    }))
    .filter(
      (el: any, index: number, arr: any) =>
        index === arr.findIndex((x: any) => x.value === el.value)
    );

  const selectedBlockDetails: any = useMemo(
    () =>
      hostelData.find((el: any) => {
        return el.hostelBlocks?.blockUniqueId === selectedBlock;
      }),
    [selectedBlock, hostelData]
  );

  const blockList = useMemo(
    () =>
      hostelData
        .filter((el: any) => el.siteName === selectedSite)
        .map((block: any) => ({
          label: block?.hostelBlocks?.blockName,
          value: block?.hostelBlocks?.blockUniqueId,
        })),
    [hostelData, selectedSite]
  );

  const roomList: any = useMemo(
    () =>
      allRooms
        .filter((el: any) => {
          return el.blockUniqueId === selectedBlock;
        })
        .map((block: any) => ({
          label: block?.roomName,
          value: block?.roomUniqueId,
        })),
    [allRooms, selectedBlock]
  );

  const selectedRoomDetails: any = useMemo(
    () =>
      allRooms.find((el: any) => {
        return el?.roomUniqueId === selectedRoom;
      }),
    [allRooms, selectedRoom]
  );

  let roomUniqueId = selectedRoomDetails?.roomUniqueId;

  const fetchData = useCallback(
    async (pageNumber: number) => {
      try {
        if (searchTerm) {
          setSearchLoading(true);
          const response = await getStudentInRoomSearch(
            roomUniqueId,
            searchTerm,
            pageNumber
          );
          setSearchLoading(false);
          if (response?.data?.totalCount > 0) {
            setData(response.data.items);
          } else if (response?.data?.totalCount === 0) {
            toast.error("No record found");
            setData([]);
          }
        } else if (shouldFetch) {
          const roomsResponse = await getStudentInRoom(roomUniqueId);
          if (roomsResponse?.data?.items?.length) {
            setLoading(false);
            setData(roomsResponse.data.items);
          }
        }
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData(1);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [fetchData]);

  let checkValidationSchema = Yup.object().shape({
    hostelSite: Yup.string().required("Required"),
    blockName: Yup.string().required("Required"),
    roomName: Yup.string().required("Required"),
  });

  return (
    <div>
      <div className="report-container">
        <div className="back">
          <Navigation to="/app/hostel/reports" text="Back to Hostel Reports" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            setLoading(true);
            setShouldFetch(true);
            try {
              const response = await getStudentInRoom(roomUniqueId);
              if (response.data?.totalCount > 0) {
                setData(response?.data?.items);
              } else if (response?.data?.totalCount === 0) {
                toast.error("No record found");
                setData([]);
              }
            } catch (error) {
              toast.error("Failed to fetch records");
            } finally {
              setLoading(false);
            }
          }}
        >
          {(props: FormikProps<any>) => {
            const { values, touched, errors, setFieldValue, setFieldTouched } =
              props;

            return (
              <Form>
                <div className="search-container">
                  <div className="room-search-dropdown">

                
                    <DropDown
                      field="hostelSite"
                      label="Site Name"
                      touched={touched}
                      errors={errors}
                      options={siteList}
                      value={{
                        value: values?.hostelSite,
                        label: values?.hostelSite,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      handleChange={setSelectedSite}
                      width={"97%"}
                    />
           

            
                    <DropDown
                      className="dropdown-style"
                      field="blockName"
                      label="Block Name"
                      touched={touched}
                      errors={errors}
                      options={blockList}
                      value={{
                        value: selectedBlockDetails?.hostelBlocks?.blockName,
                        label: selectedBlockDetails?.hostelBlocks?.blockName,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      handleChange={setSelectedBlock}
                      width={"97%"}
                    />
                

             
                    <DropDown
                      className="dropdown-style"
                      field="roomName"
                      label="Room Name"
                      touched={touched}
                      errors={errors}
                      options={roomList}
                      value={{
                        value: selectedRoomDetails?.roomName,
                        label: selectedRoomDetails?.roomName,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      handleChange={setSelectedRoom}
                      width={"97%"}
                    />
              

                  </div>

                  <div className="modal-footer">
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="search-room-table">
        {data.length > 0 && (
          <SearchRoomTable
            items={data}
            loading={searchLoading}
            roomUniqueId={roomUniqueId}
            setItems={setData}
            setSearchTerm={setSearchTerm}
          />
        )}
      </div>
    </div>
  );
};

export default SearchRoom;
