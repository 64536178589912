import { useCallback, useEffect, useState } from "react";
import AdmissionTable from "./AdmissionTable";
import SearchBox from "../../shared/components/searchbox";
import { ReactComponent as Upload } from "../../assets/upload-icon.svg";
import { CiCirclePlus } from "react-icons/ci";
import "./index.scss";
import ReactPaginate from "react-paginate";
import StudentModal from "./StudentModal";
import fetchAndDownloadExcel from "../../shared/_helpers/downloadexcel";
import StudentsUpload from "./StudentsUpload";
import { useDispatch, useSelector } from "react-redux";
import { getAdmissionList, searchStudent } from "../../redux/action/admission";
import { admissionSampleData } from "../../shared/_helpers/constants";

const AdmissionContent = () => {
  const initialValues = {
    registrationNumber: "",
    state: "",
    fullName: "",
    lga: "",
    faculty: "",
    nationality: "",
    totalScore: "",
    department: "",
    isNotApproved: "",
    notApprovedReason: "",
    sex: "",
    programme: "",
    levelOfEntry: "",
    session: "",
    matricNumberFormation: "",
  };
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [studentInfo, setStudentInfo] = useState<any>(initialValues);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedFaculty, setSelectedFaculty] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [searchParameter, setSearchParameter] = useState<string>("");
  const { items, totalCount } = useSelector(
    (state: any) => state.admissionReducer
  );

  const dispatch = useDispatch();
  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const pageCount = Math.ceil(totalCount / 20);

  const handleSearch = useCallback(
    (searchParam: any, pageSize: number) => {
      dispatch(searchStudent(searchParam, pageSize));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    if (searchParameter.length) {
      dispatch(searchStudent(searchParameter, newOffset));
    } else {
      dispatch(getAdmissionList(newOffset, session));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getAdmissionList(1, session));
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch, dispatch, session]);

  return (
    <div className="admission-management">
      <p
        className="admission-upload-template"
        onClick={() => fetchAndDownloadExcel(admissionSampleData)}
      >
        NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR ADMISSION
        BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE COLUMNS.
      </p>
      <div className="admission-action-buttons">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />
        <div className="admission-action-button" onClick={() => setShow(true)}>
          {" "}
          <span>Add Single Student</span>
          <CiCirclePlus style={{ width: "20px", height: "20px" }} />
        </div>
        <div
          className="admission-action-button"
          onClick={() => setShowUpload(true)}
        >
          <span>Upload Bulk</span>
          <Upload style={{ width: "20px", height: "20px" }} />
        </div>
      </div>
      <AdmissionTable
        admissionList={items}
        setStudentInfo={setStudentInfo}
        setShow={setShow}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedFaculty={selectedFaculty}
        setSelectedFaculty={setSelectedFaculty}
      />
      {items?.length ? (
        <div className="result-count">
          <p>
            Showing {items?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <StudentsUpload show={showUpload} setShow={setShowUpload} />
      <StudentModal
        show={show}
        setShow={setShow}
        studentInfo={studentInfo}
        setStudentInfo={setStudentInfo}
        initialValues={initialValues}
        session={session}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedFaculty={selectedFaculty}
        setSelectedFaculty={setSelectedFaculty}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default AdmissionContent;
