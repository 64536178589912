import { Course } from "../../../modules/EO-reports/EO-report/EOReport";
import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";

export const courseRegistrationSearch = async (course: string) => {
  return await ApiService.request({
    url: `${routes.COURSE_REG_SEARCH}?searchQuery=${course}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};

export const fetchCourseReport = async (
  course: Course,
  pageNumber: number,
  session: string
) => {
  const encodedCourseTitle = encodeURIComponent(course.courseTitle);
  return await ApiService.request({
    url: `${routes.COURSE_REG_REPORT}?courseCode=${course.courseCode}&courseTitle=${encodedCourseTitle}&courseUnit=${course?.courseUnit}&session=${session}&pageSize=20&pageNumber=${pageNumber}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};

export const downloadEOReport = async (
  course: Course,
  pageNumber: number,
  session: string,
  fileType: string
) => {
  const encodedCourseTitle = encodeURIComponent(course.courseTitle);
  return await ApiService.request({
    url: `${routes.COURSE_REG_REPORT_DOWNLOAD}?courseCode=${course.courseCode}&courseUnit=${course?.courseUnit}&courseTitle=${encodedCourseTitle}&session=${session}&pageSize=20&pageNumber=${pageNumber}&fileType=${fileType}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};
