import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Excel from "../../../../assets/xlsx_icon.png";

const DownloadHostelReportModal = ({
  showDownloadModal,
  setShowDownloadModal,
  downloadUrl,
  dateString
}: any) => {


  return (
    <Modal
      show={showDownloadModal}
      contentClassName="putme-reports-modal"
      onHide={() => setShowDownloadModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="report-main-body">
        <Container>
          <img
            src={Excel}
            alt="pdf"
            className="report-pdf"
          />
          <div className="downloaded-file">
            <img
              src={Excel}
              alt="Downloaded file"
            />
           hostel-report_{dateString} 
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
          <a
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
            className="download-btn"
          >
            Download
          </a>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadHostelReportModal;