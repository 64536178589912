import Done from "../../../assets/Done.png";
import Cancel from "../../../assets/Cancel.png";

const ListOfUploads = ({
  documents,
  activeDocument,
  setActiveDocument,
}: any) => {
  return (
    <div className="docs-list">
      {documents?.map((document: any) => (
        <div
          className={
            activeDocument?.documentType === document?.documentType
              ? "active-document document-type-update"
              : "document-type-update"
          }
          key={document?.documentType}
          onClick={() => setActiveDocument(document)}
        >
          <p>{document?.documentType}</p>
          <img
            src={document.status === "APPROVED" ? Done : Cancel}
            alt="done"
          />
        </div>
      ))}
    </div>
  );
};

export default ListOfUploads;
