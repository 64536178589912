import { ErrorMessage, Field } from "formik";

const InputField = ({
  errors,
  touched,
  value,
  label,
  field,
  type,
  component,
  setFieldValue,
  handleChange,
  disabled = false,
  mandatory= true
}: any) => {
   
  return (
    <div className="form-group">
      <label className="label">{label}{mandatory ? "*" : ""}</label>
      <div
        className={errors[field] && touched[field] ? "invalid-input-wrap" : ""}
      >
        <Field
          value={value}
          id={field}
          name={field}
          type={type}
          component={component || null}
          onChange={handleChange(field)}
          className={`input-wrap ${
            errors[field] && touched[field]
              ? "is-invalid-input "
              : !errors[field] && value !== ""
              ? "valid-input"
              : ""
          }`}
          disabled={disabled}
        />
      </div>
      <ErrorMessage name={field} className="form-input-error" component="div" />
    </div>
  );
};

export default InputField;
