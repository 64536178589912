import { ToastContainer } from 'react-toastify';
import { InAppTemplate } from '../../../shared/templates/portal';
import AllocationManagement from './AllocationManagement';


const HostelAllocation = () => {
    return (
      <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<AllocationManagement />}
        pageTitle="ALLOCATION MANAGEMENT"
      />
      </>
    );
  };

export default HostelAllocation;