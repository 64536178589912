import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

const SearchRoomTable = ({
  items,
  setSearchTerm,
  loading,
  searchTerm,
}: any) => {
  return (
    <div className="report-wrapper">
      <div className="inputWrap room-input">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
      </div>

      <Table bordered hover>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Matric Number</th>
            <th>Site Name</th>
            <th>Block Name</th>
            <th>Room No</th>
            <th>Level</th>
            <th>Paid</th>
          </tr>
        </thead>
        <tbody>
          {loading || items.length === 0
            ? [...Array(1)].map((_, index) => (
                <tr key={index}>
                  {[...Array(6)].map((_, index) => (
                    <td key={index}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            : items.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.fullName}</td>
                  <td>{item.matricNumber}</td>
                  <td>{item.hostelSite}</td>
                  <td>{item.blockName}</td>
                  <td>{item.roomName}</td>
                  <td>{item.level}</td>
                  <td>{item.hasPaid}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SearchRoomTable;
