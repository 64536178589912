import React from "react";
import SideBar from "../../components/sidebar";
import AppHeader from "../../components/header";
import { ChildrenCompProps } from "../../../types";
import { useWindowSize } from "../../../hooks/useWindowSize";
import "./index.scss";

export const InAppTemplate: React.FC<ChildrenCompProps> = (props) => {
  const screenResolution: any = useWindowSize();
  const { childComponent, noSideBar, pageTitle } = props;

  return (
    <div className="admin-wrap">
      <div className="page-main-content">
        <AppHeader />
        <div className="module-title">
          <h4>{pageTitle || ""}</h4>
        </div>
        <div className="content-wrap">
          {screenResolution?.width >= 768 && !noSideBar && <SideBar />}
          <div className="middle-content">{childComponent}</div>
        </div>
      </div>
    </div>
  );
};
