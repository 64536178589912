import { useState } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { DotLoader } from "react-spinners";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../shared/components/dropdown";
import { bulkUploadCourseList } from "../../redux/action/course-subject";

const CourseBulkUpload = ({ show, setShow }: any) => {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);

  const { allDepartments } = useSelector(
    (state: any) => state.courseSubjectReducer
  );
  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      }) || [];

  const departmentsOptions =
    allDepartments.map((dept: any) => {
      return {
        value: dept?.departmentName,
        label: dept?.departmentName,
        id: dept?.departmentUniqueId,
      };
    }) || [];

  const checkValidationSchema = Yup.object().shape({
    department: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    file: Yup.mixed()
      .required("Required")
      .test(
        "is-valid-size",
        "Max allowed size is 1MB",
        (value) => value && value.size <= 1048576
      ),
  });

  return (
    <Modal
      show={show}
      contentClassName="course-bulk-upload-modal"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4>BULK UPLOAD</h4>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Container>
          <Formik
            initialValues={{ file: "", department: "", programme: "" }}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              const formData = new FormData();
              formData.append("file", values.file);
              formData.append("department", values.department);
              formData.append("programme", values.programme);
              await dispatch(bulkUploadCourseList(formData, setShow));
              setSelectedDepartment("");
              setLoading(false);
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                setFieldValue,
                setFieldTouched,
              } = props;

              return (
                <Form>
                  <DropDown
                    label="Department"
                    touched={touched}
                    errors={errors}
                    options={departmentsOptions}
                    value={{
                      value: values.department,
                      label: values.department,
                    }}
                    defaultValue={{
                      value: values.department,
                      label: values.department,
                    }}
                    handle
                    field="department"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    handleChange={setSelectedDepartment}
                  />
                  <DropDown
                    label="Programme"
                    touched={touched}
                    errors={errors}
                    options={programmesList}
                    value={{
                      value: values.programme,
                      label: values.programme,
                    }}
                    defaultValue={{
                      value: values.programme,
                      label: values.programme,
                    }}
                    handle
                    field="programme"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                  <input
                    accept=".xls,.xlsx"
                    type="file"
                    size={1048576}
                    onChange={(event: any) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    className="bulk-upload-input"
                  />
                  <div className="error">
                    <ErrorMessage name={"file"} />
                  </div>
                  <div className="modal-footer">
                    <ul>
                      <li>Maximum file size allowed is 1MB.</li>
                      <li>
                        Please ensure to download and put your data in the
                        provided template.
                      </li>
                    </ul>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        className="submit-btn"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={true}
                            size={30}
                            aria-label="Submitting"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CourseBulkUpload;
