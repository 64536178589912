import Pencil from "../../../assets/pencil.png";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AddStudentModal from "./AddStudentModal";
import { useState } from "react";

const ReportsTable = ({
  items,
  session,
  PutmeStudentsLoading,
  updatePutmeLoading,
}: any) => {
  const initialValues = {
    postUmeStudentUniqueId: "",
    registrationNumber: "",
    fullName: "",
    phoneNumber: "",
    state: "",
    lga: "",
    gender: "",
    subject1: "",
    subject2: "",
    subject3: "",
    subject4: "",
    subjectScore1: "",
    subjectScore2: "",
    subjectScore3: "",
    subjectScore4: "",
    totalScore: "",
    programme: "",
    category: "",
    session: "",
  };

  const [show, setShow] = useState<boolean>(false);
  const [studentInfo, setStudentInfo] = useState<any>(initialValues);

  return (
    <div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Reg No.</th>
            <th>FULL NAME</th>
            <th>STATE</th>
            <th>SEX</th>
            <th>SUBJECT1</th>
            <th>SUBJECT2</th>
            <th>SUBJECT3</th>
            <th>SUBJECT4</th>
            <th>TOTAL SCORE</th>
            <th>SESSION</th>
            <th>PROGRAMME</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {PutmeStudentsLoading
            ? [...Array(20)].map((_, index) => (
                <tr key={index}>
                  {[...Array(12)].map((_, index) => (
                    <td key={index}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            : items.map((student: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{student?.registrationNumber}</td>
                    <td>{student?.fullName}</td>
                    <td>{student?.state}</td>
                    <td>{student?.gender}</td>
                    <td>{student?.subject1}</td>
                    <td>{student?.subject2}</td>
                    <td>{student?.subject3}</td>
                    <td>{student?.subject4}</td>
                    <td>{student?.totalScore}</td>
                    <td>{student?.session}</td>
                    <td>{student?.programme}</td>

                    <td
                      onClick={() => {
                        setStudentInfo(student);
                        setShow(true);
                      }}
                    >
                      <img
                        src={Pencil}
                        alt="edit"
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      <AddStudentModal
        show={show}
        setShow={setShow}
        initialValues={initialValues}
        studentInfo={studentInfo}
        setStudentInfo={setStudentInfo}
        session={session}
        updatePutmeLoading={updatePutmeLoading}
      />
    </div>
  );
};

export default ReportsTable;
