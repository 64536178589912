import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import "../index.scss";
import PutmeStudents from "./PutmeStudents";
import "./index.scss";
import { ToastContainer } from "react-toastify";
const PostUTMEStudents = () => {
  return (
    <>
    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    style={{ marginTop: "20px" }}
  />

    <InAppTemplate
      childComponent={<PutmeStudents />}
      pageTitle="POST UTME STUDENT"
    />
</>
  );
};

export default PostUTMEStudents;
