import { ErrorMessage } from "formik";
import { FaSortDown } from "react-icons/fa";
import Select, { components } from "react-select";

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSortDown style={{ color: "#000000" }} />
    </components.DropdownIndicator>
  );
};

const DropDown = ({
  label,
  field,
  touched,
  errors,
  options,
  disabled = false,
  handleChange,
  value,
  defaultValue,
  setFieldValue,
  setFieldTouched,
  width,
  mandatory = true,
}: any) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: width || "90%",
      height: "40px",
      display: "flex",
      alignItems: "center",
      background: "#F1FFF9",
      borderColor: state.isFocused ? "#00A85B" : "#00A85B",
      boxShadow: state.isFocused ? "0 0 0 1px #00A85B" : "#00A85B",
      ":hover": {
        borderColor: "#00A85B",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      marginRight: "2px",
    }),
    focus: () => ({
      borderColor: "#00A85B",
      boxShadow: "0 0 0 1px #00A85B",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: width || "90%",
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
      ":hover": {
        backgroundColor: "#00A85B",
        color: "#F1FFF9",
      },
    }),
  };
  const onChange = (selectedOption: any) => {
    if (handleChange) {
      if (field === "department") {
        handleChange(selectedOption.id);
      } else {
        handleChange(selectedOption.value);
      }
    }

    if (setFieldValue) {
      setFieldValue(field, selectedOption.value);
    }
    if (setFieldTouched) {
      setFieldTouched(field, true, false);
    }
  };
  return (
    <div className="form-group form-dropdown">
      <label className="label">
        {label}
        {mandatory ? "*" : null}
      </label>
      <div
        className={
          errors && errors[field] && touched && touched[field]
            ? "invalid-input-wrap"
            : ""
        }
      >
        <Select
          onChange={onChange}
          id={field}
          isDisabled={disabled}
          name={field}
          options={options}
          value={value}
          defaultValue={defaultValue}
          styles={customStyles}
          components={{ DropdownIndicator }}
        />
      </div>
      {touched && (
        <ErrorMessage
          name={field}
          className="form-input-error"
          component="div"
        />
      )}
    </div>
  );
};

export default DropDown;
