import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { InAppTemplate } from "../../shared/templates/portal";
import AdmissionContent from "./AdmissionContent";
import {
  addAdmittedStudentLoading,
  admissionListLoading,
  loadAllProgrammes,
  loadFacultyAndDept,
  loadingBulkUpload,
} from "../../redux/action/admission";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const AdmissionModule = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadFacultyAndDept());
    dispatch(loadAllProgrammes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(admissionListLoading(false));
      dispatch(addAdmittedStudentLoading(false));
      dispatch(loadingBulkUpload(false));
    };
  }, [dispatch]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<AdmissionContent />}
        pageTitle="ADMISSION MANAGEMENT MODULE"
      />
    </>
  );
};

export default AdmissionModule;
